import React from "react";
import { GrNotes } from "react-icons/gr";
import { GiCoins } from "react-icons/gi";

import my_account from "../../../assets/account-setting.svg";
import payment from "../../../assets/moneyBag.svg";
import classAndCourse from "../../../assets/book-svg.svg";
import technical from "../../../assets/technical-svg.svg";
import letsBegin from "../../../assets/lets_begin.svg";

// import exclusives from "../../../assets/Exclusives.svg"

function SupportServices() {
  return (
    <div className=" p-10 h-full overflow-y-scroll">
      <div className="">
        <p className="text-sm">PhynLabz Help & Support</p>
        <p className="text-2xl my-2 font-extrabold text-gray-700">
          I need more information on
        </p>
      </div>
      <div className=" my-10  mt-20 flex flex-col gap-5">
        <div className="flex gap-5">
          <div className="bg-white rounded-xl p-5 flex flex-col gap-5 w-[15rem] cursor-pointer shadow-no-border-shadow-3 hover:scale-105 duration-200">
            <img src={my_account} alt="" className="w-[3rem]" />
            <p className="">My Account</p>
            <p className="text-xs font-oswald text-gray-400">
              Discount codes, Knowledge Hats and More.
            </p>
          </div>{" "}
          <div className="bg-white rounded-xl p-5 flex flex-col gap-5 w-[15rem] cursor-pointer shadow-no-border-shadow-3 hover:scale-105 duration-200">
            <img src={payment} alt="" className="w-[3rem]" />

            <p className="">Payments</p>
            <p className="text-xs font-oswald text-gray-400">
              EMI & Part Payment Related Queries.
            </p>
          </div>{" "}
          <div className="bg-white rounded-xl p-5 flex flex-col gap-5 w-[15rem] cursor-pointer shadow-no-border-shadow-3 hover:scale-105 duration-200">
            <img src={classAndCourse} alt="" className="w-[3rem]" />

            <p className="">Classes & Courses</p>
            <p className="text-xs font-oswald text-gray-400">
              Enroll for a new course, learn about classes and more.
            </p>
          </div>
        </div>
        <div className="flex gap-5">
          <div className="bg-white rounded-xl p-5 flex flex-col gap-5 w-[15rem] cursor-pointer shadow-no-border-shadow-3 hover:scale-105 duration-200">
            <img src={technical} alt="" className="w-[3rem]" />

            <p className="">Technical Difficulties</p>
            <p className="text-xs font-oswald text-gray-400">
              Account related issues, buffering issues and more
            </p>
          </div>{" "}
          <div className="bg-white rounded-xl p-5 flex flex-col gap-5 w-[15rem] cursor-pointer shadow-no-border-shadow-3 hover:scale-105 duration-200">
            <img src={technical} alt="" className="w-[3rem]" />

            <p className="">PhynLabz Exclusives</p>
            <p className="text-xs font-oswald text-gray-400">
              Information regarding the tests and reports
            </p>
          </div>{" "}
          <div className="bg-white rounded-xl p-5 flex flex-col gap-5 w-[15rem] cursor-pointer shadow-no-border-shadow-3 hover:scale-105 duration-200">
            <img src={letsBegin} alt="" className="w-[3rem]" />

            <p className="">Let's Begin!</p>
            <p className="text-xs font-oswald text-gray-400">
              Your complete guide to the Unacademy App and features. Are you
              ready to crack it?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportServices;
