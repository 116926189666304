import Lottie from "lottie-react";
import loading_lottie from "../../assets/loader_lottie.json";
const CustomLoader = () => {
  
  return (
    <div className="w-full h-full fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-slate-700/40 z-50 ">
      <Lottie
        animationData={loading_lottie}
        loop={true}
        className="h-[250px]"
      />
    </div>
  );
};

export default CustomLoader;
