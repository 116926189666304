import { MdPlayArrow } from "react-icons/md";
import img1 from "../../../assets/feedback1.svg";
import img2 from "../../../assets/feedback2.svg";
function FeedBack() {
  return (
    <div className="flex  justify-center bg-white  w-full pt-10 relative   pb-20 md:pt-10">
      <div className="flex flex-col md:flex-row justify-center md:bg-[#024751]  md:justify-between items-center w-[95%] lg:w-[80%]  pt-3 text-start  rounded-lg">
        <div className="bg-white md:w-[60%] rounded-tr-lg flex flex-col md:flex-row  p-5 md:py-20 gap-5">
          <div className="md:w-[50%] text-[#024751]">
            <p className=" text-4xl font-semibold mb-10 tablet:text-xl ">
              There is a better way to test
            </p>
            <div className="">
              <p className="flex items-center">
                <MdPlayArrow className="text-[#03D37E] text-2xl" />
                AI-Powered Question Generator
              </p>
              <p className="cursor-pointer ms-6 mt-1">Feedback</p>
              <p className="cursor-pointer ms-6 mt-1">Automatic Grading</p>
              <p className="cursor-pointer ms-6 mt-1">
                Comprehensive Insights and Analytics
              </p>
              <p className="cursor-pointer ms-6 mt-1">Remote Assessments</p>
              <p className="cursor-pointer ms-6 mt-1">Easy to Archive</p>
              <p className="cursor-pointer ms-6 mt-1">Eco-friendly</p>
            </div>
          </div>
          <div className="md:w-[50%] pt-10 flex flex-col justify-between ">
            <img src={img1} alt="" className="w-[20%]" />
            <div className="">
              <p className="text-2xl font-semibold mb-5">Problem</p>
              <p className="text-sm">
                Creating detailed and insightful questions takes a lot of time.
                Especially in the case of ever-popular, choice question-based
                online and on-site assessments. Could there be a solution that
                would save time and produce reliable content?
              </p>
            </div>
          </div>
        </div>
        <div className="md:w-[40%] py-10 px-10 text-white flex flex-col justify-between bg-[#024751] rounded-lg md:bg-transparent">
          <img src={img2} alt="" className="w-[15%] mb-5" />
          <div className="">
            <p className="text-2xl font-semibold text-[#AFF8C8] mb-5">
              Solution
            </p>
            <p className="text-sm">
              Let upmyprep AI create questions for you! All you have to do is
              provide our platform with your materials or select a topic! Watch
              AI generate questions in seconds. Once they're ready, simply
              review the questions and you're all set!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedBack;
