import "primereact/resources/themes/lara-light-cyan/theme.css";

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";

import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { AdminApi } from "./redux/ReduxToolkit";
import { EducatorRouter } from "./router/educator-router";
import { SnackbarProvider } from "notistack";
import AffiliateRouter from "./router/affiliate-router";
import { getRouter } from "./utils/RouteUtils";
import { AuthProvider } from "./Providers/AuthProvider";
// import { AuthProvider } from "./Providers/AuthProvider";

function App() {
  const Router = getRouter();

  // console.log("App", process.env.NODE_ENV);
  return (
    <ApiProvider api={AdminApi}>
      <AuthProvider>
        <SnackbarProvider>
          <BrowserRouter>
            {/* <EducatorRouter /> */}
            {/* <AffiliateRouter /> */}
            <Router />
          </BrowserRouter>
        </SnackbarProvider>
      </AuthProvider>
    </ApiProvider>
  );
}

export default App;
