import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../Providers/AuthProvider";
import { useGetUplodedLiveClassessQuery } from "../../../../redux/ReduxToolkit";
import { TbArrowNarrowLeft } from "react-icons/tb";
import CustomLoader from "../../../Loading/Loading";
import CloudStorageCard from "../CloudStorageCard/CloudStorageCard";
import no_data_image from "../../../../assets/cloud_no_data.png";
import CloudVideoPlayer from "../CloudVideosFolder/CloudVideoPlayer";
import { IoCloseSharp } from "react-icons/io5";
import ReactHlsPlayer from "@ducanh2912/react-hls-player";

function LiveRecordings({ setShowLiveRecordingBox }) {
	const { userData } = useContext(AuthContext);
	const [videoUrl, setVideoUrl] = useState("");

	const { data: allRecordings, isLoading } = useGetUplodedLiveClassessQuery(
		userData?.userInfo?.educatorId
	);

	const handleSectionFolderClick = (folder, index) => {

		setVideoUrl(
			`https://d18mg0jr1igx53.cloudfront.net${
				folder?.detail?.outputGroupDetails?.[0]?.outputDetails?.[0]?.outputFilePaths?.[0]?.split(
					"/xper-bucket"
				)?.[1]
			}`
		);
	};

	return (
		<div className="flex ">
			<TbArrowNarrowLeft
				className={`border text-3xl rounded-lg border-gray-300 px-1 cursor-pointer absolute top-3 -translate-x-7`}
				onClick={() => setShowLiveRecordingBox(false)}
			/>

			{isLoading && (
				<div className=" flex justify-center items-center w-full h-[85vh]">
					<CustomLoader />
				</div>
			)}

			{!isLoading &&
				(allRecordings?.data?.length > 0 ? (
					<div className="mt-5 flex flex-row flex-wrap">
						{allRecordings?.data?.map((folder, index) => (
							<CloudStorageCard
								key={index}
								title={folder?.detail?.userMetadata?.lectureUid}
								imageType={`mp4`}
								onDoubleClick={() => handleSectionFolderClick(folder, index)}
							/>
						))}
					</div>
				) : (
					<div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
						<div className=" flex justify-center ">
							<img src={no_data_image} alt="" className="w-[20%]" />
						</div>
						<p className="text-xl text-gray-700   ">
							You have not created any Lecture yet
						</p>
					</div>
				))}

			{videoUrl !== "" && (
				<div className="w-screen h-screen bg-neutral-900/50 flex justify-center items-center  fixed top-0 left-0 z-40">
					<div
						className={`bg-white  lg:w-[50%] tablet:w-[60%]  p-16 relative rounded-2xl shadow-xl `}
					>
						<IoCloseSharp
							className={`absolute top-5   right-10 text-3xl text cursor-pointer hover:text-gray-700 text-gray-600 `}
							onClick={() => setVideoUrl("")}
                        />
                        
                        <p className="text-2xl font-medium text-[#313639]">Recording Preview</p>

						<div className="  w-full  my-3 mt-10 ">
							<ReactHlsPlayer
								src={videoUrl}
								autoPlay={false}
								controls={true}
								width="100%"
								height="auto"
								className="rounded-2xl shadow-lg"
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default LiveRecordings;
