import React from "react";
import AffilateContantArea from "./AffilateContantArea/AffilateContantArea";
import AffilateMenuSidebar from './AffilateMenuSidebar/AffilateMenuSidebar';

function AffilateServicesHomePage() {
  return (
    <div className=" w-full h-[100vh] flex bg-gray-200 overflow-y-auto no-scrollbar">
      <AffilateMenuSidebar />
      <AffilateContantArea></AffilateContantArea>
    </div>
  );
}

export default AffilateServicesHomePage;
