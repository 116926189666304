import React, { useContext, useEffect, useState } from "react";
import { IoCloseOutline, IoCloseSharp } from "react-icons/io5";
import InputFieldWithBorder from "../../../Pages/Shared/CustomInput/InputFieldWithBorder";
import TextareaWithBorder from "../../../Pages/Shared/CustomTextarea/TextareaWithBorder";
import {
  useGetAllGoalQuery,
  useGetCoursesByCourseIdQuery,
  useUploadCourseThumbnailMutation,
} from "../../../redux/ReduxToolkit";
import SelectLanguage from "../AddCourse/SelectLanguage";
import CourseType from "../AddCourse/CourseType";
import CustomLoader from "../../Loading/Loading";
import UpdateGoalDropdown from "./UpdateGoalDropdown";
import UpdateSubGoalDropdown from "./UpdateSubGoalDropdown";
import CoursePricePopup from "../AddCourse/CoursePricePopup";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import CourseCoupon from "../AddCourse/CourseCoupon";
import CustomInputFile from "../../../Pages/Shared/CustomInputFile/CustomInputFile";
import { enqueueSnackbar } from "notistack";
import { AuthContext } from "../../../Providers/AuthProvider";
import UpdateInputFile from "../../../Pages/Shared/CustomInputFile/UpdateInputFile";

function UpdateCourse({
  rightPopup,
  setShowUpdateCoursepopup,
  courseId,
  setActiveStep,
}) {
  const { userData } = useContext(AuthContext);
  const [priceList, setPriceList] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [currentSubGoalList, setCurrentSubGoalList] = useState();
  const [currentSubGoalId, setCurrentSubGoalId] = useState(null);
  const [languageList, setLanguageList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [showPricePopup, setShowPricePopup] = useState(false);
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [uploadThumbnailResponse, setUploadThumbnailResponse] = useState();
  const [uploadCourseThumbnail] = useUploadCourseThumbnailMutation();
  const { data: allGoal, isLoading: allGoalIsLoading } = useGetAllGoalQuery();

  const {
    data: getCourseDataByCourseId,
    isLoading: getCourseDataByCourseIdIsLoading,
  } = useGetCoursesByCourseIdQuery(courseId, {
    skip: !courseId || courseId === "",
  });

  // console.log("courseData", getCourseDataByCourseId?.course?.language);

  useEffect(() => {
    if (getCourseDataByCourseId) {
      setCourseTitle(getCourseDataByCourseId?.course?.courseName);
      setCourseDescription(getCourseDataByCourseId?.course?.courseDescription);
      setPriceList(getCourseDataByCourseId?.course?.coursePricing);
      setCouponList(getCourseDataByCourseId?.course?.couponsEligible);
      setUploadThumbnailResponse(
        getCourseDataByCourseId?.course?.courseThumbnail
      );
    }
  }, [getCourseDataByCourseId]);

  const handleRemovePriceChips = (index) => {
    const newChips = priceList.filter((_, idx) => idx !== index);
    setPriceList(newChips);
  };

  const handleRemoveCouponChips = (index) => {
    const newChips = couponList.filter((_, idx) => idx !== index);
    setCouponList(newChips);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("userId", "test-user-id");

    // console.log("Formdata", formData.entries());

    try {
      const uploadThumbnailResponse = await uploadCourseThumbnail({
        body: formData,
      });

      if (uploadThumbnailResponse?.data) {
        enqueueSnackbar(uploadThumbnailResponse?.data?.message, {
          autoHideDuration: 3000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        setUploadThumbnailResponse(uploadThumbnailResponse?.data);
        console.log(uploadThumbnailResponse?.data);
      } else {
        // console.log("Oops! Image is not uploaded. ");
        enqueueSnackbar("Oops! Image is not uploaded. ", {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  const thumbanailData = {
    url: uploadThumbnailResponse?.url,
    resourceId: uploadThumbnailResponse?.path,
    summary: uploadThumbnailResponse?.previewLink,
  };

  const requestBody = {
    courseName: courseTitle,
    courseDescription: courseDescription,
    coursePricing: priceList,
    couponsEligible: couponList,
    subgoalId: [currentSubGoalId],
    goalId: [currentSubGoalList?._id],
    language: [languageList],
    courseType: [courseTypeList],
    courseThumbnail: [thumbanailData],
    courseUid: courseId,
    educatorInfo: [userData?.userInfo?._id],
  };

  const handleUpdateDetails = () => {
    if (!thumbanailData?.url) {
      enqueueSnackbar("Thumbanail is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }
    // console.log("requestBody", requestBody);
  };

  // console.log(uploadThumbnailResponse?.[0]?.resourceId);

  return (
    <div className="w-screen h-screen bg-[rgba(128,128,128,.8)] flex justify-end items-center fixed top-0 left-0 z-50 cursor-default ">
      {(getCourseDataByCourseIdIsLoading || allGoalIsLoading) && (
        <CustomLoader />
      )}
      <div
        className={`bg-white  lg:w-[40rem] h-[100vh] tablet:w-[60%]    shadow-lg relative ${rightPopup} `}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="p-5 w-full flex justify-between  border-b border-[#237C43]">
          <p className="font-bold">Update Course Form </p>
          <IoCloseSharp
            className={` text-3xl text cursor-pointer hover:text-gray-700 text-gray-600 `}
            onClick={() => {
              setShowUpdateCoursepopup(false);
              setActiveStep(null);
            }}
          />
        </div>
        <div className=" p-10  h-[90vh] overflow-y-scroll no-scrollbar ">
          <div className="mb-3">
            <InputFieldWithBorder
              label={`Course Title`}
              onChange={(e) => {
                setCourseTitle(e.target.value);
              }}
              value={courseTitle}
            />
          </div>{" "}
          <div className="mb-3">
            <TextareaWithBorder
              rows={3}
              label={`Course Description`}
              onChange={(e) => {
                setCourseDescription(e.target.value);
              }}
              value={courseDescription}
            />
          </div>
          <div className="">
            <UpdateGoalDropdown
              allGoal={allGoal}
              setCurrentSubGoalList={setCurrentSubGoalList}
              previousGoalId={getCourseDataByCourseId?.course?.goalId?.[0]}
            />
          </div>
          <div className="">
            <UpdateSubGoalDropdown
              currentSubGoalList={currentSubGoalList}
              setCurrentSubGoalId={setCurrentSubGoalId}
              previousSubGoalId={
                getCourseDataByCourseId?.course?.subgoalId?.[0]
              }
            />
          </div>
          <div className="my-5">
            <SelectLanguage
              setLanguageList={setLanguageList}
              previousLanguageData={getCourseDataByCourseId?.course?.language}
            />
          </div>
          <div className="my-5">
            <CourseType
              setCourseTypeList={setCourseTypeList}
              previousCourseTypeData={
                getCourseDataByCourseId?.course?.courseType
              }
            />
          </div>
          {priceList?.length > 0 && (
            <div className="flex flex-col">
              <p className="">Price Tag</p>
              <div className=" flex flex-row ">
                {priceList?.map((chips, index) => (
                  <div
                    className={`bg-[#DFDFDF] text-gray-800 ps-3 px-3 py-2 rounded-xl  cursor-pointer me-2 mt-2 flex items-center relative min-w-[100px] ${
                      chips?.isDiscounted ? "bg-indigo-500 text-white" : ""
                    }  ${chips?.isTrail ? "bg-red-500 text-white" : ""}`}
                    key={index}
                  >
                    <div className="">
                      <div className="mr-10 mb-2 text-sm font-thin">
                        {chips?.packName}
                      </div>
                      <div className="flex items-center justify-between w-full">
                        {chips.isTrail && (
                          <div className="">
                            ₹
                            {parseInt(chips?.priceTag) -
                              (parseInt(chips?.priceTag) *
                                parseInt(chips?.discountRate)) /
                                100}
                          </div>
                        )}

                        {!chips.isTrail && (
                          <div className="flex items-center justify-between">
                            <div className="">
                              ₹
                              {parseInt(chips?.priceTag) -
                                (parseInt(chips?.priceTag) *
                                  parseInt(chips?.discountRate)) /
                                  100}
                            </div>
                            <s className="text-xs font-thin ms-3 text-gray-200">
                              {!chips?.isTrail
                                ? "₹" + chips?.priceTag
                                : "₹" + chips?.priceTag + " (Trail)"}
                            </s>
                          </div>
                        )}
                      </div>
                    </div>
                    <IoCloseOutline
                      className={`ms-5 text-white rounded-full bg-[#A7A7A7] absolute top-2 right-2 cursor-pointer `}
                      onClick={() => handleRemovePriceChips(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {couponList.length > 0 && (
            <div className="flex flex-col mt-3">
              <p className="">Coupon Tag</p>
              <div className="flex relative">
                {couponList?.map((couponChips, index) => (
                  <div
                    key={index}
                    className={`bg-[#DFDFDF] text-gray-800 ps-3 px-3 py-2 rounded-xl  cursor-pointer me-2 mt-2 flex items-center relative min-w-[150px] ${
                      couponChips?.singleUse
                        ? " bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white"
                        : ""
                    }`}
                  >
                    <div className="flex flex-col">
                      <p className="font-thin "> {couponChips?.couponName}</p>
                      <p className="text-xs">
                        {couponChips?.couponRate + "% Off"}
                      </p>
                    </div>
                    <IoCloseOutline
                      className={`ms-5 text-white rounded-full bg-[#A7A7A7] absolute top-2 right-2 cursor-pointer `}
                      onClick={() => handleRemoveCouponChips(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="flex flex-col items-start justify-start  w-full mt-5">
            <div className="">
              <img
                src={`https://axg6mefkv4.execute-api.ap-south-1.amazonaws.com/aws/serverless/optimize?url=https://d18mg0jr1igx53.cloudfront.net/${uploadThumbnailResponse?.[0]?.resourceId}?auto=compress&cs=tinysrgb&dpr=1&width=200&height=100&quality=10`}
                alt=""
              />
            </div>
            <UpdateInputFile
              lable={`Upload Thumbnail`}
              onChange={(e) => handleFileUpload(e)}
              // previewLink={
              //   uploadThumbnailResponse?.previewLink
              //     ? uploadThumbnailResponse?.previewLink
              //     : uploadCourseThumbnail?.summary
              // }
              previewLink={uploadThumbnailResponse?.previewLink}
            />
          </div>
          <div className="flex justify-end flex-col items-end">
            <CustomButton
              lable={`Add Pricing`}
              className={`mt-3 !mx-0 duration-200 bg-[#121212] text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
              onClick={() => setShowPricePopup(true)}
            />
            <CustomButton
              lable={`Add Coupon`}
              className={`mt-3 !mx-0 duration-200 bg-[#121212] text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
              onClick={() => setShowCouponPopup(true)}
            />

            <CustomButton
              lable={`Update Course`}
              className={`mt-3 !mx-0 duration-200 bg-[#49982E] text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
              onClick={() => handleUpdateDetails()}
            />
          </div>
        </div>
      </div>
      {
        <div className="fixed top-0 left-0">
          {showPricePopup && (
            <CoursePricePopup
              zoomInPopup={`animate-zoomInPopup`}
              setShowPricePopup={setShowPricePopup}
              setPriceList={setPriceList}
              priceList={priceList}
            />
          )}
        </div>
      }
      {showCouponPopup && (
        <CourseCoupon
          zoomInPopup={`animate-zoomInPopup`}
          setShowCouponPopup={setShowCouponPopup}
          setCouponList={setCouponList}
          couponList={couponList}
        />
      )}
    </div>
  );
}

export default UpdateCourse;
