import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html"; // Import the library for converting Draft.js content to HTML
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const AnswerEditer = ({ setAnswerData }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [text, setText] = useState();

  const onEditorStateChange = function (editorState) {
    logContent(editorState);
    setEditorState(editorState);
    const { blocks } = convertToRaw(editorState.getCurrentContent());
    let text = editorState.getCurrentContent().getPlainText("\u0001");
    setText(text);
    console.log();
  };

  const logContent = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const contentAsHTML = draftToHtml(convertToRaw(contentState)); // Convert Draft.js content to HTML
    // console.log("answer : ", contentAsHTML);
    // setAnswerData(`<>${contentAsHTML}</>`);
    setAnswerData(contentAsHTML);
  };


    const uploadImageCallBack = (file) => {
      return new Promise((resolve, reject) => {
        // Simulate image upload (replace with actual upload logic)
        setTimeout(() => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve({ data: { link: event.target.result } });
          };
          reader.readAsDataURL(file);
        }, 1000);
      });
    };

  return (
    <div>
      <div style={{ border: "1px solid #ccc", padding: "8px" }}>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: [
              { text: "APPLE", value: "apple" },
              { text: "BANANA", value: "banana", url: "banana" },
              { text: "CHERRY", value: "cherry", url: "cherry" },
              { text: "DURIAN", value: "durian", url: "durian" },
              { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
              { text: "FIG", value: "fig", url: "fig" },
              { text: "GRAPEFRUIT", value: "grapefruit", url: "grapefruit" },
              { text: "HONEYDEW", value: "honeydew", url: "honeydew" },
            ],
          }}
          toolbar={{
            image: {
              uploadCallback: uploadImageCallBack,
              alt: { present: true, mandatory: false },
            },
          }}
        />
      </div>
    </div>
  );
};

export default AnswerEditer;
