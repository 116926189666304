import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = localStorage.getItem("userData");
    if (token !== null || token !== undefined || token !== "") {
      setAccessToken(token);
    }
    if (userData !== null || userData !== undefined) {
      setUserData(JSON.parse(userData));
    }
  }, []);

  const login = (token, data) => {
    setAccessToken(token);
    setUserData(data);
    localStorage.setItem("token", token);
    localStorage.setItem("userData", JSON.stringify(data));
    window.location.href = "/";
  };

  const logout = () => {
    setAccessToken(null);
    setUserData(null)
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.href = "/";
  };

 

  return (
    <AuthContext.Provider value={{ login, logout, accessToken, isLoading,userData }}>
      {children}
    </AuthContext.Provider>
  );
};
