import React from "react";
import CreateNotificaton from "./CreateNotificaton";
import PreviewNotificaton from "./PreviewNotificaton";

function Notificaton() {
	return (
    <div className="flex flex-col   w-full h-[95vh] overflow-y-auto no-scrollbar  p-10">
      <div>
        <CreateNotificaton />
      </div>
      <div>
        <PreviewNotificaton />
      </div>
    </div>
  );
}

export default Notificaton;
