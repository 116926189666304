import React, { useEffect, useState } from "react";
import { LuUpload } from "react-icons/lu";

function CustomVideoUploader({ onChange, lable, onClick, filePath }) {

  const [selectedFile,setSelectedFile] = useState(null)

  useEffect(() => {
    console.log("filePath",filePath)
  },[filePath])

  return (
    <div className="col-span-full mt-5 w-full">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {lable}
      </label>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 w-full">
        <div className="text-center">
          <LuUpload
            className="mx-auto h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
          <div className="mt-4 flex text-sm leading-6 text-gray-600 items-center justify-center">
            <p className="pl-1"> Drag & Drop or</p>
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md  font-semibold text-indigo-600  hover:text-indigo-500 mx-2"
            >
              <span>Choose File</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                onChange={(e)=>{onChange(e);setSelectedFile(e.target?.files)}}
                onClick={onClick}

                //   onChange={(e) => console.log("file path = ", e.target.value)}
              />
            </label>
            <p className="text-xs leading-5 text-gray-600">to upload</p>
          </div>
          {selectedFile && selectedFile?.length > 0 &&  (
            <div className=" mt-3 text-sm   py-2 px-3 border rounded-lg  font-sans">
              {selectedFile?.[0]?.name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomVideoUploader;
