import React, { useContext, useState } from "react";
import CustomVideoUploader from "../../../Pages/Shared/CustomVideoUploader/CustomVideoUploader";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { IoCloseSharp } from "react-icons/io5";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ApiConstant } from "../../../Api/ApiConstant";
import { enqueueSnackbar } from "notistack";
import { AuthContext } from "../../../Providers/AuthProvider";

function UploadLecture({
  zoomInPopup,
  setShowUploadBox,
  lectureAllData,
  chapterId,
  sectionId,
  courseId,
}) {
  const { userData } = useContext(AuthContext);
  const [progress, setProgress] = useState(0);

  var formData = new FormData();

  const handleSelectFile = (e) => {
    formData.append("educatorId", userData?.userInfo?.educatorId);
    formData.append("courseId", courseId);
    formData.append("sectionId", sectionId);
    formData.append("chapterId", chapterId);
    formData.append("lectureName", lectureAllData?.lectureName);
    formData.append("lectureDescription", lectureAllData?.lectureDescription);
    formData.append("lectureUid", lectureAllData?.lectureUid);
    formData.append("type", "recording");
    formData.append("file", e.target?.files?.[0]);
  };

  const calculateColor = (progress) => {
    if (progress < 50) {
      return `rgba(255, ${Math.round(255 * (progress / 50))}, 0, 1)`;
    } else {
      return `rgba(${Math.round(255 * ((100 - progress) / 50))}, 255, 0, 1)`;
    }
  };

  const handleVideoUpload = async () => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      ApiConstant.BASE_URL + ApiConstant.UPLOAD_LECTURE_VIDEO,
      true
    );

    xhr.upload.onprogress = (event) => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total);
      setProgress(percentCompleted);
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          try {
            const response = JSON.parse(xhr.responseText);
            enqueueSnackbar(response.msg, {
              autoHideDuration: 2000,
              variant: "success",
              anchorOrigin: { horizontal: "center", vertical: "top" },
            });
            setShowUploadBox(false);
          } catch (error) {
            console.error("Error parsing response:", error);
          }
        } else {
          console.error("Upload failed. Status:", xhr.status);
        }
      }
    };

    xhr.send(formData);
  };

  return (
    <div className="w-screen h-screen bg-[rgba(128,128,128,.8)] flex justify-center items-center fixed top-0 left-0 z-20 cursor-default">
      <div
        className={`bg-white  lg:w-[40rem] h-[40rem] tablet:w-[60%]  p-10  rounded-md shadow-lg relative ${zoomInPopup}`}
      >
        <IoCloseSharp
          className={`absolute top-3 right-3 text-3xl text cursor-pointer hover:text-gray-700 text-gray-600 `}
          onClick={() => setShowUploadBox(false)}
        />

        <p className="text-lg  text-gray-700">Upload Video & file</p>

        <div className=" mt-10 bg-gray-100 text-gray-600 p-5 flex items-center justify-between pe-10 text-sm">
          <div className="">
            <p className="text-base font-semibold mb-5">
              Current Lecture Details
            </p>
            <p className="">Title : {lectureAllData?.lectureName}</p>
            <p className="">
              Description : {lectureAllData?.lectureDescription}
            </p>
          </div>
          {progress > 0 && progress < 100 && (
            <div className="h-[70px] w-[70px] ">
              <CircularProgressbar
                value={progress}
                text={`${progress}%`}
                styles={buildStyles({
                  pathColor: calculateColor(progress),
                  textColor: "#4a4a4a",
                  trailColor: "#d6d6d6",
                })}
              />
            </div>
          )}
        </div>

        <CustomVideoUploader onChange={(e) => handleSelectFile(e)} />

        <div className="flex justify-end mt-5 absolute bottom-4 right-5">
          <CustomButton
            lable={`Upload Video`}
            className={`w-[8rem] !px-0 bg-green-500 text-white border-none !me-0 `}
            onClick={() => handleVideoUpload()}
          />
        </div>
      </div>
    </div>
  );
}

export default UploadLecture;
