import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import BrandLogo from "../Shared/BrandLogo/BrandLogo";


function Footer() {
  const product= [
    {
      link: "/",
      title: "Skills and Knowledge Assessment",
    },
    {
      link: "/",
      title: "Online Quiz Maker",
    },
    {
      link: "/",
      title: "Easy Test Maker",
    },
    {
      link: "/",
      title: "Exam Software",
    },
    {
      link: "/",
      title: "AI-Powered Question Creation",
    },
    {
      link: "/",
      title: "Insights & Analytics",
    },
    {
      link: "/",
      title: "ISmart Proctoring",
    },
    {
      link: "/",
      title: "Microsoft Teams Quiz Maker",
    },
    {
      link: "/",
      title: "Integrations & API",
    },
    {
      link: "/",
      title: "Compare",
    },
  ];
  const business= [
    {
      link: "/",
      title: "Human Resources",
    },
    {
      link: "/",
      title: "Training Companies & Departments",
    },
    {
      link: "/",
      title: "Certification",
    },
    {
      link: "/",
      title: "Sales & Customer Service",
    },
    {
      link: "/",
      title: "Language Schools",
    },
  ];
  const useEducation= [
    {
      link: "/",
      title: "Quizzes",
    },
    {
      link: "/",
      title: "Exams",
    },
    {
      link: "/",
      title: "Homework",
    },
    {
      link: "/",
      title: "Competition",
    },
    {
      link: "/",
      title: "Formative Assessment",
    },
  ];
  const forEducation= [
    {
      link: "/",
      title: "Teachers",
    },
    {
      link: "/",
      title: "Schools",
    },
    {
      link: "/",
      title: "Universities & Colleges",
    },
  ];
  return (
    <div className="flex  justify-center bg-white text-[#024751] w-full py-5">
      <div className="flex flex-col  justify-center  lg:justify-between items-start w-[95%] lg:w-[80%]  py-3 text-start ">
        <div className="flex items-center justify-between w-full border-b">
          <div className="flex items-center">
            <BrandLogo />
            <div className="hidden md:block">
              <div className=" flex items-center ms-5  ">
                <p className="mx-3 font-medium cursor-pointer">Guides</p>
                <p className="mx-3 font-medium cursor-pointer">Help Center</p>
                <p className="mx-3 font-medium cursor-pointer">
                  Customer Stories
                </p>
                <p className="mx-3 font-medium cursor-pointer">About Us</p>
                <p className="mx-3 font-medium cursor-pointer">Contact Us</p>
              </div>
            </div>
          </div>
          <div className=" flex items-center text-3xl gap-3">
            <FaLinkedin className="cursor-pointer" />
            <FaFacebook className="cursor-pointer" />
          </div>
        </div>

        <div className=" mt-5 flex flex-col md:flex-row tablet:flex-wrap gap-5 justify-between w-full">
          <div className="mb-10 ">
            <p className="text-lg text-gray-400 mb-5">PRODUCT</p>
            <ul className="text-[#024751]">
              {product?.map((items, index) => (
                <li className=" mt-2  cursor-pointer " key={index}>
                  <span className="border-b border-transparent hover:border-[#024751]">
                    {items?.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-10 ">
            <p className="text-lg text-gray-400 mb-5">FOR BUSINESS</p>
            <ul className="text-[#024751]">
              {business?.map((items, index) => (
                <li className=" mt-2  cursor-pointer " key={index}>
                  <span className="border-b border-transparent hover:border-[#024751]">
                    {items?.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-10 ">
            <p className="text-lg text-gray-400 mb-5">EDUCATIONAL USE CASES</p>
            <ul className="text-[#024751]">
              {useEducation?.map((items, index) => (
                <li className=" mt-2  cursor-pointer " key={index}>
                  <span className="border-b border-transparent hover:border-[#024751]">
                    {items?.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-10 ">
            <p className="text-lg text-gray-400 mb-5">FOR EDUCATION</p>
            <ul className="text-[#024751]">
              {forEducation?.map((items, index) => (
                <li className=" mt-2  cursor-pointer " key={index}>
                  <span className="border-b border-transparent hover:border-[#024751]">
                    {items?.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="text-gray-300 flex items-center gap-10 mb-5 mt-10 text-sm">
          <p className=""> © 2024 Phynlabz Technology Pvt Ltd</p>
          <div className="flex items-center">
            <p className="">Privacy Policy</p>
            <GoDotFill className="text-sm mx-3" />
            <p className="">Terms and Conditions</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
