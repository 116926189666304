import React from "react";
import { Outlet } from "react-router-dom";

function AffilateContantArea() {
  return (
    <div className="w-full   bg-white relative">
      <div className="w-full h-full p-5 ">
        <Outlet />
      </div>
    </div>
  );
}

export default AffilateContantArea;
