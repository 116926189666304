import React, { useContext, useState } from "react";
import { FaUser } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";
import CustomButton from "../../Pages/Shared/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Providers/AuthProvider";
import { useEducatorLoginMutation } from "../../redux/ReduxToolkit";
import { enqueueSnackbar } from "notistack";

function AffilateLogin({
  showLoginBox,
  setShowLoginBox,
  blurInLeft,
  blurOutRight,
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [id, setId] = useState(null);

  const navigate = useNavigate();

  const { login: setAccessToken } = useContext(AuthContext);

  const [educatorLogin, { error, isError }] = useEducatorLoginMutation();

  // console.log("error message = ", error?.data?.message);
  // console.log("isError = ", isError);

  function ValidateEmail(mail) {
    if (
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const handleLogin = async () => {
    if (email === null || email === undefined || email.length === 0) {
      enqueueSnackbar("Email is required !", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    if (!ValidateEmail(email)) {
      enqueueSnackbar("Entered Email is incorrect", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });

      return;
    }
    if (password === null || password === undefined || password.length === 0) {
      enqueueSnackbar("Password is required !", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
      return;
    }

    try {
      const response = await educatorLogin({
        body: {
          email: email,
          password: password,
        },
      });

      // console.log(response);

      if (response.data) {
        // console.log("user login ", response?.data?.serverResponse);
        setAccessToken(response?.data?.serverResponse?.accessToken, {
          userInfo: response?.data?.serverResponse?.user,
        });
      }
      if (response.error) {
        enqueueSnackbar(response?.error?.data?.message, {
          autoHideDuration: 2000,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        return;
      } else {
        console.log("Oops! user is not logid ");
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  return (
    <div
      className={`flex flex-col w-[90%] tablet:w-[60%] lg:w-[30%] min-h-[50vh] bg-[#66CDBF]/60 p-5   `}
    >
      <div className="flex flex-col items-center gap-5">
        <p
          className="font-mono text-[#b4f0f5] text-xl  lg:text-2xl"
          style={{ fontWeight: "bold" }}
        >
          AFFILATE LOGIN
        </p>
        <div className=" lg:px-5 w-full flex flex-col gap-5  mt-5 lg:mt-10">
          <div className="flex items-center">
            <div className="bg-[#265255]  text-white p-3 lg:p-5">
              <FaUser />
            </div>
            <input
              type="text"
              placeholder="Username"
              className="text-xs p-3 w-full focus:outline-none bg-[#265255]/80 placeholder:text-white lg:p-5 text-white "
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex items-center">
            <div className="bg-[#265255]  text-white p-3 lg:p-5">
              <IoIosLock />
            </div>
            <input
              type="text"
              placeholder="Password"
              className="text-xs p-3 w-full focus:outline-none bg-[#265255]/80 placeholder:text-white lg:p-5  text-white "
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <CustomButton
            lable={`LOGIN`}
            className={`!mx-0 w-full !p-3 rounded-none bg-white border-none lg:mt-5 font-semibold hover:bg-[#66CDBF] `}
            onClick={handleLogin}
          />
          <p className="w-full text-xs text-center text-[#e2e0e0] cursor-pointer">
            Forgot Password
          </p>

          <div className="h-[2px] mt-5 w-full bg-[#265255] shadow-sm shadow-white">
            {" "}
          </div>
          <CustomButton
            lable={`REGISTER`}
            className={`!mx-0 w-full !p-3 rounded-none hover:bg-white border-none lg:mt-5 font-semibold bg-[#66CDBF] text-gray-800`}
          />
        </div>
      </div>
    </div>
  );
}

export default AffilateLogin;
