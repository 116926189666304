import { useEffect, useState } from "react";
import { useRef } from "react";
import { Peer } from "peerjs";

export const StudentView = () => {
  const myVideoStream = useRef(null);
  const remoteVideoStream = useRef(null);
  const studentPeer = useRef(null);

  useEffect(() => {
    studentPeer.current = new Peer();

    studentPeer.current.on("open", (id) => {
      console.log("Peer ID", id);
    });

    // studentPeer.current.on("call", (call) => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        myVideoStream.current.srcObject = stream;
      });
    //       call.answer(stream);
    //       call.on("stream", (remoteStream) => {
    //         if (remoteVideoStream.current.srcObject) {
    //           const prevStream = remoteVideoStream.current.srcObject;
    //           const tracks = prevStream.getTracks();
    //           tracks.forEach((track) => track.stop());
    //         }

    //         remoteVideoStream.current.srcObject = remoteStream;

    //         remoteVideoStream.current.addEventListener("loadedmetadata", () => {
    //           remoteVideoStream.current.play().catch((error) => {
    //             console.error("Error playing remote stream:", error);
    //           });
    //         });
    //       });
    //     })
    //     .catch((err) => {
    //       console.error("Error accessing media devices:", err);
    //     });
    // });

    return () => {
      if (studentPeer.current) {
        studentPeer.current.destroy();
      }
    };
  }, []);

  const [remotePeerId, setRemotePeerId] = useState(null);
  const makeCall = () => {
    console.log("remotePeerId", remotePeerId);
    if (!remotePeerId || remotePeerId === "") {
      alert("Meeting Id is required");
      return;
    }
    navigator?.mediaDevices
      ?.getUserMedia({ video: true, audio: false })
      .then((stream) => {
        // if (myVideoStream.current.srcObject) {
        //   const prevStream = myVideoStream.current.srcObject;
        //   const tracks = prevStream.getTracks();
        //   tracks.forEach((track) => track.stop());
        // }
        // myVideoStream.current.addEventListener("loadedmetadata", () => {
        //   myVideoStream.current.play().catch((error) => {
        //     console.error("Error playing remote stream:", error);
        //   });
        // });
        myVideoStream.current.srcObject = stream;
        const call = studentPeer.current.call(remotePeerId, stream);
        call.on("stream", (remoteStream) => {
          // if (remoteVideoStream.current.srcObject) {
          //   const prevStream = remoteVideoStream.current.srcObject;
          //   const tracks = prevStream.getTracks();
          //   tracks.forEach((track) => track.stop());
          // }

          remoteVideoStream.current.srcObject = remoteStream;

          remoteVideoStream.current.addEventListener("loadedmetadata", () => {
            remoteVideoStream.current.play().catch((error) => {
              console.error("Error playing remote stream:", error);
            });
          });
        });
      })
      .catch((err) => {
        console.error("Error accessing media devices:", err);
      });
  };

  return (
    <div>
      <div className="flex gap-8 mb-10">
        <video
          ref={myVideoStream}
          className="h-[300px] w-[400px] border rounded-lg"
          muted
          autoPlay
        ></video>
        <video
          ref={remoteVideoStream}
          className="h-[300px] w-[400px] border rounded-lg"
          autoPlay
        ></video>
      </div>
      <div className="flex flex-col">
        <input
          type="text"
          class="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none mb-4"
          value={remotePeerId}
          onChange={(e) => setRemotePeerId(e.target.value)}
          placeholder="Enter Meeting Id"
        />

        <button
          onClick={makeCall}
          className="bg-purple-500 text-white w-[200px] px-2 py-2 rounded-lg self-center"
        >
          Call Educator
        </button>
      </div>
    </div>
  );
};
