import { useContext, useState } from "react";
import { MdArrowLeft } from "react-icons/md";
import { AuthContext } from "../../../Providers/AuthProvider";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { CiMail } from "react-icons/ci";
import { IoIosUnlock } from "react-icons/io";
import "./Login.css";
import design_icon from "../../../assets/login_image_design_icon.svg";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useEducatorLoginMutation } from "../../../redux/ReduxToolkit";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [id, setId] = useState(null);

  const navigate = useNavigate();

  const { login: setAccessToken } = useContext(AuthContext);

  const [educatorLogin, { error, isError }] = useEducatorLoginMutation();

  // console.log("error message = ", error?.data?.message);
  // console.log("isError = ", isError);

  function ValidateEmail(mail) {
    if (
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const handleLogin = async () => {
    if (email === null || email === undefined || email.length === 0) {
      enqueueSnackbar("Email is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }

    if (!ValidateEmail(email)) {
      enqueueSnackbar("Entered Email is incorrect", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });

      return;
    }
    if (password === null || password === undefined || password.length === 0) {
      enqueueSnackbar("Password is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }

    try {
      const response = await educatorLogin({
        body: {
          email: email,
          password: password,
        },
      });

      console.log(response);

      if (response.data) {
        // console.log("user login ", response?.data?.serverResponse);
        setAccessToken(response?.data?.serverResponse?.accessToken, {
          userInfo: response?.data?.serverResponse?.user,
        });
      } else {
        console.log("Oops! user is not logid ");
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  return (
    <div
      className=" w-full h-[100vh] flex justify-center
     items-center login_bg_image relative"
    >
      <img
        src={design_icon}
        alt=""
        className="absolute left-[17%] bottom-[10%] h-[150px] w-[150px] "
      />

      <div className="bg-white w-auto min-h-[28rem] flex  justify-between items-center  rounded-3xl ">
        <div className="w-ful px-10">
          {isError && (
            <div className="border border-red-400 p-4 rounded-lg text-red-600 text-xs">
              {error?.data?.message}
            </div>
          )}

          <p className=" font-bold  text-2xl my-2  text-gray-700 font-outfit">
            Educator Login
          </p>
          <p className="text-gray-600 text-sm">Log in to Get Started</p>

          <div className=" w-[25rem]">
            <div className="mt-3">
              <div className=" relative  border rounded-full px-5 py-3 ">
                <span className="absolute top-3.5  bottom-0 my-auto text-xl">
                  <CiMail />
                </span>
                <input
                  autocomplete="off"
                  type="email"
                  className=" w-full rounded-full border-0 text-gray-900   bg-transparent placeholder:text-neutral-400  sm:text-sm sm:leading-6 ms-5 px-3 focus:outline-none"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <p className="text-xs mt-2 text-blue-500 cursor-pointer">
                Forgot User?
              </p>
            </div>
            <div className="my-3 ">
              <div className=" relative border rounded-full px-5 py-3">
                <span className="absolute mt-0 mb-0 my-auto text-xl top-3.5">
                  <IoIosUnlock className="text-gray-500" />
                </span>
                <input
                  autocomplete="off"
                  type="password"
                  className=" w-full rounded-full border-0  text-gray-900   bg-transparent placeholder:text-neutral-400  sm:text-sm sm:leading-6 mx-5 px-3 focus:outline-none "
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    // isError(false);
                  }}
                />
              </div>
              <p className="text-xs mt-2 text-blue-500 cursor-pointer">
                Forgot password?
              </p>
            </div>
            <CustomButton
              lable={`LOG IN`}
              className={`!mx-0 !py-3 w-full !rounded-full text-white bg-blue-600 border-none  mt-3 hover:bg-blue-600`}
              onClick={() => handleLogin()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
