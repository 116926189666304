import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

function ProgressBar() {
  const [progress, setProgress] = useState(60);
  const calculateColor = (progress) => {
    if (progress < 50) {
      return `rgba(255, ${Math.round(255 * (progress / 50))}, 0, 1)`;
    } else {
      return `rgba(${Math.round(255 * ((100 - progress) / 50))}, 255, 0, 1)`;
    }
  };

  return (
    <div className="flex flex-col  items-center justify-between gap-5 border  h-full shadow-no-border-shadow-2 w-full rounded-lg  ">
      <div className="flex flex-col items-center h-full">
        <p className=" font-medium  w-full p-3 border-b text-gray-700">
          Sell Value
        </p>
        <div className="p-5 flex items-center justify-center  h-full">
          <CircularProgressbar value={progress} text={`${progress}%`} />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full ">
        <div className="border-y w-full text-center py-3 flex flex-col gap-3">
          <p className="text-xs text-gray-400">Sell Items</p>
          <p className="text-xl font-medium text-green-600">20</p>
        </div>
        <div className=" w-full text-center py-3 flex flex-col gap-3">
          <p className="text-xs text-gray-400 ">Sell Revenue</p>
          <p className="text-xl font-medium text-green-600">₹ 20000</p>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
