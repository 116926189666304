import { useEffect, useMemo, useRef } from "react";
import CanvasDraw from "react-canvas-draw";
// import { Surface, Group, Shape } from "react-canvas";
const NodePad = () => {
  const canvasProps = useMemo(
    () => ({
      className: "w-full h-full",
      brushRadius: "2",
      brushColor: "000",
      canvasWidth: "window.innerWidth",
      canvasHeight: "window.innerHeight",
      hideCanvas: "false",
      lazyRadius: "0",
      catenaryColor: "#0a0302",
      gridColor: "rgba(150,150,150,0.17)",
      backgroundColor: "#ffffff",
    }),
    []
  );
  return useMemo(
    () => (
      <div className="canvas-container prevent-selection">
        <CanvasDraw
          lazyRadius={0}
          loadTimeOffset={0}
          brushColor={"#000"}
          brushRadius={2}
          canvasWidth={window.innerWidth}
          canvasHeight={window.innerHeight}
        />
      </div>
    ),
    []
  );
};

export default NodePad;
