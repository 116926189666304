import React from "react";
import { MdOutlineCurrencyRupee } from "react-icons/md";

function AffilateDashboardCards() {
  return (
    <div className="flex flex-col justify-between gap-5 h-full">
      <div className="border p-4 flex flex-col gap-5 rounded-lg shadow-no-border-shadow-2 w-[20rem]">
        <p className="text-gray-400 font-medium text-xs">
          Current Total Revenue
        </p>
        <div className=" flex items-center">
          <p className="text-xl font-bold flex items-center">
            <MdOutlineCurrencyRupee className="" />

            {Number(6522).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p
            className={`text-green-500 bg-green-500/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5`}
          >
            +2
          </p>
        </div>
      </div>
      <div className="border p-4 flex flex-col gap-5 rounded-lg shadow-no-border-shadow-2 w-[20rem]">
        <p className="text-gray-400 font-medium text-xs">Weekly Earning</p>
        <div className=" flex items-center">
          <p className="text-xl font-bold">10</p>
          <p
            className={`text-green-500 bg-green-500/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5`}
          >
            +2
          </p>
        </div>
      </div>
      <div className="border p-4 flex flex-col gap-5 rounded-lg shadow-no-border-shadow-2 w-[20rem]">
        <p className="text-gray-400 font-medium text-xs">Monthly Earning</p>
        <div className=" flex items-center">
          <p className="text-xl font-bold">10</p>
          <p
            className={`text-green-500 bg-green-500/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5`}
          >
            +2
          </p>
        </div>
      </div>
      <div className="border p-4 flex flex-col gap-5 rounded-lg shadow-no-border-shadow-2 w-[20rem]">
        <p className="text-gray-400 font-medium text-xs">Sell Courses</p>
        <div className=" flex items-center">
          <p className="text-xl font-bold">10</p>
          <p
            className={`text-green-500 bg-green-500/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5`}
          >
            +2
          </p>
        </div>
      </div>
    </div>
  );
}

export default AffilateDashboardCards;
