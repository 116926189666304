import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { Step, Stepper } from "react-form-stepper";
import { FiPlus } from "react-icons/fi";
import {
  useGetAllCoursesByInstrutorIdQuery,
} from "../../../redux/ReduxToolkit";
import ManageSection from "../AddSection/ManageSection";
import ManageChapter from "../AddChapter/ManageChapter";
import ManageQuestion from "../AddQuestion/ManageQuestion";
import CreateCoursePopup from "./CreateCoursePopup";
import CourseCard from "./CourseCrad";
import ManageLecture from "../AddLecture/ManageLecture";
import no_data_image from "../../../assets/no-course.png";
import CustomLoader from "../../Loading/Loading";
import { AuthContext } from "../../../Providers/AuthProvider";
import DeletePopup from "../../DeletePopup/DeletePopup";

function Course() {
  const [activeStep, setActiveStep] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [currentSectionId, setCurrentSectionId] = useState(null);
  const [sectionData, setSectionData] = useState([]);
  const [changeValueStepper, setChangeValueStepper] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(null);
  const [currentChapterId, setCurrentChapterId] = useState("");
  const [currentChapterIndex, setCurrentChapterIndex] = useState(null);
  const [newCourseId, setNewCourseId] = useState(null);
  const [currentLectureId, setCurrentLectureId] = useState(null);

  const { userData } = useContext(AuthContext);
  // console.log(userData?.userInfo?._id);

  const {
    data: myCourse,
    refetch: myCourseRefetch,
    isLoading,
    // } = useGetAllCoursesByInstrutorIdQuery("6579b1e59ac9f4f212c70780");
  } = useGetAllCoursesByInstrutorIdQuery(userData?.userInfo?._id);

  return (
    <div className="overflow-y-hidden ">
      <div className="flex flex-col  min-h-[85vh]   w-full ">
        {isLoading && <CustomLoader />}
        {activeStep === null && (
          <div className="flex flex-col ">
            <CustomButton
              lable={`Create Course `}
              hasIcon={true}
              className={`absolute bottom-3 right-5 !mx-0 bg-orange-500 hover:bg-orange-600 text-white  border-none   font-normal  `}
              onClick={() => {
                setActiveStep(0);
                setChangeValueStepper(true);
                setCourseId(null);
              }}
            >
              <FiPlus className="text-xl" />
            </CustomButton>
          </div>
        )}
        <div className="flex flex-col justify-center items-center   rounded-md mb-10   ">
          <Stepper
            // nonLinear={true}
            connectorStateColors={true}
            activeStep={activeStep}
            className="w-full "
            styleConfig={{
              completedBgColor: "#33B249",
              activeBgColor: "#008AD8",
            }}
            connectorStyleConfig={{
              activeColor: "#008AD8",
              completedColor: "#33B249",
            }}
          >
            <Step
              onClick={() => {
                setActiveStep(null);
                setChangeValueStepper(false);
                setCourseId(null);
              }}
              label={` ${
                changeValueStepper ? " Create Course " : " Manage Course "
              }`}
            />
            <Step
              onClick={() => setActiveStep(1)}
              label={` ${
                changeValueStepper ? "Create Section " : " Manage Section "
              }`}
            />
            <Step
              onClick={() => setActiveStep(2)}
              label={` ${
                changeValueStepper ? "Create Chapter " : " Manage Chapter "
              }`}
            />

            <Step
              onClick={() => setActiveStep(3)}
              label={` ${
                changeValueStepper ? "Add Lectures " : " Manage Lecture "
              }`}
            />
            <Step
              onClick={() => setActiveStep(4)}
              label={` ${
                changeValueStepper ? "Create Question " : "Manage Question "
              }`}
            />
          </Stepper>
        </div>
        <div className="   flex justify-center ">
          {activeStep === 0 && (
            <CreateCoursePopup
              setActiveStep={setActiveStep}
              setNewCourseId={setNewCourseId}
              courseRefetch={myCourseRefetch}
              courseId={courseId}
            />
          )}
          {activeStep === 1 && (
            <ManageSection
              setActiveStep={setActiveStep}
              courseId={courseId}
              setCurrentSectionId={setCurrentSectionId}
              setSectionData={setSectionData}
              setSectionIndex={setSectionIndex}
              newCourseId={newCourseId}
            />
          )}
          {activeStep === 2 && (
            <ManageChapter
              sectionUid={currentSectionId}
              setActiveStep={setActiveStep}
              currentSectionId={currentSectionId}
              sectionData={sectionData}
              sectionIndex={sectionIndex}
              setCurrentChapterData={setCurrentChapterId}
              setCurrentChapterIndex={setCurrentChapterIndex}
            />
          )}
          {activeStep === 3 && (
            <ManageLecture
              setActiveStep={setActiveStep}
              currentChapterIndex={currentChapterIndex}
              chapterId={currentChapterId}
              currentSectionId={currentSectionId}
              courseId={courseId}
              setCurrentLectureId={setCurrentLectureId}
            />
          )}

          {activeStep === 4 && (
            <ManageQuestion
              setActiveStep={setActiveStep}
              currentChapterIndex={currentChapterIndex}
              currentChapterId={currentChapterId}
              currentSectionId={currentSectionId}
              currentCourseId={courseId}
              currentLectureId={currentLectureId}
            />
          )}
        </div>
        {/* ..................................................... */}
        {activeStep === null && (
          <div className=" flex items-center flex-col   h-[78vh] overflow-y-auto no-scrollbar">
            {myCourse?.courses?.map((courseData, index) => (
              <CourseCard
                thumbnails={courseData.courseThumbnail}
                key={index}
                title={courseData.courseName}
                description={courseData.courseDescription}
                courseId={courseData?.courseUid}
                courseData={courseData}
                setActiveStep={setActiveStep}
                setCourseId={setCourseId}
              />
            ))}
          </div>
        )}
        {myCourse?.courses?.length === 0 && activeStep !== 0 && (
          <div className=" flex flex-col items-center justify-center absolute top-[35%] left-0 right-0 w-auto   ">
            <img src={no_data_image} alt="" className="w-[30%]" />
            <p className="text-base text-gray-700   ">
              You have not created any course yet
            </p>
          </div>
        )}
        {/* {error && (
          <p className="flex items-center bg-red-100 self-center px-8 py-2 rounded-lg">
            Error Fetching Course
          </p>
        )} */}
        {/* .......................................... */}
      </div>
    </div>
  );
}

export default Course;
