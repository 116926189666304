import React, { useEffect, useRef, useState } from "react";

import { BsThreeDotsVertical } from "react-icons/bs";
import { LuMonitorCheck, LuMonitorX } from "react-icons/lu";
import { MdDelete, MdModeEditOutline, MdOutlineDelete } from "react-icons/md";
import { FaBook } from "react-icons/fa6";
import DeletePopup from "../../DeletePopup/DeletePopup";
import UpdateCourse from "../UpdateCourse/UpdateCourse";
/**
 * Renders a course card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the course.
 * @param {string} props.description - The description of the course.
 * @param {Function} props.onClick - The click event handler for the card.
 * @param {Array} props.thumbnails - The array of thumbnail images for the course.
 * @returns {JSX.Element} The rendered course card component.
 */

function CourseCard({
  title,
  description,
  thumbnails,
  setActiveStep,
  courseId,

  courseData,
  handleShowEditForm,
  setCourseId,
}) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [publish, setPublish] = useState(false);
  const [iconColor, setIconColor] = useState("");
  const [showUpdateCoursePopup, setShowUpdateCoursepopup] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e?.target)) {
        setShowMenu(false);
        // console.log(menuRef.current);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const menuItems = [
    {
      link: "",
      title: "Edit",
      icon: <MdModeEditOutline />,
    },
    {
      link: "",
      title: publish ? "Unpublish" : "Publish",
      icon: publish ? <LuMonitorX /> : <LuMonitorCheck />,
    },

    {
      link: "",
      title: "Delete",
      className: "text-red-500",
      icon: <MdOutlineDelete />,
    },
  ];

  const handleMenuItems = (index, items, e) => {
    e.preventDefault();
    console.log("three dot click");
    e.stopPropagation();

    if (items?.title === "Edit") {
      e.stopPropagation();

      setShowMenu(false);
      setShowUpdateCoursepopup(true);

      console.log("edit pr click");
    }
    if (index === 1) {
      // setShowMenu(false);
      setPublish(!publish);
    }

    if (items?.title === "Delete") {
      setShowMenu(false);
      setShowDeletePopup(true);
      // console.log(courseId);
    }
  };

  const handleCardClick = () => {
    setCourseId(courseId);
    setActiveStep(1);
    // console.log("card click");
  };

  return (
    <div
      className="w-[80%] min-h-[10rem] tablet:w-full flex justify-between  rounded-md  p-3 my-2  cursor-pointer shadow-no-border-shadow-2"
      onClick={() => handleCardClick()}
      ref={menuRef}
    >
      <div className="w-[30%]  flex justify-start">
        {thumbnails?.[0]?.resourceId &&
        thumbnails?.[0]?.resourceId.length > 0 ? (
          <img
            src={`https://axg6mefkv4.execute-api.ap-south-1.amazonaws.com/aws/serverless/optimize?url=https://d18mg0jr1igx53.cloudfront.net/${thumbnails?.[0]?.resourceId}?auto=compress&cs=tinysrgb&dpr=1&width=200&height=100&quality=10`}
            alt=""
            className="rounded-lg"
          />
        ) : (
          <div
            className="
            flex justify-center items-center
            h-[100px] w-[200px] rounded-lg
            bg-gradient-to-r from-gray-700 via-gray-900 to-black
          "
          >
            <p className="text-white text-center text-xs font-thin self-center">
              No Thumbnail
            </p>
          </div>
        )}
      </div>
      <div className="w-full ms-5  flex flex-col justify-start items-start relative z-0">
        <p className=" text-lg mb-3 text-[#313639] capitalize line-clamp-1">
          {title}{" "}
        </p>
        <p className="text-sm pe-10 text-gray-400  h-[6rem] w-full overflow-y-auto no-scrollbar">
          {description}
        </p>
        <BsThreeDotsVertical
          className={`absolute right-0  text-3xl p-1  cursor-pointer z-10 hover:bg-gray-300/50 rounded-md ${
            showMenu ? "bg-gray-200" : "bg-gray-10"
          } `}
          onClick={(e) => {
            setShowMenu(!showMenu);
            e.stopPropagation();
          }}
        />
        {showMenu && (
          <ul
            className={`absolute self-end bg-white shadow-lg min-w-[10rem] max-w-[20rem]  rounded  right-8 z-30 `}
          >
            {menuItems?.map((items, index) => (
              <li
                key={index}
                className={` hover:bg-gray-100 ps-3 px-5  py-3 flex items-center cursor-pointer  text-sm ${items?.className}`}
                onClick={(e) => {
                  handleMenuItems(index, items, e);
                }}
              >
                <span className="me-4 text-lg text-gray-700 bg-gray-200 p-1.5 rounded-full  ">
                  {items?.icon}
                </span>{" "}
                {items?.title}
              </li>
            ))}
          </ul>
        )}
      </div>

      {showDeletePopup && (
        <DeletePopup
          zoomInPopup={`animate-zoomInPopup`}
          title={`Delete Course`}
          description={`Are you sure this item will be removed ? Once deleted this item will not be returned. `}
          cancelOnClick={() => setShowDeletePopup(false)}
        />
      )}
      {showUpdateCoursePopup && (
        <UpdateCourse
          setShowUpdateCoursepopup={setShowUpdateCoursepopup}
          rightPopup={`animate-rightPopup`}
          courseId={courseId}
          setActiveStep={setActiveStep}
        />
      )}
    </div>
  );
}

export default CourseCard;
