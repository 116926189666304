import React from "react";
import MenuSidebar from "./crm/MenuSidebar/MenuSidebar";
import ProfileSidebar from "./crm/ProfileSidebar/ProfileSidebar";
import ContentAriea from "./crm/Content-Ariea/Content-Ariea";

function Educator({ children }) {
  return (
    <div className=" w-full h-[100vh] flex bg-gray-200 overflow-y-auto no-scrollbar">
      <MenuSidebar />
      <ContentAriea></ContentAriea>
      {/* <ProfileSidebar /> */}
    </div>
  );
}

export default Educator;
