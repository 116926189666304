import React from "react";

import { IoIosArrowDown } from "react-icons/io";
import GradientLineChart from "./lineChart";
function GraphBox() {
  return (
    <div className=" w-full p-5 flex flex-col gap-5 justify-between shadow-no-border-shadow-2  rounded-xl">
      <div className="flex items-end justify-between">
        <div className="">
          <p className="">Performance</p>
          <div className="flex items-center gap-5 text-xs">
            <p className="flex items-center gap-1 text-gray-400">
              <span className="text-xl text-green-500">•</span> Course Growth
            </p>
            <p className="flex items-center gap-1 text-gray-400">
              <span className="text-xl text-green-500">•</span> Workshop Growth
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between gap-5 text-gray-500  border p-2 cursor-pointer rounded-md px-3">
          <p className="text-xs">Latest 6 Months</p>
          <IoIosArrowDown />
        </div>
      </div>
      <div className="">
        <GradientLineChart />
      </div>
    </div>
  );
}

export default GraphBox;
