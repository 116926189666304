import React from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import PieChart from "./piechart";

function DashboardRatingGraphBox() {
  return (
    <div className="p-5 rounded-xl shadow-no-border-shadow-2 h-full flex flex-col justify-between">
      <div className="flex justify-between items-center">
        <p className="">Average Rating</p>
        <HiDotsHorizontal className="text-4xl p-2 bg-gray-100 rounded-lg cursor-pointer" />
      </div>
      <div className="">
        <PieChart />
      </div>
    </div>
  );
}

export default DashboardRatingGraphBox;
