import React, { useEffect, useState } from "react";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { useGetAllChaptersQuery } from "../../../redux/ReduxToolkit";
import CreateChapterPopup from "./CreateChapterPopup";
import ChapterCard from "./ChapterCard";
import CustomLoader from "../../Loading/Loading";

import no_data_image from "../../../assets/no_data(course_component).png";

function ManageChapter({ setActiveStep, setCurrentChapterData, sectionUid }) {
	const [showChapterForm, setShowChapterForm] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const [sectionTitle, setSectionTitle] = useState("");
	const [showEditMode, setShowEditMode] = useState(false);
	const [chapterFormData, setChapterFormData] = useState({
		chapterName: "",
		chapterDescription: "",
	});

	const {
		data: getChapterBySectionIdData,
		isLoading,
		refetch: reFetchManageChapter,
	} = useGetAllChaptersQuery(sectionUid);

	// const [createChapter] = useCreateChapterMutation();

	// console.log("chapter component me section ki id = ", currentSectionId);
	// console.log(
	//   "chapter component me sectionData =  ",
	//   sectionData[sectionIndex]?.sectionContent?.chapter
	// );

	useEffect(() => {
		if (showChapterForm) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "scroll";
		}
	}, [showChapterForm]);

	const handleSelectSectionTitle = (sectionData) => {
		// setShowChapterCard(true);
		setShowDropdown(false);
		setSectionTitle(sectionData?.sectionName);
	};

	const handleSelectCurrentChapter = (chapters, index) => {
		setCurrentChapterData(chapters?.chapterUid);
		setActiveStep(3);
	};

	return (
    <div className="  flex flex-col justify-center items-center w-full ">
      {isLoading && <CustomLoader />}
      <div className="w-[90%] flex  justify-center min-h-[50vh] ">
        <div className=" bottom-5 right-5 absolute flex flex-col justify-center">
          <CustomButton
            lable={`Create Chapter`}
            className={`bg-orange-500 hover:bg-orange-600 text-white  border-none   font-normal mt-3 w-[10rem]`}
            onClick={() => setShowChapterForm(true)}
          />
          {/* <CustomButton
            lable={`Next`}
            className={`bg-green-500 hover:bg-green-600 text-white  border-none   font-normal mt-3 w-[10rem]`}
            onClick={() => setActiveStep(3)}
          /> */}
        </div>

        {getChapterBySectionIdData?.sections?.chapters?.length > 0 ? (
          <div className="flex justify-start flex-col items-center w-[90%] py-0 px-5  h-[75vh] overflow-y-auto no-scrollbar">
            {getChapterBySectionIdData?.sections?.chapters?.map(
              (item, index) => (
                <ChapterCard
                  key={index}
                  thumbnails={item.sectionThumbnail}
                  setChapterFormData={setChapterFormData}
                  title={item?.chapterName}
                  description={item?.chapterDescription}
                  setActiveStep={setActiveStep}
                  setShowEditMode={setShowEditMode}
                  setShowChapterForm={setShowChapterForm}
                  onclick={() => handleSelectCurrentChapter(item, index)}
                />
              )
            )}
          </div>
        ) : (
          !isLoading && (
            <div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
              <div className=" flex justify-center ">
                <img src={no_data_image} alt="" className="w-[30%]" />
              </div>
              <p className="text-lg text-gray-700   ">
                You have not created any chapter yet
              </p>
            </div>
          )
        )}

        {(showChapterForm || showEditMode) && (
          <CreateChapterPopup
            refetch={reFetchManageChapter}
            setShowEditMode={setShowEditMode}
            setShowChapterForm={setShowChapterForm}
            sectionId={sectionUid}
            chapterFormData={chapterFormData}
            setChapterFormData={setChapterFormData}
          />
        )}
      </div>
    </div>
  );
}

export default ManageChapter;
