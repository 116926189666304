import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import CustomInput from "../../../Pages/Shared/CustomInput/CustomInput";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";

function CoursePricePopup({
  setShowPricePopup,
  zoomInPopup,
  setPriceList,
  priceList,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("Duration");

  const [isDiscounted, setIsDiscounted] = useState(false);
  const [isTrail, setIsTrail] = useState(false);

  const [priceTag, setpriceTag] = useState(0);
  const [discountedValue, setDiscountedValue] = useState(0);
  const [packName, setPackName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [error, setError] = useState(false);

  const duration = [
    { durationId: 1, day: 7 },
    { durationId: 2, day: 15 },
    { durationId: 3, day: 30 },
    { durationId: 4, day: 180 },
    { durationId: 5, day: 365 },
  ];

  const handleSave = () => {
    if (packName === null || packName === undefined || packName.length === 0) {
      setError(true);
      setErrMsg("Pack Name is required !");
      return;
    }
    if (dropdownValue === "Duration") {
      setError(true);
      setErrMsg("Please Select Duration !");
      return;
    }

    const copy = [
      ...priceList,
      {
        packName: packName,
        priceTag: priceTag,
        duration: parseInt(dropdownValue),
        isDiscounted: isDiscounted,
        isTrail: isTrail,
        discountRate: parseInt(discountedValue),
      },
    ];

    // console.log("copy", copy);

    setPriceList(copy);
    setShowPricePopup(false);
    setPackName("");
    setpriceTag(0);
    setDropdownValue("Duration");
    setIsDiscounted(false);
    setIsTrail(false);
    setDiscountedValue(0);
  };

  return (
    <div className="w-screen h-screen bg-[rgba(128,128,128,.8)] fixed top-0 left-0 flex justify-center items-center   ">
      <div
        className={`bg-white  lg:w-[30%] tablet:w-[60%]  p-10 relative rounded-md shadow-lg ${zoomInPopup}`}
      >
        <div className="text-3xl ms-auto  mb-5 ">
          <AiFillCloseCircle
            className="cursor-pointer absolute top-3 right-3"
            onClick={() => setShowPricePopup(false)}
          />
        </div>

        {error && (
          <div className="bg-red-400 rounded-md p-5 py-3 mb-3 text-sm text-white ">
            {errMsg}
          </div>
        )}

        <div className=" w-full mt-5 ">
          <p className="">Pack Name</p>
          <CustomInput
            className={`!border !rounded-md !py-2 !border-gray-200 !px-5 placeholder:text-gray-400 !mt-0 text-sm `}
            placeholder={`Enter Pack Name`}
            onChange={(e) => {
              setPackName(e.target.value);
              setError(false);
            }}
          />
        </div>

        <div className="flex flex-col">
          <div className="">
            <p className=" mb-3">Select Duration</p>
            <div
              className="border rounded-md px-5 py-2  flex justify-between items-center cursor-pointer text-gray-800"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <p className="  ">{dropdownValue} </p>

              <MdOutlineKeyboardArrowDown
                className={`text-2xl duration-300 ${
                  showDropdown ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>
            {showDropdown && (
              <ul className=" shadow-lg bg-white py-3 mx-1 absolute z-10 w-[75%] overflow-y-auto h-[10rem]  text-gray-800">
                {duration?.map((duration, index) => (
                  <li
                    key={index}
                    className=" px-5 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      {
                        setDropdownValue(`${duration?.day}`);
                        setShowDropdown(false);
                        setError(false);
                      }
                    }}
                  >
                    {duration?.day} Days
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className=" w-full mt-5 ">
            <p className="">Add Price</p>
            <CustomInput
              type={`number`}
              className={`!border !rounded-md !py-2 !border-gray-200 !px-5 placeholder:text-gray-400 !mt-0 text-sm `}
              placeholder={`Add Price (Default value 0)`}
              onChange={(e) => setpriceTag(e.target.value)}
            />
          </div>

          <div className=" px-1 mb-5">
            <div className="flex items-center my-2">
              <input
                type="checkbox"
                className=""
                onClick={() => setIsTrail(!isTrail)}
              />{" "}
              <p className="ms-2 text-sm font-normal">
                Do you want to offer trial course ?
              </p>
            </div>
            {!isTrail && (
              <div className="flex items-center my-2">
                <input
                  type="checkbox"
                  className=""
                  onClick={() => setIsDiscounted(!isDiscounted)}
                />{" "}
                <p className="ms-2 text-sm font-normal">
                  Do you want to offer discount ?
                </p>
              </div>
            )}
            {isDiscounted && (
              <CustomInput
                type={`number`}
                className={`!border !rounded-md !py-2 !border-gray-200 !px-5 placeholder:text-gray-800 !font-light text-sm`}
                placeholder={`Discount Rate (%)`}
                onChange={(e) => setDiscountedValue(e.target.value)}
              />
            )}
          </div>

          <div className="flex justify-end">
            <CustomButton
              lable={`Cancel`}
              className={`w-[5rem] !px-0 bg-red-500 text-white border-none `}
              onClick={() => setShowPricePopup(false)}
            />
            <CustomButton
              lable={`Save`}
              className={`w-[5rem] !px-0 bg-green-500 text-white border-none !me-0 `}
              onClick={() => handleSave()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoursePricePopup;
