import React, { useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";

import { useEducatorKycDetailsMutation } from "../../../redux/ReduxToolkit";
import { enqueueSnackbar } from "notistack";

function StepFourth({ educatorId }) {
  const [educatorKycDetails] = useEducatorKycDetailsMutation();
  const [fileData, setFileData] = useState();
  const [fileName, setFileName] = useState("");

  const handleSelectDocument = async (e) => {
    console.log("file name = ", e.target.name);
    console.log("file value = ", e.target.value);

    let formData = new FormData();
    // formData.append("educatorId", "931e17b6-b37a-48ca-9888-673cad863563");
    formData.append("educatorId", educatorId);
    formData.append("documentName", e.target.name);
    formData.append("file", e.target.files[0]);
    setFileData(formData);
    setFileName(e.target.name);
  };

  const handleFileUpload = async () => {
    try {
      const response = await educatorKycDetails({
        body: fileData,
      });

      if (response) {
        // console.log("educator data  = ", response);
        enqueueSnackbar("File Uploading Successfully !", {
          autoHideDuration: 2000,
          variant: "success",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else {
        console.log("Oops! user is not created");
      }

      // setUploadThumbnailResponse(uploadThumbnailResponse?.data);
      // console.log("uploadThumbnailResponse", uploadThumbnailResponse?.data);
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  console.log("file ki length = ", fileData);

  return (
    <div className="">
      <div className="text-center w-full text-xl  text-[#313639] font-medium mb-20">
        Upload Document
      </div>
      <div className="flex justify-center  flex-col mt-10 gap-10">
        <div className="flex justify-between  ">
          <p className="">Aadhar Card</p>
          <input
            type="file"
            name={`aadhar`}
            className="cursor-pointer "
            onChange={(e) => handleSelectDocument(e)}
          />
          {fileName === "aadhar" && (
            <CustomButton
              lable={`Upload`}
              className={`bg-blue-700 text-white border-none !mx-0 hover:bg-blue-600`}
              onClick={() => handleFileUpload()}
            />
          )}
        </div>
        <div className="flex justify-between ">
          <p className="">Pan Card</p>
          <input
            type="file"
            name={`pan`}
            className="cursor-pointer "
            onChange={(e) => handleSelectDocument(e)}
          />
          {fileName === "pan" && (
            <CustomButton
              lable={`Upload`}
              className={`bg-blue-700 text-white border-none !mx-0 hover:bg-blue-600`}
              onClick={() => handleFileUpload()}
            />
          )}
        </div>
        <div className="flex justify-between ">
          <p className="">High School Marksheet</p>
          <input
            type="file"
            name={`high_school`}
            className="cursor-pointer "
            onChange={(e) => handleSelectDocument(e)}
          />
          {fileName === "high_school" && (
            <CustomButton
              lable={`Upload`}
              className={`bg-blue-700 text-white border-none !mx-0 hover:bg-blue-600`}
              onClick={() => handleFileUpload()}
            />
          )}
        </div>
        <div className="flex justify-between ">
          <p className="">Intermediate Marksheet</p>
          <input
            type="file"
            name={`inter`}
            className="cursor-pointer "
            onChange={(e) => handleSelectDocument(e)}
          />
          {fileName === "inter" && (
            <CustomButton
              lable={`Upload`}
              className={`bg-blue-700 text-white border-none !mx-0 hover:bg-blue-600`}
              onClick={() => handleFileUpload()}
            />
          )}
        </div>
        <div className="flex justify-between ">
          <p className="">Graduation Marksheet </p>
          <input
            type="file"
            name={`graduation`}
            className="cursor-pointer "
            onChange={(e) => handleSelectDocument(e)}
          />
          {fileName === "graduation" && (
            <CustomButton
              lable={`Upload`}
              className={`bg-blue-700 text-white border-none !mx-0 hover:bg-blue-600`}
              onClick={() => handleFileUpload()}
            />
          )}
        </div>
        <div className="flex justify-between ">
          <p className="">Post Graduation Marksheet </p>
          <input
            type="file"
            name={`post_graduation`}
            className="cursor-pointer "
            onChange={(e) => handleSelectDocument(e)}
          />
          {fileName === "post_graduation" && (
            <CustomButton
              lable={`Upload`}
              className={`bg-blue-700 text-white border-none !mx-0 hover:bg-blue-600`}
              onClick={() => handleFileUpload()}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default StepFourth;
