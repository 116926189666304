import React, { useEffect, useState } from "react";

import ReactHlsPlayer from "@ducanh2912/react-hls-player";

import { IoCloseSharp } from "react-icons/io5";
import { useGetLectureByLectureIdQuery } from "../../../redux/ReduxToolkit";
import { ShimmerThumbnail } from "react-shimmer-effects";

import no_file from "../../../assets/pug-dog.png"


function PreviewLecture({ rightPopup, setShowPreviewBox, lectureAllData }) {
  const [videoPath, setVideoPath] = useState();
  const [responceStatus, setResponseStatus] = useState();
  const [thubnailUrl, setThubnailUrl] = useState([]);
  const [hideScrollbar, setHideScrollbar] = useState(false);

  // console.log("lecture id ",lectureAllData?.lectureUid)

  const { data: playerData } = useGetLectureByLectureIdQuery(
    lectureAllData?.lectureUid
  );

  useEffect(() => {
    setResponseStatus(playerData?.data?.detail?.status);
    const videoUrl =
      playerData?.data?.detail?.outputGroupDetails?.[0]?.outputDetails?.[0]
        ?.outputFilePaths?.[0];

    const videoFinalUrl = videoUrl?.split("/xper-bucket");
    setVideoPath(videoFinalUrl?.[1]);
    let urls = [];

    playerData?.data?.detail?.outputGroupDetails?.[2]?.outputDetails?.[0]?.outputFilePaths?.map(
      (images) => {
        try {
          var finalImageArray = images.split("/xper-bucket");
          const maxCount = parseInt(finalImageArray?.[1]?.split(".")[1]);
          for (let i = 0; i <= maxCount; i++) {
            var localpath = `${
              finalImageArray?.[1]?.split(".")[0] +
              "." +
              FormatInteger(i, 7) +
              "." +
              finalImageArray?.[1]?.split(".")[2]
            }`;
            urls.push(localpath);
            // console.log("Image Path at ", localpath);
          }
        } catch (e) {
          console.log("Error");
        }
      }
    );
    setThubnailUrl(urls);
  }, [playerData]);

  function FormatInteger(num, length) {
    return (num / Math.pow(10, length)).toFixed(length).substr(2);
  }

  return (
    <div className="w-screen h-screen bg-[rgba(128,128,128,.8)] flex justify-end items-center fixed top-0 left-0 z-20 cursor-default  ">
      <div
        className={`bg-white  lg:w-[40rem] h-[100vh] tablet:w-[60%]  p-10 pt-20  shadow-lg relative ${rightPopup} `}
      >
        <IoCloseSharp
          className={`absolute top-5   right-10 text-3xl text cursor-pointer hover:text-gray-700 text-gray-600 `}
          onClick={() => setShowPreviewBox(false)}
        />

        {playerData?.data?.detail?.outputGroupDetails?.length === null ||
        playerData?.data?.detail?.outputGroupDetails?.length === undefined ? (
          <div className=" flex flex-col items-center justify-center">
            <img src={no_file} alt="" className={`w-[10rem]`} />
            <p className="text-xl text-gray-600  font-medium text-center mt-3">
              There is no video in this lecture
            </p>
          </div>
        ) : responceStatus !== "COMPLETE" ? (
          <div className="relative ">
            <ShimmerThumbnail height={250} rounded className={`w-full`} />
            <p className="">Upload Processing</p>
          </div>
        ) : (
          <div className="">
            <div className="">
              <p className="text-2xl font-medium text-gray-700">
                Lecture Preview
              </p>
            </div>
            <div className="  w-full  my-3 mt-10 ">
              <ReactHlsPlayer
                src={`https://d18mg0jr1igx53.cloudfront.net${videoPath}`}
                autoPlay={false}
                controls={true}
                width="100%"
                height="auto"
                className="rounded-md shadow-lg"
              />
            </div>
            <div className="px-3">
              <p className="font-normal text-lg">
                {lectureAllData?.lectureName}
              </p>
              <p className="mt-3 text-xs">
                {lectureAllData?.lectureDescription}
              </p>
            </div>
            <div className={`my-5 mt-10 flex  flex-col`}>
              <p className="text-xl text-gray-700  ">Thumbnails</p>

              <div
                className={`my-5  flex  gap-3 overflow-x-auto  ${
                  hideScrollbar ? "no-scrollbar" : ""
                }`}
              >
                {thubnailUrl?.map((thumbnail, index) => {
                  return (
                    <img
                      key={index}
                      src={`https://d18mg0jr1igx53.cloudfront.net${thumbnail}`}
                      alt=""
                      className="w-[10rem] h-[5rem] rounded hover:h-[7rem] hover:w-[14rem] hover:gap-4  duration-300 cursor-pointer mb-2"
                      onMouseEnter={() => setHideScrollbar(true)}
                      onMouseLeave={() => setHideScrollbar(false)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PreviewLecture;
