import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const PieChart = () => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const ctx = chartContainer.current.getContext("2d");

    // Data for the pie chart
    const pieData = {
      // labels: ["Red", "Blue", "Yellow", "Green", "Purple"],
      labels: ["Pass", "Average", "Fail"],

      datasets: [
        {
          label: "My Dataset",
          data: [70, 20, 10],
          // data: [20, 30, 15, 20, 15],
          backgroundColor: [
            "rgba(75, 192, 192, 0.8)", // Green

            "rgba(255, 205, 86, 0.8)", // Yellow
            "rgba(255, 99, 132, 0.8)", // Red

            // "rgba(54, 162, 235, 0.8)", // Blue

            // "rgba(153, 102, 255, 0.8)", // Purple
          ],
        },
      ],
    };

    chartInstance.current = new Chart(ctx, {
      type: "pie",
      data: pieData,
      options: {
        plugins: {
          legend: {
            display: true,
            position: "bottom",
          },
        },
      },
    });

    return () => {
      // Clean up: destroy chart instance when component unmounts
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div className=" w-full h-[20rem] flex justify-center  relative ">
      <canvas ref={chartContainer} width={100} height={100} />
    </div>
  );
};

export default PieChart;
