import React, { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function Goals({ allGoal, setCurrentSubGoalList }) {
  const menuRef = useRef();
  const [dropdownValue, setDropdownValue] = useState("Choose goal");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSelectDropDownValue = (goal) => {
    setDropdownValue(`${goal?.goalTitle}`);
    setShowDropdown(false);
    setCurrentSubGoalList(goal);
  };

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e?.target)) {
        // console.log(menuRef.current);
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // console.log(allGoal);

  return (
    <div className="mb-5 text-sm relative" ref={menuRef}>
      <p className=" mb-1 text-[#7A7D7E] ">Your Goal</p>
      {/* {error && <p className="text-red-500">{errMsg}</p>} */}
      <div
        className="border rounded-md px-5 py-2  flex justify-between items-center cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <p className=" text-[#7A7D7E] ">{dropdownValue}</p>
        <MdOutlineKeyboardArrowDown
          className={`text-2xl duration-300 ${
            showDropdown ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
      {showDropdown && (
        <ul className=" shadow-lg bg-white py-3 absolute left-0 right-0 mx-auto z-10 w-full overflow-y-auto h-[10rem]  text-[#7A7D7E]">
          {allGoal?.msg?.map((goal, index) => (
            <li
              key={index}
              className=" px-5 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                {
                  handleSelectDropDownValue(goal);
                }
              }}
            >
              {goal?.goalTitle}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Goals;
