import React, { useState } from "react";
import CloudCourse from "./CloudVideosFolder/CloudCourse";
import CloudSection from "./CloudVideosFolder/CloudSection";
import CloudChapter from "./CloudVideosFolder/CloudChapter";
import CloudVideosFolder from "./CloudVideosFolder/CloudVideosFolder";
import CloudNotesFolder from "./CloudNotesFolder/CloudNotesFolder";
import CloudLectures from "./CloudVideosFolder/CloudLectures";
import LiveSessionFolder from "./LiveSession/LiveSessionFolder";
import LiveRecordings from "./LiveSession/LiveRecordings";

function CloudStoreage() {
	const [showSection, setShowSection] = useState(false);
	const [showChapter, setShowChapter] = useState(false);
	const [showLecture, setShowLecture] = useState(false);
	const [showCourses, setShowCourse] = useState(false);

	const [currentCourseId, setCurrentCourseId] = useState(null);
	const [currentSectionId, setCurrentSectionId] = useState(null);
	const [currentChapterId, setCurrentChapterId] = useState(null);
	const [currentLectureData, setCurrentLectureData] = useState(null);

	const [showLiveRecordingBox, setShowLiveRecordingBox] = useState(false);
	return (
		<div className="flex justify-start w-full  h-[85vh] overflow-y-auto no-scrollbar">
			<div className=" flex flex-col justify-start mx-5  w-full">
				<div className="">
					{!showCourses &&
						!showSection &&
						!showChapter &&
						!showLecture &&
						!showLiveRecordingBox && (
							<div className="flex flex-row flex-wrap">
								<CloudVideosFolder onDoubleClick={() => setShowCourse(true)} />
								<CloudNotesFolder />
								<LiveSessionFolder
									onDoubleClick={() => setShowLiveRecordingBox(true)}
								/>
							</div>
						)}
				</div>

				<div className="">
					{showCourses && !showSection && !showChapter && !showLecture && (
						<CloudCourse
							setShowCourse={setShowCourse}
							setShowSection={setShowSection}
							setCurrentCourseId={setCurrentCourseId}
						/>
					)}
				</div>
				<div className="">
					{showSection && !showChapter && !showLecture && (
						<CloudSection
							setShowSection={setShowSection}
							setShowChapter={setShowChapter}
							currentCourseId={currentCourseId}
							setCurrentSectionId={setCurrentSectionId}
						/>
					)}
				</div>

				<div className="">
					{showChapter && !showLecture && (
						<CloudChapter
							setShowChapter={setShowChapter}
							setShowLecture={setShowLecture}
							currentSectionId={currentSectionId}
							setCurrentChapterId={setCurrentChapterId}
						/>
					)}
				</div>

				<div className="">
					{showLecture && (
						<CloudLectures
							setShowLecture={setShowLecture}
							currentChapterId={currentChapterId}
							currentLectureData={currentLectureData}
							setCurrentLectureData={setCurrentLectureData}
						/>
					)}
				</div>

				<div className="">
					{showLiveRecordingBox && (
						<LiveRecordings setShowLiveRecordingBox={setShowLiveRecordingBox} />
					)}
				</div>
			</div>
		</div>
	);
}

export default CloudStoreage;
