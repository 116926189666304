import { IoMdCheckmark } from "react-icons/io";
import heroImage from "../../../assets/heroImage.webp";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { useEffect } from "react";
import FormPopup from "../../TeacherRegistration/FormPopup/FormPopup";

function Hero({ showOnBoardingFrom, setShowOnBoardingFrom }) {
  useEffect(() => {
    if (showOnBoardingFrom) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showOnBoardingFrom]);
  return (
    <div className="w-full">
      <div className="flex  justify-center bg-[#024751] text-[#bce4e9] w-full py-5 lg:pb-20 ">
        <div className="flex flex-col lg:flex-row justify-center  lg:justify-between items-center  w-[95%] lg:w-[80%] tablet:w-[70%]  py-3 lg:text-start">
          <div className="w-full  lg:w-[40%]">
            <p className="text-3xl lg:text-4xl font-semibold text-white mb-5 leading-tight">
              Turn your <br className="md:hidden" /> quizzes <br />
              into success stories
            </p>
            <p className="mb-9 text-sm lg:text-sm lg:w-[80%]">
              AI-powered skills and knowledge assessment software, serving 2.5M+
              business and educational users worldwide.
            </p>
            <CustomButton
              lable="Sign up - it's free"
              onClick={() => setShowOnBoardingFrom(true)}
              className={`bg-[#FFEEB4] text-[#024751] hover:bg-[#faf3dc] !mx-0`}
            />
            <p className="flex justify-center lg:justify-start items-center gap-3 mt-5 w-full text-center text-sm">
              <IoMdCheckmark className="text-xl" />
              No credit card required
            </p>
          </div>
          <div className=" mt-10  lg:w-[50%]">
            <img src={heroImage} alt="" />
          </div>
        </div>
      </div>
      <div className="bg-[#D2C3FB] w-full flex justify-center py-5 items-center ">
        <div className="flex flex-col lg:flex-row justify-between items-center w-[95%] lg:w-[80%] ">
          <div className="text-white ">
            <p className="text-[#024751] font-semibold text-3xl mb-3">
              Here to take a test?
            </p>
            <p className=" text-sm text-gray-600 ">
              No registration required. Enter your access code and start.
            </p>
          </div>
          <div className="flex flex-col md:flex-row items-center mt-10 lg:mt-0 w-full  lg:w-auto">
            <input
              type="text"
              placeholder="Enter the access code"
              className="p-3 px-3 w-full lg:w-[30rem] rounded-lg focus:outline-none"
            />
            <CustomButton
              lable="Start your test"
              className="bg-[#024751] hover:bg-[#275e66] text-white border-none !py-3  mt-5 w-full lg:w-auto md:mt-0"
            />
          </div>
        </div>
      </div>

      {showOnBoardingFrom && (
        <FormPopup
          setShowOnBoardingFrom={setShowOnBoardingFrom}
          zoomInPopup={`animate-zoomInPopup`}
        />
      )}
    </div>
  );
}

export default Hero;
