import React, { useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";

import EducationForm from "./EducationForm";
import { useEducatorQualificationMutation } from "../../../redux/ReduxToolkit";

function StepSecond({ setActiveStep, educatorId }) {
  const [qualification, setQualification] = useState([]);

  // console.log("2nd step me  select me option ki value = ", showOptionsBox);

  const handleAddQualification = () => {
    setQualification([...qualification, {}]);
  };

  return (
    <div>
      <div className=" flex flex-col justify-center h-full p-5  ">
        <div className="self-center mb-10">
          <p className="text-2xl font-medium text-[#313639]">
            Education Qualification
          </p>
        </div>

        {qualification.map((education, index) => (
          <EducationForm
            key={index}
            index={index}
            qualification={qualification}
            setQualification={setQualification}
            educatorId={educatorId}
          />
        ))}

        {/* ....................................... */}
        <div className="flex justify-between ">
          {/* ....................................... */}
          <div className="mt-6 flex items-center justify-center gap-x-6">
            <CustomButton
              lable={` Add Qualification`}
              className={` bg-blue-700 text-white border-none !mx-0 hover:bg-blue-600`}
              onClick={() => handleAddQualification()}
            />
          </div>
          <div className="mt-6 flex items-center justify-center gap-x-6">
            <CustomButton
              lable={` NEXT`}
              className={` bg-red-500 text-white border-none !mx-0 hover:bg-red-600`}
              onClick={() => setActiveStep(2)}
            />
          </div>
          {/* ....................................... */}
        </div>
      </div>
    </div>
  );
}

export default StepSecond;
