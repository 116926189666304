import React, { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function CourseType({ setCourseTypeList, previousCourseTypeData }) {
  const menuRef = useRef();
  const [dropdownValue, setDropdownValue] = useState("Course Type");
  const [showDropdown, setShowDropdown] = useState(false);

  const courseTypes = [
    "Recorded Lectures",
    "Live Lectures",
    "Full Course",
    "Notes Only",
    "PYQ",
    "Crash Course",
    "Discussion",
    "Test Series",
  ];

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e?.target)) {
        // console.log(menuRef.current);
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    if (previousCourseTypeData?.[0]) {
      setDropdownValue(previousCourseTypeData?.[0]);
      setCourseTypeList(previousCourseTypeData?.[0]);
    }
  }, [previousCourseTypeData]);

  return (
    <div className="text-[#7A7D7E] relative text-sm" ref={menuRef}>
      <p className=" mb-1">Course Type</p>
      <div
        className="border rounded-md px-5 py-2  flex justify-between items-center cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <p className="  ">{dropdownValue}</p>
        <MdOutlineKeyboardArrowDown
          className={`text-2xl duration-300 ${
            showDropdown ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
      {showDropdown && (
        <ul className=" shadow-lg bg-white py-3 absolute left-0 right-0 mx-auto z-10 w-full overflow-y-auto h-[10rem] ">
          {courseTypes?.map((courseTypeValue, index) => (
            <li
              key={index}
              className=" px-5 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                {
                  setDropdownValue(`${courseTypeValue}`);
                  setShowDropdown(false);
                  setCourseTypeList(courseTypeValue);
                }
              }}
            >
              {courseTypeValue}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CourseType;
