import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import InputFieldWithBorder from "../../../Pages/Shared/CustomInput/InputFieldWithBorder";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";

function AddNewBank({ rightPopup, setShowAddBankForm }) {
  const [aadhar, setAadhar] = useState(null);
  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNo, setAccountNo] = useState(null);
  const [ifsc, setIfsc] = useState("");
  return (
    <div className="w-screen h-screen bg-[rgba(128,128,128,.8)] flex justify-end items-center fixed top-0 left-0 z-50 cursor-default ">
      <div
        className={`bg-white  lg:min-w-[30rem] h-[100vh] tablet:w-[60%]    shadow-lg relative ${rightPopup} `}
      >
        <div className="p-5 w-full flex justify-between  border-b border-[#237C43]">
          <p className="font-bold">Add New Bank Account</p>
          <IoCloseSharp
            className={` text-3xl text cursor-pointer hover:text-gray-700 text-gray-600 hover:rotate-45 duration-200  uppercase`}
            onClick={() => {
              setShowAddBankForm(false);
            }}
          />
        </div>
        <div className="p-10">
          <div className="">
            <InputFieldWithBorder
              type={`number`}
              label={`Aadhar No.`}
              onChange={(e) => setAadhar(e.target.value)}
              inputClassName={`!mb-3`}
            />
          </div>{" "}
          <div className="">
            <InputFieldWithBorder
              label={`Bank Name`}
              onChange={(e) => setBankName(e.target.value)}
              inputClassName={`!mb-3 uppercase `}
            />
          </div>
          <div className="">
            <InputFieldWithBorder
              label={`Account Holder Name`}
              onChange={(e) => setAccountHolderName(e.target.value)}
              inputClassName={`!mb-3 uppercase`}
            />
          </div>
          <div className="">
            <InputFieldWithBorder
              type={`number`}
              label={`Account No`}
              onChange={(e) => setAccountNo(e.target.value)}
              inputClassName={`!mb-3`}
            />
          </div>
          <div className="">
            <InputFieldWithBorder
              label={`IFSC Code`}
              onChange={(e) => setIfsc(e.target.value)}
              inputClassName={`!mb-3 uppercase`}
            />
          </div>
          <div className="">
            <CustomButton
              lable={`Add`}
              className={`bg-[#34AA2E] hover:bg-[#3e9e39]  text-white border-none hover:scale-95 duration-200 !mx-0`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewBank;
