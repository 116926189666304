import React, { useState } from "react";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import AddNewBank from "./AddNewBank";

function AccountServices({ userInfo }) {
  const [showAddBankForm, setShowAddBankForm] = useState(false);
  return (
    <div className="flex flex-col gap-5">
      <div className="shadow-no-border-shadow-3 rounded-lg flex items-center justify-between">
        <div className="p-5 text-[#34AA2E] font-bold">Account Services </div>
        <div className="">
          <CustomButton
            lable={`Add New Bank Account`}
            className={`bg-[#34AA2E]  text-white border-none hover:scale-95 duration-200 `}
            onClick={() => setShowAddBankForm(true)}
          />
        </div>
      </div>
      <div className=" w-[40rem] my-5 rounded-lg p-5   relative shadow-no-border-shadow-3">
        <div className="flex items-center  px-5 md:px-0">
          <div className=" flex justify-center  w-[30%] md:w-[20%]">
            {userInfo?.avatar?.url ? (
              <img
                src={userInfo?.avatar?.url}
                alt=""
                className=" w-[8rem] h-[8rem] rounded-full"
              />
            ) : (
              <img
                src={require("../../../assets/awtar.png")}
                alt=""
                className=" w-[8rem] h-[8rem] rounded-full"
              />
            )}
          </div>
          <div className="ms-10 ">
            <p className="text-xl md:text-3xl font-bold text-gray-700">
              Krishna Kumar Gautam
            </p>
            <p className=" text-xsm md:text-sm text-stone-400 mt-2">
              Joined DexLabz in Oct 2023
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-5 w-[30rem] text-stone-600 mt-5 text-xs md:text-sm border border-[#237C43] rounded-lg  p-5 ms-5 ">
          <div className="flex flex-col gap-2   ">
            <div className="flex justify-between ">
              <p className="">Email Id : </p>
              <p className="">kkgkrishna112@gmail.com</p>
            </div>{" "}
            <div className="flex justify-between ">
              <p className="">Mobile No : </p>
              <p className="">+91 7651893713</p>
            </div>{" "}
            <div className="flex justify-between ">
              <p className="">Aadhar Number : </p>
              <p className="">314556835392</p>
            </div>{" "}
            <div className="flex justify-between ">
              <p className="">Bank Name : </p>
              <p className="">Bank Of Baroda</p>
            </div>{" "}
            <div className="flex justify-between ">
              <p className="">Account Number : </p>
              <p className="">20838100013817</p>
            </div>
            <div className="flex justify-between ">
              <p className="">Ifsc Code : </p>
              <p className="">BARB0MANKAN</p>
            </div>
          </div>
        </div>
      </div>

      {showAddBankForm && (
        <AddNewBank
          setShowAddBankForm={setShowAddBankForm}
          rightPopup={`animate-rightPopup`}
        />
      )}
    </div>
  );
}

export default AccountServices;
