import React, { useState } from "react";
import { TbArrowNarrowLeft } from "react-icons/tb";
import CloudStorageCard from "../CloudStorageCard/CloudStorageCard";
import { useGetAllLectureByChapterIdQuery } from "../../../../redux/ReduxToolkit";
import CloudVideoPlayer from "./CloudVideoPlayer";
import CustomLoader from "../../../Loading/Loading";

import no_data_image from "../../../../assets/cloud_no_data.png";


function CloudLectures({
  setShowLecture,
  currentChapterId,
  currentLectureData,
  setCurrentLectureData,
}) {
  const [showVideoPopup, setShowVideoPopup] = useState(false);

  const {
    data: allLecture,
    isLoading,
    refetch: refetchAllLectureByChapter,
  } = useGetAllLectureByChapterIdQuery(currentChapterId);

  const handleSectionFolderClick = (lecture, index) => {
    setCurrentLectureData(lecture);
    setShowVideoPopup(true);
  };

  return (
    <div className="flex ">
      <TbArrowNarrowLeft
        className={`border text-3xl rounded-lg border-gray-300 px-1 cursor-pointer absolute top-3 -translate-x-7`}
        onClick={() => setShowLecture(false)}
      />

      {isLoading && (
        <div className=" flex justify-center items-center w-full h-[85vh]">
          <CustomLoader />
        </div>
      )}

      {!isLoading &&
        (allLecture?.chapters?.lectures?.length > 0 ? (
          <div className="mt-5 flex flex-row flex-wrap">
            {allLecture?.chapters?.lectures?.map((lecture, index) => (
              <CloudStorageCard
                key={index}
                title={lecture?.lectureName}
                imageType={`mp4`}
                onDoubleClick={() => handleSectionFolderClick(lecture, index)}
              />
            ))}
          </div>
        ) : (
          <div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
            <div className=" flex justify-center ">
              <img src={no_data_image} alt="" className="w-[20%]" />
            </div>
            <p className="text-xl text-gray-700   ">
              You have not created any Lecture yet
            </p>
          </div>
        ))}

      {showVideoPopup && (
        <CloudVideoPlayer
          setShowVideoPopup={setShowVideoPopup}
          currentLectureData={currentLectureData}
        />
      )}
    </div>
  );
}

export default CloudLectures;
