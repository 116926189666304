import React, { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { useEducatorQualificationMutation } from "../../../redux/ReduxToolkit";
import { enqueueSnackbar } from "notistack";

function EducationForm({
  setShowOptionsBox,
  qualification,
  setQualification,
  index,
  educatorId,
}) {
  const [boardName, setBoardName] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [passingYear, setPassingYear] = useState(null);
  const [totalMark, setTotalMark] = useState(null);
  const [obtainedMark, setObtainedMark] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [dropdownValue, setdropdownValue] = useState("");

  const [educatorQualification] = useEducatorQualificationMutation();

  console?.log("EducationForm ka qualification = ", qualification);
  // console?.log("EducationForm ka setQualification = ", setQualification);
  // console?.log("EducationForm ka index = ", index);
  // console?.log(" step 2 me educatorId = ", educatorId);

  const handleSelection = (e) => {
    // console.log("dropdown Value = ", e.target.value);

    setdropdownValue(e.target.value);

    const copy = [...qualification];
    copy[index][e?.target?.value] = {};

    let keysCount = Object.keys(copy[index]).length;
    // console.log("Number of keys:", keysCount);

    if (keysCount === 1) {
      // console.log("code ko chalana hai ");
      copy[index][e?.target?.value] = {};
      // console?.log("copy 1 se chhoti hai", copy);
      setBoardName(e?.target?.value);
      setSchoolName(e?.target?.value);
      setPassingYear(e?.target?.value);
      setTotalMark(e?.target?.value);
      setObtainedMark(e?.target?.value);
      setQualification(copy);
    }
    if (keysCount > 1) {
      const oldKey = Object.keys(qualification[index])[0];
      const oldData = qualification[index][oldKey];
      // console.log("code ko nhi  chalana hai ");
      // console.log('Purana Key',)
      // const puranaData=qualification[index][Object.keys(qualification[index])]
      copy[index][e?.target?.value] = oldData;
      delete qualification[index][oldKey];

      // console?.log("copy 1 se badi hai ", qualification);
      setBoardName(e?.target?.value);
      setSchoolName(e?.target?.value);
      setPassingYear(e?.target?.value);
      setTotalMark(e?.target?.value);
      setObtainedMark(e?.target?.value);

      setQualification(qualification);
    }

    // setBoardName(e?.target?.value);
    // setSchoolName(e?.target?.value);
    // setPassingYear(e?.target?.value);
    // setTotalMark(e?.target?.value);
    // setObtainedMark(e?.target?.value);
    // copy[index][e?.target?.value] = {};
    // // console?.log("copy", copy);
    // setQualification(copy);
  };

  const handleUpdateValue = (e) => {
    if (dropdownValue === "default" || dropdownValue === "") {
      enqueueSnackbar("Please Select Qualification !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }

    console?.log("value", e?.target?.value);
    // console?.log("name", e?.target?.name);
    // const copy = [...qualification];

    [...qualification][index][boardName][e?.target?.name] = e?.target?.value;
    [...qualification][index][schoolName][e?.target?.name] = e?.target?.value;
    [...qualification][index][passingYear][e?.target?.name] = e?.target?.value;
    [...qualification][index][totalMark][e?.target?.name] = e?.target?.value;
    [...qualification][index][obtainedMark][e?.target?.name] = e?.target?.value;

    // console.log(
    //   "board name = ",
    //   [...qualification][index][boardName][e?.target?.name]
    // );
  };

  const handleSaveForm = async () => {
    if (
      [...qualification][index][boardName]?.board_university === null ||
      [...qualification][index][boardName]?.board_university === undefined ||
      [...qualification][index][boardName]?.board_university.length === 0
    ) {
      enqueueSnackbar("Board Name / University Name  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (
      [...qualification][index][schoolName]?.school_college === null ||
      [...qualification][index][schoolName]?.school_college === undefined ||
      [...qualification][index][schoolName]?.school_college.length === 0
    ) {
      enqueueSnackbar("college Name / School Name  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (
      [...qualification][index][passingYear]?.year_of_passing === null ||
      [...qualification][index][passingYear]?.year_of_passing === undefined ||
      [...qualification][index][passingYear]?.year_of_passing.length === 0
    ) {
      enqueueSnackbar("Passing Year  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (
      [...qualification][index][totalMark]?.total_marks === null ||
      [...qualification][index][totalMark]?.total_marks === undefined ||
      [...qualification][index][totalMark]?.total_marks.length === 0
    ) {
      enqueueSnackbar("Total Marks  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (
      [...qualification][index][obtainedMark]?.obtained_marks === null ||
      [...qualification][index][obtainedMark]?.obtained_marks === undefined ||
      [...qualification][index][obtainedMark]?.obtained_marks.length === 0
    ) {
      enqueueSnackbar("Obtained Marks  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }

    const percentageValue =
      ([...qualification][index][obtainedMark]?.obtained_marks * 100) /
      [...qualification][index][totalMark]?.total_marks;

    setPercentage(percentageValue);

    try {
      const response = await educatorQualification({
        body: {
          educatorId: educatorId,
          // educatorId: "931e17b6-b37a-48ca-9888-673cad863563",
          "educationDetail.boardUniversityName": [...qualification][index][
            boardName
          ]?.board_university,
          "educationDetail.collegeName": [...qualification][index][schoolName]
            ?.school_college,
          "educationDetail.passingYear": [...qualification][index][passingYear]
            ?.year_of_passing,
          "educationDetail.totalMarks": [...qualification][index][totalMark]
            ?.total_marks,
          "educationDetail.marksObtained": [...qualification][index][
            obtainedMark
          ]?.obtained_marks,
        },
      });

      // console.log("section created", loading, response);

      if (response) {
        // console.log("api responce = ", response);
      } else {
        console.log("Oops! section is not created");
      }
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  return (
    <div className="mt-5">
      {/* ............................................ */}
      <div className="sm:col-span-3 mb-2">
        <div className="mt-2">
          <select
            className="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:leading-6 text-xs md:text-sm"
            onChange={(e) => handleSelection(e)}
          >
            <option className="py-2 my-10" value="default">
              Select Qualification
            </option>
            <option className="py-2 my-10" value="highSchool">
              10 Class
            </option>
            <option className="py-2 my-10" value="intermediate">
              12 Class
            </option>
            <option className="py-2 my-10" value="graduation">
              Graduation
            </option>
            <option className="py-2 my-10" value="postGraduation">
              Post Graduation
            </option>
          </select>
        </div>
      </div>
      {/* ............................................ */}
      <div className="relative z-0 w-full mb-6 mt-6 group  ">
        <input
          type="text"
          name="board_university"
          className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000"
          placeholder=""
          onChange={(e) => {
            handleUpdateValue(e);
          }}
        />
        <label className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
          Board Name/University Name
        </label>
      </div>

      {/* ............................................ */}
      <div className="relative z-0 w-full mb-6 mt-6 group  ">
        <input
          type="text"
          name="school_college"
          className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000"
          placeholder=""
          onChange={(e) => handleUpdateValue(e)}
        />
        <label className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
          School Name/College Name
        </label>
      </div>

      {/* ............................................ */}
      <div className="flex items-center">
        <div className="relative z-0 w-full  group  me-3">
          <input
            type="number"
            name="year_of_passing"
            className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000"
            placeholder=""
            onChange={(e) => handleUpdateValue(e)}
          />
          <label className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
            Year of Passing
          </label>
        </div>
        <div className="relative z-0 w-full   group  ms-3">
          <input
            type="number"
            name="total_marks"
            className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000"
            placeholder=""
            onChange={(e) => handleUpdateValue(e)}
          />
          <label className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
            Total marks
          </label>
        </div>

        {/* ............................................ */}
      </div>
      <div className="flex">
        <div className="relative z-0 w-full mb-6 mt-6 group  me-3">
          <input
            type="number"
            name="obtained_marks"
            className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000"
            placeholder=""
            onChange={(e) => handleUpdateValue(e)}
          />
          <label className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
            Obtained marks
          </label>
        </div>
        <div className="relative z-0 w-full mt-6 group  ms-3">
          <input
            type="number"
            name="total_marks"
            value={percentage}
            className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5 "
            placeholder=""
            // onChange={(e) => handleUpdateValue(e)}
            disabled
          />
          <label className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
            Percentage
          </label>
        </div>

        {/* ............................................ */}
      </div>

      {/* ....................................... */}

      <div className="mt-6 flex items-center justify-start gap-x-6">
        <CustomButton
          lable={`SAVE`}
          className={`!px-10 bg-green-500 text-white border-none !mx-0`}
          onClick={() => handleSaveForm()}
        />
      </div>
      {/* ....................................... */}
    </div>
  );
}

export default EducationForm;
