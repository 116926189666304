import React, { useContext, useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import {
  useGetAllCoursesByInstrutorIdQuery,
  useGetEnrolledStudentByCourseIdMutation,
} from "../../redux/ReduxToolkit";
import { AuthContext } from "../../Providers/AuthProvider";
import { Utils } from "../../utils/utils";
import CustomLoader from "../Loading/Loading";
import { HiDotsHorizontal } from "react-icons/hi";
import { VscAccount } from "react-icons/vsc";

function ByCourseEnrollment() {
  const { userData } = useContext(AuthContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("select course");
  const [showTable, setShowTable] = useState(false);
  const [showDetailsBox, setShowDetailsBox] = useState(false);

  const { data: courseList } = useGetAllCoursesByInstrutorIdQuery(
    userData?.userInfo?._id
  );

  const [
    getEnrolledStudentByCourseId,
    { data: enrolledStudentData, isLoading },
  ] = useGetEnrolledStudentByCourseIdMutation();

  const handleClickCourse = async (course) => {
    setDropdownValue(`${course?.courseName}`);
    setShowDropdown(false);

    try {
      getEnrolledStudentByCourseId({
        body: {
          courseId: course?._id,
          educatorId: userData?.userInfo?.educatorId,
        },
      });
    } catch (error) {
      console.log("mutation Error", error);
    }
  };

  useEffect(() => {
    if (enrolledStudentData?.data && enrolledStudentData?.data.length > 0) {
      setShowTable(true);
    }
  }, [enrolledStudentData]);

  // console.log("enrolledStudentData", enrolledStudentData);

  const handleThreeDot = (index, tableData) => {
    // console.log("index", index);
    // console.log("tableData", tableData);
    if (showDetailsBox === index) {
      setShowDetailsBox(-1);
      return;
    }
    setShowDetailsBox(index);
  };

  return (
    <div className="flex flex-col  ">
      {isLoading && <CustomLoader />}
      <div className="relative">
        <p className=" mb-3">Select Course</p>
        <div
          className="border rounded-md px-5 py-2  flex justify-between items-center cursor-pointer text-gray-800"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <p className="  ">{dropdownValue} </p>

          <MdOutlineKeyboardArrowDown
            className={`text-2xl duration-300 ${
              showDropdown ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
        {showDropdown && (
          <ul className=" shadow-lg bg-white py-3 mx-1 absolute z-10 left-0 right-0 overflow-y-auto h-[15rem]  text-gray-800 ">
            {courseList?.courses?.map((course, index) => (
              <li
                key={index}
                className=" px-5 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  {
                    handleClickCourse(course);
                  }
                }}
              >
                {course?.courseName}
              </li>
            ))}
          </ul>
        )}
      </div>

      {enrolledStudentData?.data.length === 0 && (
        <div className="flex flex-col justify-center items-center  h-[50vh] w-full">
          <img
            src={require("../../assets/no_data_found.png")}
            alt=""
            className="w-[15rem]"
          />
          <p className="font-semibold text-xl">No Data Found</p>
        </div>
      )}

      {enrolledStudentData?.data &&
        enrolledStudentData?.data.length > 0 &&
        !isLoading &&
        showTable && (
          <div className="flex flex-col ">
            <div className="flex flex-col mb-0 mt-8">
              <div className="w-full flex justify-between items-center font-bold shadow-md mb-5 px-3 py-3">
                <div className="text-start w-[20%]  ">AVATAR</div>
                <div className="text-start w-[20%]  ">STUDENT NAME</div>
                <div className="text-start w-[20%]  ">COURSE</div>
                <div className="text-start w-[20%]  ">PRICE</div>
                <div className="text-start w-[20%]  ">DATE</div>
              </div>
            </div>

            {enrolledStudentData?.data?.map((tableData, index) => (
              <div className="flex flex-col relative" key={index}>
                <div className="w-full flex justify-between items-center my-2  shadow px-3 py-5 ">
                  <div className="text-start w-[20%]   text-sm text-gray-500">
                    {tableData?.studentInfo?.profile?.avatar?.url ? (
                      <img
                        src={tableData?.studentInfo?.profile?.avatar?.url}
                        alt=""
                        className="rounded-full w-[3rem] h-[3rem]"
                      />
                    ) : (
                      <VscAccount className=" w-[3rem] h-[3rem] text-gray-400" />
                    )}
                  </div>
                  <div className="text-start w-[20%]   text-sm text-gray-500">
                    {tableData?.studentInfo?.profile?.firstName}{" "}
                    {tableData?.studentInfo?.profile?.lastName}
                  </div>
                  <div className="text-start w-[20%]   text-sm text-gray-500">
                    {tableData?.paymentDetail?.courseName}
                  </div>
                  <div className="text-start w-[20%]   text-sm text-gray-500">
                    ₹{" "}
                    {Number(
                      tableData?.paymentDetail?.amount / 100
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                  <div className="text-start w-[20%]   text-sm text-gray-500">
                    {Utils.formatDate(tableData?.dateOfEnrollment)}
                  </div>
                  <HiDotsHorizontal
                    className="absolute right-3 cursor-pointer "
                    onClick={() => {
                      // setShowDetailsBox(!showDetailsBox);
                      handleThreeDot(index, tableData);
                    }}
                  />
                </div>
                {showDetailsBox === index && (
                  <div className="  z-10 flex self-end flex-col w-[25rem] bg-gray-50 shadow-lg  p-5  right-0 rounded-lg">
                    <div className="flex justify-between my-1">
                      <p className="font-semibold text-[#313639]">Email</p>
                      <p className="text-gray-500">
                        {tableData?.studentInfo?.email}
                      </p>
                    </div>
                    <div className="flex justify-between my-1">
                      <p className="font-semibold text-[#313639]">Mobile No </p>
                      <p className="text-gray-500">
                        +91 {tableData?.studentInfo?.profile?.mobile}
                      </p>
                    </div>

                    <div className="flex justify-between my-1">
                      <p className="font-semibold text-[#313639]">
                        Payment Method{" "}
                      </p>
                      <p className="text-gray-500 uppercase">
                        {tableData?.paymentDetail?.gatewayPayload?.method}
                      </p>
                    </div>
                    <div className="flex justify-between my-1">
                      <p className="font-semibold text-[#313639]">
                        Expiry Date{" "}
                      </p>
                      <p className="text-gray-500">
                        {Utils.formatDate(tableData?.expiryDate)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
    </div>
  );
}

export default ByCourseEnrollment;
