import React from "react";

function CustomInput({ onChange, className, inputLable, name, value, type,placeholder,onClick,editable }) {
  return (
    <div className="relative z-0 w-full mb-6 mt-3 group  ">
      <input
        editable={editable}
        name={name}
        value={value}
        type={type || "text"}
        className={`block pt-3.5 pb-1 ps-2 px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000 bg-gray-50  placeholder:text-gray-400  ${className}`}
        placeholder={placeholder || ""}
        onChange={onChange}
        onClick={onClick}
      />
      {/* <label
        className={`peer-focus:font-medium absolute text-base text-gray-900duration-300 transform -translate-y-6 scale-75 top-5 focus:top-0 peer-focus:dark:top-5 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6  z-10 ps-1`}
      > */}
      <label
        className={`peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-900 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}
      >
        {inputLable}
      </label>
    </div>
  );
}

export default CustomInput;
