import { Peer } from "peerjs";
import { useEffect, useRef, useState, useContext } from "react";
import {
	BiMicrophone,
	BiMicrophoneOff,
	BiVideo,
	BiVideoOff,
} from "react-icons/bi";
import { ReactMediaRecorder } from "react-media-recorder-2";
import { BsRecord2 } from "react-icons/bs";
import { MdCallEnd } from "react-icons/md";
import { MdOutlinePresentToAll } from "react-icons/md";
import { AuthContext } from "../../../Providers/AuthProvider";

import { CircularProgressbar } from "react-circular-progressbar";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import success_animation from "../../../assets/success_animation.json";
import Lottie from "lottie-react";

export const LiveClass = () => {
	const remoteVideoStream = useRef(null);
	const myVideoStream = useRef(null);
	const peer = useRef(null);
	const callRef = useRef(null);
	const localStream = useRef(null);
	const { userData } = useContext(AuthContext);
	const [isCameraOn, setIsCameraOn] = useState(true);
	const [isMicrophoneOn, setIsMicrophoneOn] = useState(true);
	const [remoteStreams, setRemoteStreams] = useState(0);
	const [isRecordingStarted, setIsRecordingStarted] = useState(false);
	const [countDownTimer, setCountDownTimer] = useState(10);
	const [blobUrl, setBlobUrl] = useState(null);
	const [showUploadPopup, setShowUploadPopup] = useState(false);
	const [progress, setProgress] = useState(0);
	const navigate = useNavigate();

	useEffect(() => {
		if (isRecordingStarted) {
			setTimeout(() => {
				if (countDownTimer > 0) {
					setCountDownTimer(countDownTimer - 1);
				}
			}, 1000);
		} else {
			setCountDownTimer(10);
		}
	}, [isRecordingStarted, countDownTimer]);

	const divRef = useRef(null);

	useEffect(() => {
		peer.current = new Peer("edu-dexlabz");
		peer.current.on("open", (id) => {
			console.log("Peer ID", id);
		});
	}, []);

	const addVideoStream = (video, stream) => {
		video.srcObject = stream;
		video.controls = false;
		video.muted = true;
		video.addEventListener("loadedmetadata", () => {
			video.play();
		});
		video.className = "rounded-lg shadow-md w-[240px] ";
		const grid = document.getElementById("grid");
		grid.appendChild(video);
		setRemoteStreams(grid.childElementCount);
	};

	const handleUploadRecording = async (blobUrl) => {
		// const percentCompleted = Math.round((event.loaded * 100) / event.total);
		// setProgress(percentCompleted);

		const formData = new FormData();
		formData.append("educatorId", userData?.userInfo?.educatorId);
		formData.append("type", "live");
		formData.append("courseId", "LiveClassCourse");
		formData.append("sectionId", "LiveSection");
		formData.append("chapterId", "LiveChapter");
		formData.append("lectureName", "LectureName");
		formData.append("lectureDescription", "LeactureDescription");
		formData.append("lectureUid", `${Date.now()}.mp4`);

		let blob = await fetch(blobUrl).then((r) => r.blob());
		const file = new File([blob], `${Date.now()}.mp4`);

		formData.append("file", file);
		console.log("Form Data", file);

		const xhr = new XMLHttpRequest();
		xhr.open(
			"POST",
			"https://xper-api.phynlabz.com/service/chapter/addVideoToLecture",
			true
		);

		xhr.upload.onprogress = (event) => {
			const percentCompleted = Math.round((event.loaded * 100) / event.total);
			setProgress(percentCompleted);
		};

		xhr.onreadystatechange = () => {
			if (xhr.readyState === XMLHttpRequest.DONE) {
				if (xhr.status === 200) {
					try {
						const response = JSON.parse(xhr.responseText);
						enqueueSnackbar(response, {
							autoHideDuration: 2000,
							variant: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
						// setShowUploadBox(false);
					} catch (error) {
						console.error("Error parsing response:", error);
					}
				} else {
					console.error("Upload failed. Status:", xhr.status);
				}
			}
		};

		xhr.send(formData);
	};

	useEffect(() => {
		peer.current.on("call", (call) => {
			console.log("New Incomming Call");
			callRef.current = call;
			call.answer(localStream.current);
			call.on("stream", (remoteStream) => {
				const video = document.createElement("video");
				addVideoStream(video, remoteStream);
				if (remoteVideoStream.current) {
					remoteVideoStream.current.srcObject = remoteStream;
					console.log("Remote Stream", remoteStream);
					remoteVideoStream.current.addEventListener("loadedmetadata", () => {
						remoteVideoStream.current.play().catch((error) => {
							console.error("Error playing remote streamxx:", error);
						});
					});
				}
			});
		});

		navigator?.mediaDevices
			?.getUserMedia({ video: isCameraOn, audio: isMicrophoneOn })
			.then((stream) => {
				localStream.current = stream;
				if (myVideoStream.current) {
					myVideoStream.current.srcObject = stream;
					myVideoStream.current.muted = true;

					myVideoStream.current.addEventListener("loadedmetadata", () => {
						myVideoStream.current.play().catch((error) => {
							console.error("Error playing local streamx:", error);
						});
					});
				}
			})
			.catch((err) => {
				console.error("Error accessing media devices:", err);
			});

		return () => {
			if (peer.current) {
				peer.current.destroy();
			}
			if (localStream.current) {
				localStream.current?.getTracks()?.forEach((track) => track.stop());
			}
		};
	}, []);

	const handleCameraToggle = async () => {
		try {
			if (isCameraOn) {
				setIsCameraOn(false);
				if (localStream.current) {
					const tracks = localStream.current.getTracks();
					console.log("Tracks", tracks);
					tracks.forEach((track) => {
						if (track?.kind === "video") {
							track.enabled = false;
							track.stop();
						}
					}); // Disable tracks instead of stopping them
				}
			} else {
				setIsCameraOn(true);
				if (localStream.current) {
					const tracks = localStream.current.getTracks();
					console.log("Tracks", tracks);
					tracks.forEach((track) => {
						if (track?.kind === "video") {
							track.enabled = true;
						}
					}); // Disable tracks instead of stopping them
				}

				const newStream = await navigator.mediaDevices.getUserMedia({
					video: true,
					audio: isMicrophoneOn,
				});
				localStream.current = newStream;

				callRef.current?.peerConnection?.getSenders().forEach((sender) => {
					if (sender?.track?.kind === "video") {
						sender.replaceTrack(newStream.getVideoTracks()[0]);
					}
					if (sender?.track?.kind === "audio") {
						sender.replaceTrack(newStream.getAudioTracks()[0]);
					}
				});
				myVideoStream.current.srcObject = localStream.current;
				myVideoStream?.current?.addEventListener("loadedmetadata", () => {
					myVideoStream.current.play().catch((error) => {
						console.error("Error playing local stream:", error);
					});
				});
			}
		} catch (error) {
			console.error("Error toggling camera:", error);
		}
	};

	const handleMicrophoneToggle = () => {
		if (isMicrophoneOn) {
			setIsMicrophoneOn(false);
			if (localStream.current) {
				const tracks = localStream.current.getTracks();
				console.log("Tracks", tracks);
				tracks.forEach((track) => {
					if (track.kind === "audio") {
						track.enabled = false;
						//.stop();
					}
				}); // Disable tracks instead of stopping them
			}
		} else {
			setIsMicrophoneOn(true);
			if (localStream.current) {
				const tracks = localStream.current.getTracks();
				console.log("Tracks", tracks);
				tracks.forEach((track) => {
					if (track.kind === "audio") {
						track.enabled = true;
						// track.stop();
					}
				}); // Disable tracks instead of stopping them
			}
		}
	};

	function downloadFromBlobUrl(blobUrl, filename = `${Date.now()}.mp4`) {
		const a = document.createElement("a");
		a.href = blobUrl;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	return (
		<div className=" overflow-hidden">
			<ReactMediaRecorder
				video
				audio
				screen
				downloadRecordingType="mp4"
				emailToSupport="support@dexlabz.com"
				render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
					<div
						className="flex relative w-full  h-[80vh]   "
						id="recordThisDiv"
						ref={divRef}
					>
						{isCameraOn ? (
							<div className="w-full flex relative  ">
								<video
									ref={myVideoStream}
									className={`${
										remoteStreams > 0 ? "h-[82%]" : "h-full"
									}  border rounded-2xl shadow-lg `}
									autoPlay
									muted
								/>
								<div className="absolute w-full flex self-start items-start left-10">
									<img
										className="  text-start  mt-5 px-2 w-[100px] opacity-40"
										src="/android-chrome-512x512.png"
										alt="logo"
									/>
								</div>
								{isRecordingStarted && countDownTimer !== 0 && (
									<p className="absolute top-[48%]  left-[48%] h-[100px] w-[100px] text-center align-middle self-center items-center  rounded-full bg-neutral-100  p-8 text-3xl font-bold text-gray-700 font-outfit">
										{countDownTimer}
									</p>
								)}
							</div>
						) : (
							<div className=" flex justify-center items-center w-full h-full">
								<div className="h-[250px] w-[250px] rounded-full bg-red-100  flex self-center justify-center items-center">
									<p className="self-center items-center flex align-middle text-center capitalize text-sm">
										{userData?.userInfo?.profile?.firstName + " "}
										{userData?.userInfo?.profile?.lastName}
									</p>
								</div>
							</div>
						)}

						<div className="absolute bottom-0 w-full flex justify-center items-center ">
							<div className="m-10 flex gap-4">
								<button
									className=" flex justify-center items-center rounded-full  bg-gray-500 opacity-90 h-[50px] w-[50px] shadow-orange-100"
									onClick={handleMicrophoneToggle}
								>
									{isMicrophoneOn ? (
										<BiMicrophone className="text-xl text-white" />
									) : (
										<BiMicrophoneOff className="text-xl text-white" />
									)}
								</button>

								<button
									className=" flex justify-center items-center rounded-full  bg-gray-500  opacity-90 h-[50px] w-[50px] shadow-orange-100"
									onClick={handleCameraToggle}
								>
									{isCameraOn ? (
										<BiVideo className="text-xl text-white" />
									) : (
										<BiVideoOff className="text-xl text-white" />
									)}
								</button>
								<button className=" flex justify-center items-center rounded-full  bg-gray-500 opacity-90 h-[50px] w-[50px] shadow-orange-100">
									<MdOutlinePresentToAll className="text-xl text-white" />
								</button>

								{!isRecordingStarted && (
									<button className=" flex justify-center items-center rounded-full  bg-gray-500 opacity-90 h-[50px] w-[50px] shadow-orange-100">
										<BsRecord2
											className="text-3xl text-white"
											onClick={() => {
												if (!isRecordingStarted) {
													startRecording();
													setIsRecordingStarted(true);
												}
											}}
										/>
									</button>
								)}
								<button
									className=" flex justify-center items-center rounded-full  bg-red-500  h-[50px] w-[80px] shadow-orange-100"
									onClick={() => {
										if (isRecordingStarted) {
											setIsRecordingStarted(false);
											stopRecording();
										}
										if (mediaBlobUrl) {
											// downloadFromBlobUrl(mediaBlobUrl);
											// setBlobUrl(mediaBlobUrl);
											handleUploadRecording(mediaBlobUrl);
											setShowUploadPopup(true);
										}
									}}
								>
									<MdCallEnd className="text-xl text-white" />
								</button>
							</div>
						</div>

						<div
							className="absolute flex flex-col gap-10 right-0 overflow-y-scroll h-full "
							id="grid"
						/>
					</div>
				)}
			/>

			{showUploadPopup && (
				<div className="absolute top-0 bottom-0 right-0 left-0 flex flex-col mx-auto my-auto w-[500px] h-[400px] bg-white rounded-2xl shadow-lg p-10">
					{progress >= 0 && progress < 100 && (
						<p className="text-lg">
							We are uploading your recording. Please wait...
						</p>
					)}
					{progress === 100 && (
						<p className="font-medium">
							Your recording has been uploaded successfully <br />
							<span className="text-xs font-thin">
								You can find your recording under the cloud storage section
							</span>
						</p>
					)}

					<div className="w-full flex items-center justify-center  h-[80%]">
						{progress > 0 && progress < 100 && (
							<div className="h-[100px] w-[100px]  ">
								<CircularProgressbar value={progress} text={`${progress}%`} />
							</div>
						)}
						{progress === 100 && (
							<Lottie
								animationData={success_animation}
								loop={false}
								className=" h-[60%] "
							/>
						)}
					</div>

					<div className="absolute bottom-4 flex gap-4 right-10">
						<button
							className={`text-xs px-4 p-2 rounded-lg  text-white ${
								progress !== 100
									? "pointer-events-none opacity-50 bg-gray-400"
									: " bg-green-600 cursor-pointer"
							}`}
							disabled={progress !== 100}
							onClick={() => navigate("/cloud-storage")}
						>
							View in Cloud Storage
						</button>
						<button
							className={`text-xs px-4 p-2 rounded-lg  text-white ${
								progress !== 100
									? "pointer-events-none opacity-50 bg-gray-400"
									: "  cursor-pointer bg-blue-600"
							}`}
							onClick={() => setShowUploadPopup(!showUploadPopup)}
							disabled={progress !== 100}
						>
							Close
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default LiveClass;
