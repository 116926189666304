import React, { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function SelectLanguage({ setLanguageList, previousLanguageData }) {
  const menuRef = useRef();
  const [dropdownValue, setDropdownValue] = useState("Language");
  const [showDropdown, setShowDropdown] = useState(false);
  const [languageCode, setLanguageCode] = useState("");
  const [languageIcon, setLanguageIcon] = useState("");

  const language = [
    { languageName: "Hinglish", languageCode: "HE", languageIcon: "ABC" },
    { languageName: "English", languageCode: "EN", languageIcon: "XYZ" },
    { languageName: "Hindi", languageCode: "HI", languageIcon: "MNO" },
  ];

  const handleClickSelectLanguage = (language) => {
    setDropdownValue(language?.languageName);
    setLanguageCode(language?.languageCode);
    setLanguageIcon(language?.languageIcon);

    // console.log("language = ", language);
    // console.log("languageCode", languageCode);
    // console.log("languageIcon", languageIcon);

    setLanguageList(language);
  };

  // console.log(previousLanguageData);

  useEffect(() => {
    if (previousLanguageData?.[0]) {
      setDropdownValue(previousLanguageData?.[0]?.languageName);
      setLanguageCode(previousLanguageData?.[0]?.languageCode);
      setLanguageIcon(previousLanguageData?.[0]?.languageIcon);
      setLanguageList(previousLanguageData?.[0]);
    }
  }, [previousLanguageData]);

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e?.target)) {
        // console.log(menuRef.current);
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className="text-[#7A7D7E] relative text-sm " ref={menuRef}>
      <p className=" mb-1">Course Language</p>
      <div
        className="border rounded-md px-5 py-2  flex justify-between items-center cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <p className="  ">{dropdownValue}</p>
        <MdOutlineKeyboardArrowDown
          className={`text-2xl duration-300 ${
            showDropdown ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
      {showDropdown && (
        <ul className=" shadow-lg bg-white py-3 absolute left-0 right-0 mx-auto z-10 w-full overflow-y-auto max-h-[10rem] ">
          {language?.map((language, index) => (
            <li
              key={index}
              className=" px-5 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                {
                  handleClickSelectLanguage(language);
                  setShowDropdown(false);
                }
              }}
            >
              {language?.languageName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SelectLanguage;
