import React, { useState } from "react";
import AllEnrollment from "./AllEnrollment";
import ByCourseEnrollment from "./ByCourseEnrollment";
import TimeEnrollment from "./TimeEnrollment";

function Enrollment() {
	const [showStudentDetailBox, setShowStudentDetailBox] = useState("all");

	const handleShowStudentBox = (selectedValue) => {
		setShowStudentDetailBox(selectedValue);
	};

	return (
		<div className="p-5 ">
			<div className="">
				<div className=" mb-5 flex justify-between w-full ">
					<p className="text-[#313639] text-xl font-semibold">
						Enrollment List
					</p>
				</div>
				<div className=" w-full flex justify-start items-center my-3 rounded-md shadow-md p-5 mb-8 z-50">
					<div className="flex justify-start items-cente  w-full border rounded-lg">
						<div className=" border-r p-2 px-4">
							<p
								className={` cursor-pointer   text-center px-5 py-2 m-1  rounded-full text-sm ${
									showStudentDetailBox === "all"
										? "bg-blue-700 text-white"
										: "border text-[#313639]"
								}`}
								onClick={() => handleShowStudentBox("all")}
							>
								All Enrollments
							</p>
						</div>
						<div className=" border-r p-2 px-4">
							<p
								className={` cursor-pointer   text-center px-5 py-2 m-1  rounded-full text-sm ${
									showStudentDetailBox === "course"
										? "bg-blue-700 text-white"
										: "border text-[#313639]"
								}`}
								onClick={() => handleShowStudentBox("course")}
							>
								By Course
							</p>
						</div>
						<div className=" border-r p-2 px-4">
							<p
								className={` cursor-pointer   text-center px-5 py-2 m-1  rounded-full text-sm ${
									showStudentDetailBox === "time"
										? "bg-blue-700 text-white"
										: "border text-[#313639]"
								}`}
								onClick={() => handleShowStudentBox("time")}
							>
								Time
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="h-[63vh]  overflow-y-auto no-scrollbar">
				{showStudentDetailBox === "all" && <AllEnrollment />}
				{showStudentDetailBox === "course" && <ByCourseEnrollment />}
				{showStudentDetailBox === "time" && <TimeEnrollment />}
			</div>
		</div>
	);
}

export default Enrollment;
