import React, { useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { useEducatorBankDetailsMutation } from "../../../redux/ReduxToolkit";
import { enqueueSnackbar } from "notistack";

function StepThird({ setActiveStep, educatorId }) {
  const [aadhar, setAadhar] = useState(null);
  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNo, setAccountNo] = useState(null);
  const [ifsc, setIfsc] = useState("");
  const [showNextBtn, setShowNextBtn] = useState(false);

  const [educatorBankDetails] = useEducatorBankDetailsMutation();

  const handleSubmitBankDetails = async () => {
    if (aadhar === null || aadhar === undefined || aadhar.length === 0) {
      enqueueSnackbar("Aadhar No is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (bankName === null || bankName === undefined || bankName.length === 0) {
      enqueueSnackbar("Bank Name is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (
      accountHolderName === null ||
      accountHolderName === undefined ||
      accountHolderName.length === 0
    ) {
      enqueueSnackbar("Account Holder Name is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (
      accountNo === null ||
      accountNo === undefined ||
      accountNo.length === 0
    ) {
      enqueueSnackbar("Account No is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (ifsc === null || ifsc === undefined || ifsc.length === 0) {
      enqueueSnackbar("IFSC Code is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }

    try {
      const response = await educatorBankDetails({
        body: {
          educatorId: educatorId,
          // educatorId: "931e17b6-b37a-48ca-9888-673cad863563",
          "educationDetail.adhaarNumber": aadhar,
          "educationDetail.bankName": bankName,
          "educationDetail.accountHolderName": accountHolderName,
          "educationDetail.accountNumber": accountNo,
          "educationDetail.ifsc": ifsc,
        },
      });

      // console.log("section created", loading, response);

      if (response) {
        // console.log("back vali api ka responce = ", response);
        setShowNextBtn(true);
      } else {
        console.log("Oops! section is not created");
      }
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  return (
    <div>
      <div className=" flex flex-col justify-center  p-5">
        <div className="self-center mb-10">
          <p className="text-2xl font-medium text-[#313639]">Bank Details</p>
        </div>
        {/* ....................................... */}
        <div className="">
          {/* ....................................... */}

          <div className="relative z-0 w-full mb-6 mt-6 group  ">
            <input
              type="number"
              className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000"
              placeholder=""
              onChange={(e) => setAadhar(e.target.value)}
            />
            <label
              htmlFor="floating_email"
              className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Aadhar No
            </label>
          </div>

          <div className="relative z-0 w-full mb-6 mt-6 group  ">
            <input
              type="text"
              className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000"
              placeholder=""
              onChange={(e) => setBankName(e.target.value)}
            />
            <label
              htmlFor="floating_email"
              className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Bank Name
            </label>
          </div>

          <div className="relative z-0 w-full mb-6 mt-6 group  ">
            <input
              type="text"
              className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000"
              placeholder=""
              onChange={(e) => setAccountHolderName(e.target.value)}
            />
            <label
              htmlFor="floating_email"
              className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Account Holder Name
            </label>
          </div>

          <div className="relative z-0 w-full mb-6 mt-6 group  ">
            <input
              type="number"
              className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000"
              placeholder=""
              onChange={(e) => setAccountNo(e.target.value)}
            />
            <label
              htmlFor="floating_email"
              className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Account No
            </label>
          </div>

          <div className="relative z-0 w-full mb-6 mt-6 group  ">
            <input
              type="text"
              className="block py-2.5  px-0 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-[#313639] dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-gray-900 peer bg-red-5000"
              placeholder=""
              onChange={(e) => setIfsc(e.target.value)}
            />
            <label
              htmlFor="floating_email"
              className="peer-focus:font-medium absolute text-base text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-900 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              IFSC Code
            </label>
          </div>

          <div className="mt-6 flex  justify-between gap-x-6 relative">
            {!showNextBtn && (
              <CustomButton
                lable={`SAVE`}
                className={` bg-green-700 text-white border-none !mx-0 hover:bg-green-600 absolute left-0`}
                onClick={() => handleSubmitBankDetails()}
              />
            )}
            {showNextBtn && (
              <CustomButton
                lable={`NEXT`}
                className={` bg-blue-700 text-white border-none !mx-0 hover:bg-blue-600 absolute right-0`}
                onClick={() => setActiveStep(3)}
              />
            )}
          </div>

          {/* ....................................... */}
        </div>
        {/* ....................................... */}
      </div>
    </div>
  );
}

export default StepThird;
