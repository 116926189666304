import React, { useEffect, useRef, useState } from "react";

import { BsThreeDotsVertical } from "react-icons/bs";
import { LuMonitorCheck, LuMonitorX } from "react-icons/lu";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaBook } from "react-icons/fa6";
import Lottie from "lottie-react";
import BrandLogo from "../../../Pages/Shared/BrandLogo/BrandLogo";
import no_thumbnail_bg from "../../../assets/thumbnail_bg_ripalEffect.json";

function ChapterCard({
	title,
	description,
	onclick,
	setActiveStep,
	setShowEditMode,
	setShowChapterForm,
	setChapterFormData,
	thumbnails,
}) {
	const [showMenu, setShowMenu] = useState(false);
	const [publish, setPublish] = useState(false);
	let menuRef = useRef();

	useEffect(() => {
		let handler = (e) => {
			if (!menuRef?.current?.contains(e?.target)) {
				setShowMenu(false);
				// console.log(menuRef.current);
			}
		};
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	});

	const menuItems = [
		{
			link: "",
			title: "Edit",
			icon: <MdEdit />,
		},
		{
			link: "",
			title: publish ? "Unpublish" : "Publish",
			icon: publish ? <LuMonitorX /> : <LuMonitorCheck />,
		},

		{
			link: "",
			title: "Delete",
			className: "text-red-500",
			icon: <MdDelete />,
		},
	];

	const handleMenuItems = (index, e, items) => {
		e.stopPropagation();

		if (items?.title === "Edit") {
			setShowMenu(false);
			setShowEditMode(true);
			setShowChapterForm(true);
			setChapterFormData({
				chapterName: title,
				chapterDescription: description,
			});
			return;
		}
		if (index === 1) {
			// setShowMenu(false);
			setPublish(!publish);
			return;
		}

		if (items?.title === "Delete") {
			setShowMenu(false);
			return;
		}
	};

	return (
    <div
      className="w-full max-h-[10rem]  tablet:w-full flex justify-between  rounded-md  p-3 my-2  shadow-no-border-shadow-2 cursor-pointer "
      onClick={onclick}
      ref={menuRef}
    >
      <div className="w-[30%] flex justify-start h-full ">
        {thumbnails ? (
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj_0Xr9NGJgOcmUn3SKp_5iZg4vHWrOU5I_w&usqp=CAU"
            alt=""
          />
        ) : (
          <div className="bg-neutral-100 h-full w-full relative rounded-md">
            <Lottie animationData={no_thumbnail_bg} className=" h-full " />
            <BrandLogo
              className={`absolute top-0 bottom-0 my-auto left-0 right-0 mx-auto w-[3rem] h-[3rem] translate-y-0`}
            />
          </div>
        )}
      </div>
      <div className="w-full ms-5  pt-1 flex flex-col justify-start items-start relative">
        <p className=" text-lg mb-3 text-gray-600 capitalize line-clamp-1">
          {title}
        </p>
        <p className="text-sm pe-10 text-gray-400  h-[6rem] w-full overflow-y-auto no-scrollbar">
          {description}
        </p>
        <BsThreeDotsVertical
          className="absolute right-0  text-xl cursor-pointer z-10"
          onClick={(e) => {
            setShowMenu(!showMenu);
            e.stopPropagation();
          }}
        />
        {showMenu && (
          <ul
            className={`absolute self-end bg-white shadow-lg min-w-[10rem] max-w-[20rem]  rounded  right-5`}
          >
            {menuItems?.map((items, index) => (
              <li
                key={index}
                className={`hover:bg-gray-100 ps-3 px-5  py-3 flex items-center cursor-pointer  text-sm ${items?.className}`}
                onClick={(e) => handleMenuItems(index, e, items)}
              >
                <span className="me-4 text-lg text-gray-700 bg-gray-200 p-1.5 rounded-full">
                  {items?.icon}
                </span>{" "}
                {items?.title}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default ChapterCard;
