

export const Utils = {
	isCouponExpired: (expiryDate) =>
		Math.floor(Date.now() / 1000) > Date.parse(expiryDate) / 1000,

	formatDate: (dateString) => {
		const date = new Date(dateString);
		const year = date.getUTCFullYear();
		const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
		const day = date.getUTCDate().toString().padStart(2, "0");
		return `${day}/${month}/${year}`;
	},

	formatHtml: (data) => {
		const htmlString = data;
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, "text/html");
		const text = doc.body.textContent;
		return text;
	},



  
  
  
};
