import { useState } from "react";
import { MdEdit } from "react-icons/md";
function InputFieldWithBorder({
  label,
  onChange,
  inputClassName,
  labelClassName,
  value,
  placeholder,
  type,
  hasIcon = false,
  inputFieldDisable = false,
  onKeyUp
}) {
  const [inputDisable, setInputDisable] = useState(true);
  return hasIcon ? (
    <div className="my-1 w-full  flex items-end gap-2">
      <div className="w-full">
        <p className={`text-[#797b7c] text-sm mb-1 ${labelClassName}`}>
          {label}
        </p>
        <div className="input-group">
          {inputDisable ? (
            <input
              disabled
              type={type || "text"}
              className={`border rounded-md  w-full text-sm p-1.5   ${inputClassName} `}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onKeyUp={onKeyUp}
            />
          ) : (
            <input
              type={type || "text"}
              className={`border rounded-md  w-full text-sm p-1.5  hover:border-[#34AA2E] focus:outline-none focus:border-[#34AA2E] ${inputClassName} `}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onKeyUp={onKeyUp}
            />
          )}
        </div>
      </div>
      <div
        className={`  p-2 rounded-md cursor-pointer ${
          !inputDisable ? "bg-[#34AA2E] text-white" : "bg-gray-200"
        }`}
        onClick={() => setInputDisable(!inputDisable)}
      >
        <MdEdit />
      </div>
    </div>
  ) : (
    <div className="my-1 w-full">
      <p className={`text-[#797b7c] text-sm mb-1 ${labelClassName}`}>{label}</p>
      <input
        disabled={inputFieldDisable}
        type={type || "text"}
        className={`border rounded-md  w-full text-sm p-1.5   ${inputClassName} ${
          inputFieldDisable
            ? ""
            : "hover:border-[#34AA2E] focus:outline-none focus:border-[#34AA2E] "
        }`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
    </div>
  );
}

export default InputFieldWithBorder;
