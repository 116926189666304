import React, { useEffect, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html"; // Import the library for converting Draft.js content to HTML
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import QuestionEditer from "./QuestionEditer";
import AnswerEditer from "./AnswerEditer";
import OptionsEditer from "./OptionsEditer";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useCreateQuestionMutation } from "../../../redux/ReduxToolkit";

function CreateQuestionPopup({
  setShowCreateQuestionForm,
  bottomPopup,
  currentCourseId,
  currentSectionId,
  currentChapterId,
  currentLectureId,
  questionCardRefetch,
}) {
  const [options, setOptions] = useState([{ content: "", selected: false }]);
  const [answerData, setAnswerData] = useState("");
  const [questionData, setQuestionData] = useState("");

  const [createQuestion] = useCreateQuestionMutation();

  // useEffect(() => {
  //   console.log("course id", currentCourseId);
  //   console.log("section id", currentSectionId);
  //   console.log("lecture id", currentChapterId);
  //   console.log("lecture id", currentLectureId);
  // }, []);

  const addOption = () => {
    setOptions([...options, { content: "", selected: false }]);
  };

  const handleFormSave = async () => {
    // console.log("question Array", questionData);
    // console.log("Options Array", options);
    // console.log("answer Array", answerData);

    if (!questionData || !questionData.length === 0) {
      enqueueSnackbar("Question is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }

    if (!answerData || !answerData.length === 0) {
      enqueueSnackbar("Answer is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }

    let flag = false;
    options?.forEach((option) => {
      if (option?.selected) {
        flag = true;
        return;
      }
    });

    if (flag) {
      try {
        const response = await createQuestion({
          body: {
            chapterId: currentChapterId,
            sectionId: currentSectionId,
            courseId: currentCourseId,
            lectureId: currentLectureId,
            questionData: questionData,
            answerData: answerData,
            options: options,
          },
        });

        if (response.data) {
          // console.log("api data", response?.data);
          enqueueSnackbar("Question created successfully !", {
            autoHideDuration: 3000,
            variant: "success",
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
          setShowCreateQuestionForm(false);
          questionCardRefetch();
        } else {
          enqueueSnackbar(response?.error?.data?.msg, {
            autoHideDuration: 3000,
            variant: "error",
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
          return;
        }
      } catch (error) {
        console.error("Mutation failed:", error.message);
      }
    } else {
      enqueueSnackbar("At least one option must be correct !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
    }
  };

  return (
    <div className="w-full h-screen bg-[rgba(128,128,128,.8)] flex flex-col justify-center items-center fixed top-0 left-0 z-20">
      <div
        className={`bg-white  lg:w-[70%] tablet:w-[60%] h-[85vh]  p-10 relative rounded-md shadow-lg  ${bottomPopup}`}
      >
        <div className="h-[85vh] flex flex-col ">
          <div className="flex flex-col overflow-y-auto h-[70vh] no-scrollbar ">
            <div className="flex flex-col">
              <p className="font-medium text-[#313639]">
                Question Title & Description
              </p>
              <QuestionEditer setQuestionData={setQuestionData} />
            </div>

            <div className=" mt-3 ">
              {options.map((option, index) => (
                <div key={index}>
                  <p className=" font-medium text-[#313639]">
                    {`Option ${index + 1}`}
                  </p>
                  <OptionsEditer
                    options={options}
                    optionValue={option}
                    arrayIndex={index}
                    setOptions={setOptions}
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-col mt-2">
              <p className="font-medium text-[#313639]">Answer Description</p>
              <AnswerEditer setAnswerData={setAnswerData} />
            </div>
          </div>
          <div className="flex justify-end w-full mt-5 absolute bottom-5 right-0 px-10 bg-white">
            <CustomButton
              lable={`Cancel`}
              className={`bg-red-500 hover:bg-red-600 text-white  border-none   font-normal`}
              onClick={() => setShowCreateQuestionForm(false)}
            />

            <CustomButton
              lable={`Add Options`}
              className={`bg-orange-500 hover:bg-orange-600 text-white  border-none   font-normal`}
              onClick={() => addOption()}
            />
            <CustomButton
              lable={`Save`}
              className={`bg-green-500 hover:bg-green-600 text-white  border-none  !mx-0 font-normal`}
              onClick={() => handleFormSave()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateQuestionPopup;
