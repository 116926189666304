import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";

function CustomCheckbox({
	onChange,
	value,
	inputId,
	name,
    inputLable,
    checked
}) {
	// const [checked, setChecked] = useState(false);

	return (
		<div className="flex align-items-center">
			<Checkbox
				inputId={inputId}
				name={name}
				value={value}
				onChange={onChange}
				checked={checked}
				className="border rounded-md"
			/>
			<label className="ml-2 text-gray-500 text-sm">{inputLable}</label>
		</div>
	);
}

export default CustomCheckbox;
