import CustomButton from "../../Shared/CustomButton/CustomButton";
import zigzag1 from "../../../assets/zigzag1.webp";
import zigzag2 from "../../../assets/zigzag2.webp";

function ZigzagCard() {
  return (
    <div className="w-full">
      <div className="flex  justify-center bg-[#FFEEB4] text-[#bce4e9] w-full pt-10 ">
        <div className="flex flex-col lg:flex-row justify-center  lg:justify-between items-center w-[95%] lg:w-full tablet:w-[70%] py-3  ">
          <div className=" lg:w-[40%] lg:ms-20">
            <p className="text-[#024751] text-xl lg:text-4xl font-semibold mb-5">
              upmyprep and Microsoft Teams. A perfect match.
            </p>
            <p className="text-[#024751]  text-sm mb-9">
              Combine upmyprep's unique features with the power of Microsoft
              Teams. Experience the best of both worlds and succeed in your
              online assessments.
            </p>
            <div className="flex flex-col md:flex-row items-center">
              <CustomButton
                lable="Add upmyprep to Microsoft Teams "
                className="bg-[#024751] hover:bg-[#275e66] text-white border-none !text-lg  mb-5 w-full md:w-auto md:mb-0 !mx-0"
              />
              <CustomButton
                lable="Read more"
                className={`text-[#024751] border-[#024751] hover:bg-[#024751] hover:text-white hover:border-[#024751] !text-lg w-full md:w-auto`}
              />
            </div>
          </div>
          <div className="lg:w-[50%]  flex justify-end mt-10">
            <img
              src={zigzag1}
              alt=""
              className="rounded-full lg:rounded-l-full lg:rounded-e h-[15rem] lg:h-[30rem]"
            />
          </div>
        </div>
      </div>
      <div className="flex  justify-center bg-[#F4F7FA] text-[#bce4e9] w-full py-10 relative">
        <div className="flex flex-col lg:flex-row-reverse justify-center  lg:justify-between items-center w-[95%] lg:w-full tablet:w-[70%] py-3  ">
          <div className=" lg:w-[40%] lg:me-20">
            <p className="text-[#024751] text-xl lg:text-4xl font-semibold mb-5">
              Enterprise-grade security and data protection
            </p>
            <p className="text-[#024751] mb-9 text-sm">
              Your respondents' personal data and assessment results are
              perfectly safe with us. We never access that information, and you
              can easily modify or delete records at any time.
            </p>
            <div className="flex flex-col lg:flex-row items-center">
              <CustomButton
                lable="Learn more"
                className={`text-[#024751] border-[#024751] hover:bg-[#024751] hover:text-white hover:border-[#024751] !text-lg w-full md:w-auto !mx-0 `}
              />
            </div>
          </div>
          <div className="lg:w-[50%]  flex justify-end mt-10">
            <img
              src={zigzag2}
              alt=""
              className="rounded-full lg:rounded-l-full lg:rounded-s h-[13rem] lg:h-[30rem]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZigzagCard;
