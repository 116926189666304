/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useGetAllLectureByChapterIdQuery } from "../../../redux/ReduxToolkit";
import CustomLoader from "../../Loading/Loading";
import CourseCard from "../AddCourse/CourseCrad";
import AddLectureForm from "./AddLecture";
import LectureCard from "./LectureCard";

import no_data_image from "../../../assets/no_data(course_component).png";

const ManageLecture = ({
  setActiveStep,
  chapterId,
  currentSectionId,
  courseId,
  setCurrentLectureId,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [lectureAllData, setLectureAllData] = useState({
    lectureName: "",
    lectureDescription: "",
    lectureUid: "",
  });
  const {
    data,
    isLoading,
    refetch: refetchAllLectureByChapter,
  } = useGetAllLectureByChapterIdQuery(chapterId);

  const handleClickLectureCard = (lecture) => {
    setLectureAllData({
      lectureName: lecture?.lectureName,
      lectureDescription: lecture?.lectureDescription,
      lectureUid: lecture?.lectureUid,
    });
    setCurrentLectureId(lecture?.lectureUid);
  };

  const handleUploadVideo = () => {
    // console.log("Upload Video");
  };

  // console.log("lectuer me course ki id = ",courseId)
  // console.log("lectuer me section ki id = ",currentSectionId)
  // console.log("lectuer me chapter ki id = ",chapterId)
  // console.log("lectuer me current lecture ka data  = ", lectureAllData);

  return (
    <div className="flex    w-[90%]  h-full ">
      {isLoading && <CustomLoader />}
      {showForm && (
        <AddLectureForm
          chapterId={chapterId}
          refetchAllLectureByChapter={refetchAllLectureByChapter}
          showAddLecturePopup={setShowForm}
          lectureAllData={lectureAllData}
          setLectureAllData={setLectureAllData}
        />
      )}

      {data?.chapters?.lectures?.length > 0 ? (
        <div className=" h-[78vh] w-full ">
          <div className="flex flex-wrap h-auto items-start justify-start   w-full overflow-y-auto no-scrollbar pt-10 pb-96">
            {data?.chapters?.lectures?.map((lecture, index) => (
              <LectureCard
                key={index}
                title={lecture.lectureName}
                description={lecture.lectureDescription}
                setActiveStep={setActiveStep}
                onClick={() => handleClickLectureCard(lecture)}
                courseId={courseId}
                sectionId={currentSectionId}
                chapterId={chapterId}
                lectureAllData={lectureAllData}
                setShowForm={setShowForm}
              />
            ))}
          </div>
        </div>
      ) : (
        !isLoading && (
          <div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
            <div className=" flex justify-center ">
              <img src={no_data_image} alt="" className="w-[30%]" />
            </div>
            <p className="text-lg text-gray-700   ">
              You have not created any lecture yet
            </p>
          </div>
        )
      )}
      {/* <button
        disabled
        onClick={() => setActiveStep(4)}
        className="absolute bottom-10 flex items-center justify-center right-10 rounded-full bg-orange-500 text-sm text-center font-normal p-5 w-[150px] h-[20px] text-white shadow-red-500 shadow-md "
      >
        Next
      </button> */}

      <button
        onClick={() => setShowForm(true)}
        className="absolute bottom-5 right-5 flex items-center justify-center  rounded-full bg-orange-500 text-sm text-center font-normal p-5 w-[150px] h-[20px] text-white shadow-red-500 shadow-md"
      >
        Add Lectures
      </button>
    </div>
  );
};

export default ManageLecture;
 