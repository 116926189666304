import React from "react";
import { TbArrowNarrowLeft } from "react-icons/tb";
import CloudStorageCard from "../CloudStorageCard/CloudStorageCard";
import { useGetAllSectionsQuery } from "../../../../redux/ReduxToolkit";
import CustomLoader from "../../../Loading/Loading";

import no_data_image from "../../../../assets/cloud_no_data.png";


function CloudSection({
  setShowSection,

  setShowChapter,
  currentCourseId,
  setCurrentSectionId,
}) {
  const {
    data: allSection,
    refetch: sectionRefetch,
    isLoading,
  } = useGetAllSectionsQuery(currentCourseId);

  const handleSectionFolderClick = (section, index) => {
    setCurrentSectionId(section?.sectionUid);
    setShowChapter(true);
  };

  return (
    <div className="flex ">
      <TbArrowNarrowLeft
        className={`border text-3xl rounded-lg border-gray-300 px-1 cursor-pointer absolute top-3 -translate-x-7`}
        onClick={() => setShowSection(false)}
      />

      {isLoading && (
        <div className=" flex justify-center items-center w-full h-[85vh]">
          <CustomLoader />
        </div>
      )}

      {!isLoading &&
        (allSection?.sections?.length > 0 ? (
          <div className="mt-5 flex flex-row flex-wrap">
            {allSection?.sections?.map((section, index) => (
              <CloudStorageCard
                key={index}
                title={section?.sectionName}
                onDoubleClick={() => handleSectionFolderClick(section, index)}
              />
            ))}
          </div>
        ) : (
          <div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
            <div className=" flex justify-center ">
              <img src={no_data_image} alt="" className="w-[20%]" />
            </div>
            <p className="text-xl text-gray-700  ">
              You have not created any Section yet
            </p>
          </div>
        ))}
    </div>
  );
}

export default CloudSection;
