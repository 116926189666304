import React from "react";
import PaymentCards from "./PaymentCards";
import PayoutTable from "./PayoutTable";

function PayoutServices() {
  return (
    <div className="flex flex-col gap-5 justify-start h-full">
      <p className="text-xl font-bold text-gray-700">Payouts</p>

      <div className=" ">
        <PaymentCards />
      </div>
      <div className="h-full">
        <PayoutTable />
      </div>
    </div>
  );
}

export default PayoutServices;
