import React, { useEffect, useState } from "react";
import CustomInputFile from "../../../Pages/Shared/CustomInputFile/CustomInputFile";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { useCreateChapterMutation } from "../../../redux/ReduxToolkit";
import CustomLoader from "../../Loading/Loading";
import { enqueueSnackbar } from "notistack";

function CreateChapterPopup({
  setShowChapterForm,
  sectionId,
  refetch,
  setShowEditMode,
  chapterFormData,
  setChapterFormData,
}) {
  const [chapterName, setChapterName] = useState("");
  const [chapterDescription, setChapterDescription] = useState("");

  const [createChapter, { isLoading }] = useCreateChapterMutation();

  useEffect(() => {
    if (chapterFormData) {
      setChapterName(chapterFormData?.chapterName);
      setChapterDescription(chapterFormData?.chapterDescription);
    }
  }, []);

  const handleSaveForm = async () => {
    if (
      chapterName === null ||
      chapterName === undefined ||
      chapterName.length === 0
    ) {
      enqueueSnackbar("Chapter Title is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }
    if (
      chapterDescription === null ||
      chapterDescription === undefined ||
      chapterDescription.length === 0
    ) {
      enqueueSnackbar("Chapter Description is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      return;
    }

    const response = await createChapter({
      body: {
        sectionId: sectionId,
        chapterName: chapterName,
        chapterDescription: chapterDescription,
      },
    });
    // console.log("Create Chapter", response);
    if (response) {
      setShowChapterForm(false);
      refetch();
    }
  };

  const handleCancle = () => {
    setShowChapterForm(false);
    setShowEditMode(false);
    setChapterFormData({
      chapterName: "",
      chapterDescription: "", 
    });
  };

  return (
    <div className="w-full h-screen bg-[rgba(128,128,128,.8)] flex flex-col justify-center items-center fixed top-0 left-0 z-40">
      {isLoading && <CustomLoader />}
      <div className="bg-white  lg:w-[50rem] 6xl:w-[80%] tablet:w-[60%]  p-10 relative rounded-2xl shadow-lg">
        <div className="text-2xl font-medium text-[#313639] mb-20">
          {chapterName === "" ? "Create Chapter" : "Update Chapter"}
        </div>
        <div className="col-span-full">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Chapter Title
          </label>
          <div className="mt-2">
            <input
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
              onChange={(e) => setChapterName(e.target.value)}
              value={chapterName}
            />
          </div>
        </div>
        <div className="col-span-full mt-5">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Chapter Description
          </label>
          <div className="mt-2">
            <textarea
              rows="3"
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"
              onChange={(e) => setChapterDescription(e.target.value)}
              value={chapterDescription}
            ></textarea>
          </div>
        </div>
        <div className="flex justify-end flex-col items-end">
          <CustomButton
            lable={`Save`}
            className={`mt-3 mx-0 duration-200 bg-orange-500 text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
            onClick={() => handleSaveForm()}
          />
          {/* <CustomButton
            lable={`Next`}
            className={`mt-3 mx-0 duration-200 bg-gray-500 text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
            onClick={() => setActiveStep(2)}
          /> */}
          <CustomButton
            lable={`Cancel`}
            className={`mt-3 mx-0 duration-200 bg-red-500 text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
            onClick={() => {
              handleCancle();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateChapterPopup;
