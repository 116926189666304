import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import CustomInputFile from "../../../Pages/Shared/CustomInputFile/CustomInputFile";
import CreateQuestionPopup from "./CreateQuestionPopup";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import QuestionCard from "./QuestionCard";
import axios from "axios";
import { useGetQuestionsByLectureIdQuery } from "../../../redux/ReduxToolkit";
import CustomLoader from "../../Loading/Loading";
import no_data_image from "../../../assets/no_data(course_component).png";


function ManageQuestion({
	currentCourseId,
	currentSectionId,
	currentChapterId,
	currentLectureId,
}) {
	const [showCreateQuestionForm, setShowCreateQuestionForm] = useState(false);
  const [data, setData] = useState(null);

  const {
    data: getAllQuestions,
    isLoading,
    refetch: reFetchManageQuestion,
  } = useGetQuestionsByLectureIdQuery(currentLectureId);

  useEffect(() => {
    if (showCreateQuestionForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showCreateQuestionForm]);

  useEffect(() => {
    // async function fetchData() {
    // 	try {
    // 		const response = await axios.get("http://localhost:5000/questions");
    // 		setData(response.data);
    // 		console.log("data", response.data);
    // 	} catch (error) {
    // 		console.error("Error fetching data:", error);
    // 	}
    // }

    // fetchData();

    console.log("questions = ", getAllQuestions?.msg?.[0]?.questions);
  }, [getAllQuestions]);

  return (
    <div className="h-[100%] flex justify-start items-start w-[90%]">
      {isLoading && <CustomLoader />}
      <div className=" flex flex-col  w-full ">
        <div className=" bottom-5 right-5 absolute flex ">
          <CustomButton
            lable={`Create Question`}
            className={`bg-orange-500 hover:bg-orange-600 text-white  border-none   font-normal mt-3 w-[10rem]`}
            onClick={() => setShowCreateQuestionForm(true)}
          />
          {/* <CustomButton
						lable={`Final Submit`}
						className={`bg-green-500 hover:bg-green-600 text-white  border-none   font-normal mt-3 w-[10rem]`}
						// onClick={() => setActiveStep(3)}
					/> */}
        </div>
        {getAllQuestions?.msg?.[0]?.questions &&
        getAllQuestions?.msg?.[0]?.questions.length > 0 ? (
          <div className=" flex  justify-between flex-wrap w-full   h-[68vh] overflow-y-auto no-scrollbar ">
            {getAllQuestions?.msg?.[0]?.questions?.map((questions, index) => (
              <QuestionCard
                itemKey={index}
                title={questions?.questionData}
                optionsArray={questions?.options}
                answerDescription={questions?.answerData}
                arrayIndex={index}
              />
            ))}
          </div>
        ) : (
          !isLoading && (
            <div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
              <div className=" flex justify-center ">
                <img src={no_data_image} alt="" className="w-[30%]" />
              </div>
              <p className="text-lg text-gray-700 ">
                You have not created any Question yet
              </p>
            </div>
          )
        )}

        {showCreateQuestionForm && (
          <CreateQuestionPopup
            setShowCreateQuestionForm={setShowCreateQuestionForm}
            bottomPopup={`animate-bottomPopup`}
            currentChapterId={currentChapterId}
            currentSectionId={currentSectionId}
            currentCourseId={currentCourseId}
            currentLectureId={currentLectureId}
            questionCardRefetch={reFetchManageQuestion}
          />
        )}
      </div>
    </div>
  );
}

export default ManageQuestion;
