import React, { useContext } from "react";
import { AuthContext } from "../../Providers/AuthProvider";
import EnrollmentInfo from "./EnrollmentInfo";
import RevenueInfo from "./RevenueInfo";
import GradientLineChart from "./lineChart";
import GradientBarChart from "./barchart";
import PieChart from "./piechart";
import DashboardStudentRankingTable from "./DashboardStudentRankingTable";
import SolidColorBarChart from "./barchart";
import DashboardCards from "./DashboardCards";
import GraphBox from "./GraphBox";
import DashboardRatingGraphBox from "./DashboardRatingGraphBox";
import DashboardCourseTable from "./DashboardCourseTable";
import DashboardWorkshops from "./DashboardWorkshops";

function Dashboard() {
  const { userData } = useContext(AuthContext);
  return (
    <div className=" h-full overflow-y-auto no-scrollbar  ">
      <div className="mb-5 w-full flex justify-between">
        <div className="">
          <p className="text-lg font-bold text-gray-800">Overview</p>
          <p className=" text-sm text-gray-400">Here's your overview today!</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-xl">{userData?.userInfo?.educatorName}</p>
          <div className="">
            <img
              src={require("../../assets/awtar.png")}
              alt=""
              className="w-[3rem] border rounded-full p-1"
            />
          </div>
        </div>
      </div>

      <div className="w-full  flex gap-5 px-3 ">
        <div className="w-[70%] flex  gap-5 ">
          <DashboardCards />
          <GraphBox />
        </div>
        <div className="w-[30%] ">
          <DashboardRatingGraphBox />
        </div>
      </div>
      <div className="w-full mt-5 px-3  flex gap-5">
        <div className="w-[70%]">
          <DashboardCourseTable />
        </div>
        <div className="w-[30%]">
          <DashboardWorkshops />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
