import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
function PayoutTable() {
  const data = [
    {
      date: "Apr 3, 2021",
      status: "Pending",
      charges: 5000,
      refunds: 2000,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 0,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 2000,
      fees: 0,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Pending",
      charges: 5000,
      refunds: 2000,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 0,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 2000,
      fees: 0,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Pending",
      charges: 5000,
      refunds: 2000,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 0,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 2000,
      fees: 0,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Pending",
      charges: 5000,
      refunds: 2000,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 0,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 2000,
      fees: 0,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Pending",
      charges: 5000,
      refunds: 2000,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 0,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 2000,
      fees: 0,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Pending",
      charges: 5000,
      refunds: 2000,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 0,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 2000,
      fees: 0,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Pending",
      charges: 5000,
      refunds: 2000,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 0,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 2000,
      fees: 0,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Pending",
      charges: 5000,
      refunds: 2000,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 0,
      fees: 3000,
      total: 20000,
    },
    {
      date: "Apr 3, 2021",
      status: "Paid",
      charges: 5000,
      refunds: 2000,
      fees: 0,
      total: 20000,
    },
  ];
  return (
    <div className="flex flex-col justify-start gap-5  mt-5 h-full">
      <p className="text-xl font-bold text-gray-700">All Payouts</p>
      <div className="border w-full rounded-md flex items-center">
        <div className="flex items-center justify-between p-2 border-r w-[13rem] cursor-pointer">
          <p className=" text-sm">Filter payouts </p>
          <IoMdArrowDropdown />
        </div>
        <div className=" px-5 flex items-center w-full">
          <IoSearchOutline />
          <input
            type="text"
            placeholder="Search"
            className="p-2 w-full focus:outline-none text-sm"
          />  
        </div>
      </div>
      <div className=" h-[59vh] overflow-y-scroll  ">
        <table className="w-full h-auto text-sm bg-white rounded">
          <thead>
            <tr className="bg-green-700 text-white">
              <th className=" p-3 text-center">Date</th>
              <th className=" p-3 text-center">Status</th>
              <th className=" p-3 text-center">Charges</th>
              <th className=" p-3 text-center">Refunds</th>
              <th className=" p-3 text-center">Fees</th>
              <th className=" p-3 text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((items, index) => (
              <tr
                key={index}
                className={`shadow text-gray-500 cursor-pointer hover:bg-green-400/10  ${
                  index % 2 !== 0 ? "bg-green-300/20" : ""
                }`}
                style={{ fontFamily: "ArialHelvetica, sans-serif" }}
              >
                <td className=" p-3 text-center text-[#519ff3] font-medium">
                  {items?.date}
                </td>
                <td className={` p-3  flex justify-center `}>
                  <p
                    className={` font-bold p-1.5 px-3 text-xs rounded-lg  text-center ${
                      items?.status === "Paid"
                        ? "text-green-500 bg-green-500/20"
                        : "text-orange-400 bg-orange-400/20"
                    }`}
                  >
                    {items?.status}
                  </p>
                </td>
                <td className=" p-3  text-center  ">
                  <span className="me-1">₹</span>{" "}
                  {Number(`${items?.charges}`).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td
                  className={` p-3  text-center   ${
                    items?.refunds === 0 ? "text-red-500" : ""
                  }`}
                >
                  <span className="me-1">₹</span>{" "}
                  {Number(`${items?.refunds}`).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>{" "}
                <td
                  className={` p-3  text-center   ${
                    items?.fees === 0 ? "text-red-500" : ""
                  }`}
                >
                  <span className="me-1">₹</span>{" "}
                  {Number(`${items?.fees}`).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>{" "}
                <td className=" p-3  text-center font-semibold text-[#383939] ">
                  <span className="me-1">₹</span>{" "}
                  {Number(`${items?.total}`).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PayoutTable;
