import React, { useState } from "react";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { FiPlus } from "react-icons/fi";
import SectionCard from "./SectionCard";
import { useGetAllSectionsQuery } from "../../../redux/ReduxToolkit";
import CreateSectionForm from "./CreateSectionForm";
import CustomLoader from "../../Loading/Loading";

import no_data_image from "../../../assets/no_data(course_component).png";

function ManageSection({
  setActiveStep,
  courseId,
  setCurrentSectionId,
  setSectionData,
  setSectionIndex,
}) {
  const [showCreateSectionForm, setShowCreateSectionForm] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [sectionFormData, setSectionFormData] = useState({
    sectionName: "",
    sectionDescription: "",
  });

  const {
    data: getAllSectionData,
    refetch: sectionRefetch,
    isLoading,
  } = useGetAllSectionsQuery(courseId);

  const handleShowCreateSection = () => {
    setShowCreateSectionForm(!showCreateSectionForm);
  };

  const handleSectionCardClick = (sectionData, index) => {
    setCurrentSectionId(sectionData.sectionUid);
    setActiveStep(2);
  };

  return (
    <div className="h-full  flex justify-center w-full ">
      {isLoading && <CustomLoader />}
      <div className="w-[90%] tablet:w-full flex flex-col justify-center items-center ">
        {!showCreateSectionForm && (
          <div className="w-[90%] h-[75vh] overflow-y-auto no-scrollbar">
            {getAllSectionData?.sections?.length > 0 ? (
              <div className="flex justify-center flex-col items-center w-full  gap-1 px-5">
                {getAllSectionData?.sections?.map((sectionData, index) => (
                  <SectionCard
                    key={index}
                    thumbnails={sectionData.sectionThumbnail}
                    setSectionFormData={setSectionFormData}
                    title={sectionData?.sectionName}
                    description={sectionData?.sectionDescription}
                    setActiveStep={setActiveStep}
                    showEditMode={showEditMode}
                    setShowCreateSectionForm={setShowCreateSectionForm}
                    setShowEditMode={setShowEditMode}
                    onclick={() => {
                      handleSectionCardClick(sectionData, index);
                    }}
                  />
                ))}
              </div>
            ) : (
              !isLoading && (
                <div className=" w-full h-[70vh] flex flex-col justify-center items-center my-auto ">
                  <div className=" flex justify-center ">
                    <img src={no_data_image} alt="" className="w-[30%]" />
                  </div>
                  <p className="text-lg text-gray-700   ">
                    You have not created any section yet
                  </p>
                </div>
              )
            )}

            <div className="flex flex-col">
              <CustomButton
                lable={`Create Section `}
                hasIcon={true}
                className={`absolute bottom-5 right-5 !mx-0 bg-orange-500 hover:bg-orange-600 text-white  border-none   font-normal `}
                onClick={() => handleShowCreateSection()}
              >
                <FiPlus className="text-xl" />
              </CustomButton>
            </div>
          </div>
        )}

        {(showCreateSectionForm || showEditMode) && (
          <CreateSectionForm
            setActiveStep={setActiveStep}
            courseId={courseId}
            sectionRefetch={sectionRefetch}
            setShowCreateSectionForm={setShowCreateSectionForm}
            showEditMode={showEditMode}
            setShowEditMode={setShowEditMode}
            showCreateSectionForm={showCreateSectionForm}
            sectionFormData={sectionFormData}
          />
        )}
      </div>
    </div>
  );
}

export default ManageSection;
