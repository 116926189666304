import React, { useState } from "react";
import BrandLogo from "../../Shared/BrandLogo/BrandLogo";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import busniess from "../../../assets/building.svg";
import book from "../../../assets/book.svg";
import user from "../../../assets/product_user_icon.svg";
import calendar from "../../../assets/calendar.svg";
import rocket from "../../../assets/rocket.svg";
import paint_roller from "../../../assets/paint_roller.svg";
import bar_chart from "../../../assets/bar_chart.svg";
import message from "../../../assets/messages.svg";
import eye from "../../../assets/show.svg";
import shield_check from "../../../assets/shield_check.svg";
import badge from "../../../assets/badge.svg";

function Header({ showOnBoardingFrom, setShowOnBoardingFrom }) {
  const navigate = useNavigate();
  const [showItemsBox, setShowItemsBox] = useState("");

  const handleMouseEnter = (value) => {
    console.log(value);
    setShowItemsBox(value);
  };

  const productMenu = [
    {
      icon: user,
      title: "Skills and Knowledge Assessment",
      description: "Make online tests,Quizzes and exams",
    },
    {
      icon: rocket,
      title: "Al Question Generator",
      description: "Be inspired by Al-generated questions",
    },
    {
      icon: bar_chart,
      title: "Insights & Analytics",
      description: "Use comprehensive, real-time inputs ",
    },
    {
      icon: message,
      title: "Feedback & Grades",
      description: "Experience automated feedback and grading",
    },
    {
      icon: eye,
      title: "Proctoring & Security",
      description: "Enjoy reliable results every single time",
    },
    {
      icon: eye,
      title: "Self-grading, Open-ended & Choice questions",
      description: "Use a variety of test questions",
    },
    {
      icon: calendar,
      title: "Reporting",
      description: "Impressive, detailed reporting options",
    },
    {
      icon: paint_roller,
      title: "Customization",
      description: "Adjust our platform to your needs",
    },
    {
      icon: shield_check,
      title: "Security",
      description: "Your safety is our priority",
    },
    {
      icon: badge,
      title: "Certificates",
      description: "Create your own assessment certificates",
    },
  ];

  return (
    // <div>
    //   <div className="flex flex-col md:justify-center items-center">
    //     <div className="flex   w-full ">
    //       <div className="flex justify-start ">
    //         <BrandLogo />
    //       </div>
    //       <p className="text-xl lg:text-3xl  text-blue-500 self-center  w-full text-center">
    //         Educator Onboarding
    //       </p>
    //     </div>
    //     <div className=" lg:w-[50%] flex flex-col justify-center items-center text-center">
    //       <p className="text-lg lg:text-2xl text-gray-700  my-5">
    //         Passionate about teaching? Kick-start your journey with DexLabz!
    //       </p>
    //       <p className="text-xs md:text-sm text-gray-500 w-[90%]">
    //         The DexLabz Educator Internship Programme helps upcoming Educators
    //         fast-track their teaching careers with the support of India’s
    //         Largest Learning Platform*
    //       </p>
    //     </div>

    //     <CustomButton
    //       lable={`Login`}
    //       className={`absolute right-5 top-5 bg-orange-500 hover:bg-orange-600 text-white  border-none   font-normal`}
    //       onClick={() => navigate("/login")}
    //     />
    //   </div>
    // </div>

    <div className="hidden lg:block">
      <div className="flex justify-center bg-[#024751] text-[#bce4e9] w-full">
        <div className="flex justify-between items-center w-[80%] py-3 relative">
          <div className="flex items-center">
            <div className=" rotate">
              <BrandLogo className={`tablet:!w-[4rem] lg:!w-[3rem]`} />
            </div>
            <div className="flex items-center  gap-10 ms-10">
              <p
                className="cursor-pointer hover:border-b"
                onMouseEnter={() => {
                  handleMouseEnter("product");
                }}
                onMouseLeave={() => {
                  handleMouseEnter("");
                }}
              >
                Product
                {showItemsBox === "product" && (
                  <div className="bg-white absolute top-14 mt-1 p-5 px-3 rounded-lg flex items-start gap-5">
                    <div className="flex flex-row flex-wrap items-start gap-4 w-[45rem]  ">
                      {productMenu?.map((card, index) => (
                        <div
                          className=" hover:bg-gray-100 pe-10 p-2 rounded-md w-[48%] flex items-center "
                          key={index}
                        >
                          <img
                            src={card?.icon}
                            alt=""
                            className="w-[2.5rem] bg-gray-100 rounded-md p-2"
                          />
                          <div className="ms-3  ">
                            <p className="text-gray-800  text-sm">
                              {card?.title}
                            </p>
                            <p className="text-xs text-gray-700/50">
                              {card?.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </p>
              <p
                className="cursor-pointer hover:border-b w-full"
                onMouseEnter={() => {
                  handleMouseEnter("who_its_for");
                }}
                onMouseLeave={() => {
                  handleMouseEnter("");
                }}
              >
                Who it's for
                {showItemsBox === "who_its_for" && (
                  <div className="bg-white absolute top-14 mt-1 p-5 rounded-lg flex items-start gap-5 w-[40rem]">
                    <div className="flex flex-col items-start gap-4 w-full ">
                      <div className="">
                        <img
                          src={busniess}
                          alt=""
                          className="h-[2.5rem] bg-green-100 p-1.5 rounded-lg"
                        />
                        <p className="text-[#03D37E]">Business</p>
                      </div>
                      <div className=" hover:bg-gray-100 pe-10 p-2 rounded-md  w-full ">
                        <p className="text-gray-800  text-sm">
                          Human Resources
                        </p>
                        <p className="text-xs text-gray-700/50">
                          See how we make a difference
                        </p>
                      </div>
                      <div className=" hover:bg-gray-100 pe-10 p-2 rounded-md  w-full ">
                        <p className="text-gray-800  text-sm">
                          Training Companies & Departments
                        </p>
                        <p className="text-xs text-gray-700/50">
                          Certification
                        </p>
                      </div>
                      <div className=" hover:bg-gray-100 pe-10 p-2 rounded-md  w-full ">
                        <p className="text-gray-800  text-sm">
                          Sales & Customer Service{" "}
                        </p>
                        <p className="text-xs text-gray-700/50">
                          See how we make a difference
                        </p>
                      </div>
                      <div className=" hover:bg-gray-100 pe-10 p-2 rounded-md  w-full ">
                        <p className="text-gray-800  text-sm">
                          Language Schools{" "}
                        </p>
                        <p className="text-xs text-gray-700/50">
                          See how we make a difference
                        </p>
                      </div>
                      <div className=" hover:bg-gray-100 pe-10 p-2 rounded-md  w-full ">
                        <p className="text-gray-800  text-sm">
                          Customer Stories
                        </p>
                        <p className="text-xs text-gray-700/50">
                          See how we make a difference
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-4 w-full ">
                      <div className="">
                        <img
                          src={book}
                          alt=""
                          className="h-[2.5rem] bg-purple-100 p-1.5 rounded-lg"
                        />
                        <p className="text-purple-300 mt-1">Education</p>
                      </div>
                      <div className=" hover:bg-gray-100 pe-10 p-2 rounded-md  w-full mb-3 ">
                        <p className="text-gray-800  text-sm">Teachers</p>
                        <p className="text-xs text-gray-700/50">
                          See how we make a difference
                        </p>
                      </div>
                      <div className=" hover:bg-gray-100 pe-10 p-2 rounded-md  w-full mb-3 ">
                        <p className="text-gray-800  text-sm">Schools</p>
                        <p className="text-xs text-gray-700/50">
                          offer a positive learning experience
                        </p>
                      </div>
                      <div className=" hover:bg-gray-100 pe-10 p-2 rounded-md  w-full mb-3 ">
                        <p className="text-gray-800  text-sm">
                          Universities & Colleges
                        </p>
                        <p className="text-xs text-gray-700/50">
                          Inspire learning and test knowledge
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </p>

              <p
                className="cursor-pointer hover:border-b"
                onMouseEnter={() => {
                  handleMouseEnter("pricing");
                }}
                onMouseLeave={() => {
                  handleMouseEnter("");
                }}
              >
                Pricing
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <CustomButton
              lable={`Login`}
              onClick={() => navigate("/login")}
              className={`text-white border-[#577461] hover:bg-[#faf3dc] hover:text-slate-800 hover:border-[#faf3dc] !text-lg`}
            />
            <CustomButton
              lable="Sign up"
              onClick={() => setShowOnBoardingFrom(true)}
              className={`bg-[#FFEEB4] text-[#024751] hover:bg-[#faf3dc] !text-lg`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
