import React, { useState } from "react";
import "./AffiliateMarketing.css";
import AffilateLogin from "./AffilateLogin";
import AffilateRegistration from "./AffilateRegistration";

function AffiliateMarketingLogin() {
  const [showLoginBox, setShowLoginBox] = useState(true);
  return (
    <div className="flex items-center justify-center  w-full h-[100vh] bg-image ">
      <div className="flex items-center justify-center  w-full h-[100vh] bg-[#265255]/30  ">
        {showLoginBox ? (
          <AffilateLogin
            showLoginBox={showLoginBox}
            setShowLoginBox={setShowLoginBox}
            blurInLeft={`animate-blurInLeft`}
            blurOutRight={`animate-blurOutRight`}
          />
        ) : (
          <AffilateRegistration
            showLoginBox={showLoginBox}
            setShowLoginBox={setShowLoginBox}
            blurInLeft={`animate-blurInLeft`}
            blurOutRight={`animate-blurOutRight`}
          />
        )}
      </div>
    </div>
  );
}

export default AffiliateMarketingLogin;
