export const ApiConstant = {
  JSON_BASE_URL: "http://localhost:5000/",
  PROFILE_DATA: "profile-information",
  BANK_DETAILS: "bank-details",
  CLOUD_VIDEOS: "cloud-videos",

  BASE_URL:
    process.env.NODE_ENV === "production"
      ? "https://xper-api.phynlabz.com"
      : // : "http://localhost:3000",
        "https://xper-api.phynlabz.com",
  // BASE_URL:"http://192.168.29.67:3000",

  EDUCATOR_LOGIN: "/service/educatorService/loginEducator",
  EDUCATOR_PROFILE_REGISTRATION: "/service/educatorService/createEducator",
  EDUCATOR_QUALIFICATION: "/service/educatorService/updateEducationDetails",
  EDUCATOR_BANK_DETAILS: "/service/educatorService/updateBankDetails",
  EDUCATOR_KYC_DETAILS: "/service/educatorService/updateKycDetails",

  GET_ALL_GOAL: "service/goals/get-allgoals",
  GET_ALL_COURSE: "/service/course/getAllCourse",
  GET_ALL_COURSE_BY_GOAL_ID: "/service/course/getStoreData",
  GET_COURSE_BY_COURSE_ID: "service/course/getCourseById",
  GET_COURSE_BY_INSTRUCTOR_ID: "service/course/getCourseByInstructorId",
  GET_ALL_LECTURE_BY_CHAPTER_ID: "service/chapter/getLectureByChapterId",
  GET_ALL_GOAL_SUMMARY: "service/goals/getGoalSummary",
  CREATE_COURSE: "service/course/createCourse",
  CREATE_SECTION: "service/section/addSection",
  CREATE_CHAPTER: "service/chapter/addChapter",
  ADD_LECTURE_TO_COURSE: "service/chapter/addLecture",
  GET_ALL_SECTION: "service/section/course",
  GET_CHAPTER_BY_SECTION_ID: "service/chapter/getChapterBySectionId",
  UPLOAD_COURSE_THUMBNAIL: "/service/uploadService/uploadThumbnail",
  UPLOAD_LECTURE_VIDEO: "/service/chapter/addVideoToLecture",
  GET_LECTURE_BY_LECTURE_ID: "/service/resource/getAllResourcesByLectureId",

  // questions
  CREATE_QUESTION: "/service/question/createQuestion",
  GET_QUESTIONS_BY_LECTURE_ID: "/service/question/getQuestionsByLectureId",
  // questions

  // enrollment
  GET_ALL_ENROLLED_STUDENT: "/service/sales/statistics/getSalesByInstructorId",
  GET_ENROLLED_STUDENT_BY_COURSE_ID:
    "/service/sales/statistics/getAllEnrollments",
  GET_ENROLLED_STUDENT_BY_DATE:
    "/service/sales/statistics/getEnrollmentsInRange",
  // enrollment

  // live classes

  GET_UPLODED_LIVE_CLASSESS: "/service/liveClass/getUploadedLiveClass",

  // live classes

  // Notification
  SEND_NOTIFICATION_BY_COURSE_ID:
    "/service/notification/sendNotificationToEnrolledCourseId",
  // Notification

 
};

export const optimizeImage = (
  originalUrl,
  width = 150,
  height = 100,
  quality = 60
) => {
  const baseUrl = process.env.REACT_APP_IMAGE_OPTIMIZATION_URL;
//   let optimizedUrl = baseUrl + ApiConstant.cloudFront + originalUrl;

  if (width) {
    originalUrl += `&width=${width}`;
  }
  if (height) {
    originalUrl += `&height=${height}`;
  }
  if (quality) {
    originalUrl += `&quality=${quality}`;
  }

  console.log("url",originalUrl)

  return originalUrl;
};
