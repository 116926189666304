import React, { useContext, useState } from "react";
import CloudStorageCard from "../CloudStorageCard/CloudStorageCard";
import { useGetAllCoursesByInstrutorIdQuery } from "../../../../redux/ReduxToolkit";
import { TbArrowNarrowLeft } from "react-icons/tb";
import CloudSection from "./CloudSection";
import CustomLoader from "../../../Loading/Loading";

import no_data_image from "../../../../assets/cloud_no_data.png";
import { AuthContext } from "../../../../Providers/AuthProvider";


function CloudCourse({ setShowCourse, setShowSection, setCurrentCourseId }) {
  const { userData } = useContext(AuthContext);
  console.log(userData?.userInfo?._id);

  const { data: allCourse, refetch: myCourseRefetch, isLoading } =
    // useGetAllCoursesByInstrutorIdQuery("60f7b0b9e6b3a40015a9d1a5"); 
    useGetAllCoursesByInstrutorIdQuery(userData?.userInfo?._id);

  console.log("cloud me course ka data = ", allCourse?.courses);

  const handleCourseFolderClick = (course, index) => {
    setCurrentCourseId(course?.courseUid);
    setShowSection(true);
    console.log("courseUid", course?.courseUid);
  };

  return (
    <div className="flex ">
      <TbArrowNarrowLeft
        className={`border text-3xl rounded-lg border-gray-300 px-1 cursor-pointer absolute top-3 -translate-x-7`}
        onClick={() => setShowCourse(false)}
      />

      {isLoading && (
        <div className=" flex justify-center items-center w-full h-[85vh]">
          <CustomLoader />
        </div>
      )}

      {!isLoading &&
        (allCourse?.courses?.length > 0 ? (
          <div className="mt-5 flex flex-row flex-wrap">
            {allCourse?.courses?.map((course, index) => (
              <CloudStorageCard
                key={index}
                title={course?.courseName}
                onDoubleClick={() => handleCourseFolderClick(course, index)}
              />
            ))}
          </div>
        ) : (
          <div className=" w-full h-[75vh] flex flex-col justify-center items-center my-auto">
            <div className=" flex justify-center ">
              <img src={no_data_image} alt="" className="w-[20%]" />
            </div>
            <p className="text-xl text-gray-700   ">
              You have not created any Course yet
            </p>
          </div>
        ))}
    </div>
  );
}

export default CloudCourse;
