import React, { useState } from 'react'
import folder_icon from "../../../../assets/folder_icon2.svg";

function CloudNotesFolder() {
  const [showFolderName, setShowFolderName] = useState(false);

  return (
    <div>
      <div className="flex flex-col items-center cursor-pointer w-[5rem]  mx-3 rounded-lg relative h-[7.5rem]">
        <div
          className=""
          onMouseEnter={() => setShowFolderName(true)}
          onMouseLeave={() => setShowFolderName(false)}
        >
          <img src={folder_icon} alt="" />
        </div>
        <div
          className={`px-2 w-full text-center text-xs  mb-2  absolute top-20 ${
            showFolderName ? "line-clamp-none bg-white" : "line-clamp-2 "
          }`}
        >
          Notes 
        </div>
      </div>
    </div>
  );
}

export default CloudNotesFolder