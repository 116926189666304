import React from "react";
import { FaUser } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";
import CustomButton from "../../Pages/Shared/CustomButton/CustomButton";

function AffilateRegistration({
  setShowLoginBox,
  blurInLeft,
  blurOutRight,
  showLoginBox,
}) {
  return (
    <div
      className={`flex flex-col w-[90%] lg:w-[30%] min-h-[50vh] bg-[#66CDBF]/60 p-5 
     `}
    >
      <div className="flex flex-col items-center gap-5">
        <p
          className="font-mono text-[#b4f0f5] text-xl  lg:text-2xl"
          style={{ fontWeight: "bold" }}
        >
          AFFILATE REGISTRATION
        </p>
        <div className=" lg:px-5 w-full flex flex-col gap-5  mt-5 lg:mt-10">
          <div className="flex items-center">
            <div className="bg-[#265255]  text-white p-3 lg:p-5">
              <FaUser />
            </div>
            <input
              type="text"
              placeholder="Username"
              className="text-xs p-3 w-full focus:outline-none bg-[#265255]/80 placeholder:text-white lg:p-5 text-white "
            />
          </div>
          <div className="flex items-center">
            <div className="bg-[#265255]  text-white p-3 lg:p-5">
              <IoIosLock />
            </div>
            <input
              type="text"
              placeholder="Password"
              className="text-xs p-3 w-full focus:outline-none bg-[#265255]/80 placeholder:text-white lg:p-5  text-white "
            />
          </div>
          <CustomButton
            lable={`REGISTER`}
            className={`!mx-0 w-full !p-3 rounded-none bg-white border-none lg:mt-5 font-semibold hover:bg-[#66CDBF] `}
          />

          <div className="h-[2px] mt-5 w-full bg-[#265255] shadow-sm shadow-white">
            {" "}
          </div>
          <CustomButton
            lable={`LOGIN`}
            className={`!mx-0 w-full !p-3 rounded-none hover:bg-white border-none lg:mt-5 font-semibold bg-[#66CDBF] text-gray-800`}
            onClick={() => setShowLoginBox(true)}
          />
        </div>
      </div>
    </div>
  );
}

export default AffilateRegistration;
