import React, { useEffect, useState } from "react";

import { Step, Stepper, step } from "react-form-stepper";
import StepFirst from "../StepFirst/StepFirst";
import StepThird from "../StepThird/StepThird";
import StepFourth from "../StepFourth/StepFourth";
import StepSecond from "../StepSecond/StepSecond";

import { AiFillCloseCircle } from "react-icons/ai";

import "./FormPopup.css";

function FormPopup({ setShowOnBoardingFrom, zoomInPopup }) {
  const [activeStep, setActiveStep] = useState(0);
  const [educatorId, setEducatorId] = useState(null);

  return (
    <div className="w-full h-[100vh]  flex justify-center items-center  fixed top-0 left-0  sing_up_bg_image">
      <div
        className={`bg-white  lg:w-[50rem] tablet:w-[60%]  p-10 relative rounded-md shadow-lg ${zoomInPopup}`}
      >
        <div className="text-3xl ms-auto ">
          <AiFillCloseCircle
            className="cursor-pointer absolute top-5 right-5 text-[#808080]"
            onClick={() => setShowOnBoardingFrom(false)}
          />
        </div>
        <Stepper
          activeStep={activeStep}
          connectorStateColors={true}
          className="w-full "
          styleConfig={{
            completedBgColor: "#33B249",
            activeBgColor: "#008AD8",
          }}
          connectorStyleConfig={{
            activeColor: "#008AD8",
            completedColor: "#33B249",
          }}
        >
          <Step onClick={() => setActiveStep(0)} label="Profile Information" />
          <Step
            onClick={() => setActiveStep(1)}
            label="Education Qualification"
          />
          <Step onClick={() => setActiveStep(2)} label="Bank Details" />
          <Step onClick={() => setActiveStep(3)} label="Upload Documents" />
        </Stepper>
        <div className=" h-[35rem] overflow-y-auto ">
          {activeStep === 0 && (
            <StepFirst
              setActiveStep={setActiveStep}
              setEducatorId={setEducatorId}
            />
          )}
          {activeStep === 1 && (
            <StepSecond setActiveStep={setActiveStep} educatorId={educatorId} />
          )}
          {activeStep === 2 && (
            <StepThird setActiveStep={setActiveStep} educatorId={educatorId} />
          )}
          {activeStep === 3 && (
            <StepFourth setActiveStep={setActiveStep} educatorId={educatorId} />
          )}
        </div>
      </div>
    </div>
  );
}

export default FormPopup;
