import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function UpdateSubGoalDropdown({
  currentSubGoalList,
  setCurrentSubGoalId,
  previousSubGoalId,
}) {
  const [dropdownValue, setDropdownValue] = useState("Choose subgoal");
  const [showDropdown, setShowDropdown] = useState(false);

  const findSubGoal = currentSubGoalList?.subgoal?.find(
    (subGoalData) => subGoalData?._id === previousSubGoalId
  );

  // console.log(findSubGoal);

  useEffect(() => {
    if (findSubGoal) {
      setDropdownValue(findSubGoal?.title);
    }
  }, [findSubGoal]);

  return (
    <div className="text-sm text-[#7A7D7E] relative">
      <p className=" mb-1 ">Your SubGoal</p>
      <div
        className="border rounded-md px-5 py-2  flex justify-between items-center cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <p className="  ">{dropdownValue}</p>
        <MdOutlineKeyboardArrowDown
          className={`text-2xl duration-300 ${
            showDropdown ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
      {showDropdown && (
        <ul className=" shadow-lg  py-3  bg-white absolute left-0 right-0 mx-auto z-10 w-full overflow-y-auto h-[10rem] ">
          {currentSubGoalList?.subgoal &&
          currentSubGoalList?.subgoal.length > 0 ? (
            currentSubGoalList?.subgoal?.map((subgoal, index) => (
              <li
                key={index}
                className=" px-5 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  {
                    setDropdownValue(`${subgoal?.title}`);
                    setShowDropdown(false);
                    setCurrentSubGoalId(subgoal?._id);
                  }
                }}
              >
                {subgoal?.title}
              </li>
            ))
          ) : (
            <div className="px-5 py-2 hover:bg-gray-100 flex items-center justify-center h-full">
              Please select Goal !
            </div>
          )}
        </ul>
      )}
    </div>
  );
}

export default UpdateSubGoalDropdown;
