import React from "react";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";

function CustomInputFile({ onChange, lable, onClick, filepath, previewLink }) {

  console.log(previewLink);
  return (
    <div className="w-full">
      {previewLink == undefined && previewLink == null ? (
        <div className="col-span-full mt-5 w-full">
          <label
            htmlFor="cover-photo"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {lable}
          </label>
          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 w-full">
            {!previewLink && (
              <div className="text-center">
                <MdOutlinePhotoSizeSelectActual
                  className="mx-auto h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md  font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={onChange}
                      onClick={onClick}

                      //   onChange={(e) => console.log("file path = ", e.target.value)}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                {filepath === "" ? (
                  <p className="text-xs leading-5 text-gray-600">filepath</p>
                ) : (
                  <p className="text-xs leading-5 text-gray-600">
                    PNG, JPG, GIF up to 10MB
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <p className="mb-5">Uploaded Thumbnail</p>
          <img
            src={previewLink}
            alt=""
            className="h-[100px] w-[200px] rounded  shadow-no-border-shadow hover:scale-125 duration-200 cursor-pointer"
          />
        </div>
      )}
    </div>
  );
}

export default CustomInputFile;
