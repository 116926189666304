import { Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../Providers/AuthProvider";
import AffilateServicesHomePage from "../Affilate-services/AffilateServicesHomePage";
import AffilateDashboard from "../AffiliateMarketingPages/AffilateDashboard/AffilateDashboard";
import AffiliateMarketingLogin from "../AffiliateMarketingPages/AffilateLoginRegistration/AffiliateMarketingLogin";
import AffilateCourseServices from "../AffiliateMarketingPages/AffilateCourseServices/AffilateCourseServices";

function AffiliateRouter() {
  const { accessToken } = useContext(AuthContext);

  return accessToken ? (
    <Routes>
      <Route path="/" Component={AffilateServicesHomePage}>
        <Route path="/" Component={AffilateDashboard} />
        <Route path="/course" Component={AffilateCourseServices} />
      </Route>
    </Routes>
  ) : (
    <Routes>
      <Route path="/" Component={AffiliateMarketingLogin} />
      {/* <Route path="/login" Component={AffiliateMarketing} /> */}
    </Routes>
  );
}

export default AffiliateRouter;
