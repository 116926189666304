import { BsThreeDotsVertical } from "react-icons/bs";
import { LuMonitorCheck, LuMonitorX } from "react-icons/lu";
import {
	MdDelete,
	MdEdit,
	MdOutlineAddComment,
	MdOutlineFileCopy,
	MdPlayCircle,
} from "react-icons/md";
import { PiTelevisionSimple } from "react-icons/pi";
import { useEffect, useRef, useState } from "react";
import UploadLecture from "./UploadLecture";
import PreviewLecture from "./PreviewLecture";
import UploadPdf from "./UploadPdf";

function LectureCard({
  title,
  description,
  onClick,
  thumbnails,
  setActiveStep,
  lectureAllData,
  chapterId,
  sectionId,
  courseId,
  setShowForm,
}) {
  const [showUploadPdfBox, setShowUploadPdfBox] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [publish, setPublish] = useState(false);
  const [showUploadBox, setShowUploadBox] = useState(false);
  const [showPreviewBox, setShowPreviewBox] = useState(false);
  let menuRef = useRef();

  // console.log(lectureAllData);

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e?.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const menuItems = [
    {
      link: "",
      title: "Edit",
      icon: <MdEdit />,
    },
    {
      link: "",
      title: publish ? "Unpublish" : "Publish",
      icon: publish ? <LuMonitorX /> : <LuMonitorCheck />,
    },

    {
      link: "",
      title: "Question Bank",
      icon: <MdOutlineAddComment />,
    },

    {
      link: "",
      title: "Upload Videos/Lecture",
      icon: <MdPlayCircle />,
    },
    {
      link: "",
      title: "Add Notes",
      icon: <MdOutlineFileCopy />,
    },
    {
      link: "",
      title: "Preview Lecture",
      icon: <PiTelevisionSimple />,
    },

    {
      link: "",
      title: "Delete",
      className: "",
      icon: <MdDelete />,
    },
  ];

  const handleMenuItems = (index, items, e) => {
    e.stopPropagation();

    if (items?.title === "Edit") {
      setShowMenu(false);
      setShowForm(true);
    }
    if (index === 1) {
      // setShowMenu(false);
      setPublish(!publish);
    }
    if (items?.title === "Question Bank") {
      setActiveStep(4);
    }
    if (items?.title === "Add Notes") {
      setShowUploadPdfBox(true);
      setShowMenu(false);
    }
    if (items?.title === "Upload Videos/Lecture") {
      setShowUploadBox(true);
      setShowMenu(false);
    }
    if (items?.title === "Preview Lecture") {
      setShowPreviewBox(true);
      setShowMenu(false);
    }

    if (items?.title === "Delete") {
      setShowMenu(false);
    }
  };

  return (
    <div
      className=" w-[20rem] min-h-[5rem] h-[5rem] mx-auto mb-5 self-start flex justify-between border-r-none border-y-none rounded-md my-2 cursor-pointer  border-l-green-600 border-l-[8px] ps-2 shadow-no-border-shadow"
      onClick={onClick}
      ref={menuRef}
    >
      <div className=" flex justify-start ">
        {thumbnails && (
          <img
            src={`https://axg6mefkv4.execute-api.ap-south-1.amazonaws.com/aws/serverless/optimize?url=https://d18mg0jr1igx53.cloudfront.net/${thumbnails?.[0]?.resourceId}?auto=compress&cs=tinysrgb&dpr=1&width=200&height=100&quality=10`}
            alt=""
            className="rounded-lg"
          />
        )}
      </div>
      <div className="  flex flex-col justify-start items-start relative w-full  p-1 pe-7 ">
        <p className="   text-[#313639] capitalize line-clamp-1 font-medium ">
          {title}
        </p>
        <p className="text-xs pe-10 text-gray-400 normal-case line-clamp-2">
          {description}
        </p>
        <BsThreeDotsVertical
          className="absolute right-2  text-xl cursor-pointer z-10"
          onClick={() => setShowMenu(!showMenu)}
        />
        {showMenu && (
          <div className=" z-30">
            <ul
              className={`absolute self-end bg-white shadow-lg min-w-[10rem] max-w-[20rem]  rounded  right-8 z-30 h-[15rem] overflow-y-scroll `}
            >
              {menuItems?.map((items, index) => (
                <li
                  key={index}
                  className={`hover:bg-gray-100 ps-3 px-5  py-3 flex items-center cursor-pointer  text-sm ${items?.className}`}
                  onClick={(e) => handleMenuItems(index, items, e)}
                >
                  <span className="me-4 text-lg text-gray-500 bg-gray-200 p-1.5 rounded-full">
                    {items?.icon}
                  </span>{" "}
                  {items?.title}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {showUploadPdfBox && (
        <UploadPdf
          zoomInPopup={`animate-zoomInPopup`}
          setShowUploadPdfBox={setShowUploadPdfBox}
          showUploadPdfBox={showUploadPdfBox}
          lectureAllData={lectureAllData}
          courseId={courseId}
          sectionId={sectionId}
          chapterId={chapterId}
        />
      )}
      {showUploadBox && (
        <UploadLecture
          zoomInPopup={`animate-zoomInPopup`}
          setShowUploadBox={setShowUploadBox}
          showUploadBox={showUploadBox}
          lectureAllData={lectureAllData}
          courseId={courseId}
          sectionId={sectionId}
          chapterId={chapterId}
        />
      )}
      {showPreviewBox && (
        <PreviewLecture
          rightPopup={`animate-rightPopup`}
          lectureAllData={lectureAllData}
          setShowPreviewBox={setShowPreviewBox}
        />
      )}
    </div>
  );
}

export default LectureCard;
