import React from "react";
import testSeries from "../../assets/testSeries-svg.svg";
import CustomButton from "../../Pages/Shared/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";

function TestSeriesServices() {
  const navigateExternalUrl = () => {
    window.location.href = "https://exam.upmyprep.com/";
  };
  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-5">
      <img src={testSeries} alt="" className="w-[15rem]" />

      <div className="">
        <CustomButton
          lable={`  Go To Test Series`}
          className={`bg-[#237C43]  text-white border-none hover:scale-95 duration-200  !p-3 !px-5`}
          onClick={() => navigateExternalUrl()}
        />
      </div>
    </div>
  );
}

export default TestSeriesServices;
