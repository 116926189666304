import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

function DashboardWorkshops() {
  const [showWorkshopBox, setShowWorkshopBox] = useState();
  const data = [
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      goal: "UPSC",
      date: "25 Dec 2021",
      time: "09:00 - 12:00",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      goal: "UPSC",
      date: "25 Dec 2021",
      time: "09:00 - 12:00",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      goal: "UPSC",
      date: "25 Dec 2021",
      time: "09:00 - 12:00",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      goal: "UPSC",
      date: "25 Dec 2021",
      time: "09:00 - 12:00",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      goal: "UPSC",
      date: "25 Dec 2021",
      time: "09:00 - 12:00",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/60f1a490a90ed8713c41c36c/1629223610791-LCBJG5451DRKX4WOB4SP/37-design-powers-url-structure.jpeg",
      courseName:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore ipsa vel et officiis deleniti, minima quod a iure quidem quia, voluptatem repellendus cumque. Mollitia, vitae amet voluptate aspernatur magnam voluptatibus.",
      charges: 5000,
      goal: "UPSC",
      date: "25 Dec 2021",
      time: "09:00 - 12:00",
    },
  ];

  const handleOpenBox = (index) => {
    if (index === index) {
      setShowWorkshopBox(index);
    }
    if (showWorkshopBox === index) {
      setShowWorkshopBox(-1);
    }
    
  };

  console.log(showWorkshopBox);
  return (
    <div className="p-5 rounded-xl shadow-no-border-shadow-2 h-full flex flex-col gap-3">
      <div className="flex justify-between items-center">
        <p className="">Upcoming Workshops</p>
        <p
          className={`text-green-500 bg-green-500/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5 cursor-pointer`}
        >
          View All
        </p>
      </div>
      <div className="flex flex-col gap-4  h-[17rem] overflow-y-scroll">
        {data?.map((items, index) => (
          <div
            className="bg-gray-50 rounded-lg cursor-pointer p-3 flex flex-col gap-3"
            onClick={() => handleOpenBox(index)}
          >
            <div className="flex items-center justify-between    pe-3">
              <div className="flex items-center gap-3  text-xs ">
                <p className="border-r border-gray-400   pe-3">
                  {items?.date}{" "}
                </p>
                <p className="">2 Workshops</p>
              </div>
              <IoIosArrowDown
                className={`duration-300 ${
                  showWorkshopBox === index ? "rotate-180" : ""
                }`}
              />
            </div>
            {showWorkshopBox === index && (
              <div className="flex items-center gap-3 text-xs ">
                <div className="">
                  <img src={items?.image} className="w-[15rem]" alt="" />
                </div>
                <div className="">
                  <p className="line-clamp-1 text-sm font-medium mb-2">
                    {items?.courseName}
                  </p>
                  <div className="w-full flex justify-between items-end">
                    <p className="font-medium text-red-500">{items?.goal}</p>
                    <p className="">{items?.time}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DashboardWorkshops;
