import React from "react";

import wallet from "../../../assets/wallet-svg.svg";
import pending from "../../../assets/pending-payout.svg";
import paid from "../../../assets/paid-svg.svg";

import { MdOutlineCurrencyRupee } from "react-icons/md";
function PaymentCards() {
  return (
    <div className="flex justify-between items-center gap-5   w-full me-2 h-[10rem]">
      <div className=" p-5 rounded-lg w-[49%]  min-h-[8rem] flex flex-col  relative  shadow-no-border-shadow-3">
        <div className="flex justify-between">
          <p className=" text-gray-500 text-sm">Previous Payout </p>
          <p className=" text-gray-500/50 text-xs">Apirl 02, 2024 </p>
        </div>
        <div className="flex gap-2 items-center text-3xl mt-5 ">
          <MdOutlineCurrencyRupee />
          <span
            className="text-[#313639]"
            style={{ fontFamily: "ArialHelvetica, sans-serif" }}
          >
            {Number(1976700).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
          <div className="text-green-500 bg-green-500/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-5">
            Paid
          </div>
        </div>
        <div className="text-sm text-[#519ff3] font-oswald mt-3 cursor-pointer">
          View transactions
        </div>
        <div className=" w-[6rem] absolute bottom-0 right-0 p-5 rounded-tl-3xl bg-green-500/20">
          <img src={paid} alt="" className=" " />
        </div>
      </div>
      <div className=" p-5 rounded-lg w-[49%]  min-h-[8rem] flex flex-col  relative  shadow-no-border-shadow-3">
        <div className="flex justify-between">
          <p className=" text-gray-500 text-sm">Next Payout </p>
          <p className=" text-gray-500/50 text-xs">Apirl 02, 2024 </p>
        </div>
        <div className="flex gap-2 items-center text-3xl mt-5">
          <MdOutlineCurrencyRupee />
          <span
            className="text-[#313639]"
            style={{ fontFamily: "ArialHelvetica, sans-serif" }}
          >
            {Number(1000).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
          <div className="text-orange-400 bg-orange-400/20 font-bold p-1.5 px-3 rounded-lg text-xs ms-10">
            Pending
          </div>
        </div>
        <div className="text-sm text-[#519ff3] font-oswald mt-3 cursor-pointer">
          View transactions
        </div>
        <div className=" w-[6rem] absolute bottom-0 right-0 p-5 rounded-tl-3xl bg-orange-400/20">
          <img src={pending} alt="" className=" " />
        </div>
      </div>
      <div className=" p-5 rounded-lg w-[49%]  min-h-[8rem] flex flex-col    shadow-no-border-shadow-3 relative">
        <div className="flex justify-between">
          <p className=" text-gray-500 text-sm">Balance </p>
          <p className=" text-gray-500/50 text-xs"> Est by Apirl 02, 2024 </p>
        </div>
        <div className="flex gap-2 items-center text-3xl mt-5">
          <MdOutlineCurrencyRupee />
          <span
            className="text-[#313639]"
            style={{ fontFamily: "ArialHelvetica, sans-serif" }}
          >
            {Number(1000).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </div>
        <div className="text-sm text-[#519ff3] font-oswald mt-3 cursor-pointer">
          View transactions
        </div>
        <div className=" w-[6rem] absolute bottom-0 right-0 p-5 rounded-tl-3xl bg-[#FE4900]/30">
          <img src={wallet} alt="" className=" " />
        </div>
      </div>
    </div>
  );
}

export default PaymentCards;
