import React from "react";
import { Route, Routes } from "react-router-dom";

function FallbackRouter() {
  return (
    <Routes>
      <Route path="*" Component={FallbackPage} />
    </Routes>
  );
}

function FallbackPage() {
  return (
    <div className="w-full h-screen flex justify-center items-center  bg-gray-800 text-white">
      <div className="flex flex-col items-center justify-center  ">
        <img
          src={require("../assets/moon-mission.png")}
          alt=""
          srcset=""
          className="w-[15rem] self-center"
        />
        <p className="text-3xl font-thin -translate-y-12  text-center">
          You landed to a page that does not exists
        </p>
      </div>
    </div>
  );
}

export default FallbackRouter;
