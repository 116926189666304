import React, { useState } from "react";

import folder_icon from "../../../../assets/folder_icon2.svg";
import mp4_icon from "../../../../assets/mp4.png";

function CloudStorageCard({ title, onDoubleClick, imageType }) {
	const [showFolderName, setShowFolderName] = useState(false);

	let iconType = folder_icon;

	if (imageType === "mp4") {
		iconType = mp4_icon;
	}

	return (
		<div onDoubleClick={onDoubleClick}>
			<div
				className="flex flex-col items-center cursor-pointer w-[5rem] mx-3 mb-5 rounded-lg relative h-[7.5rem] "
				onMouseEnter={() => setShowFolderName(true)}
				onMouseLeave={() => setShowFolderName(false)}
			>
				<div className="">
					<img src={iconType} alt="" className={``} />
				</div>
				<div
					className={`px-2 w-[60px]  break-words text-center   text-xs absolute top-20 z-10   ${
						showFolderName ? "line-clamp-none bg-white" : "line-clamp-2 "
					} ${imageType === "mp4" ? "top-24 -translate-y-2" : ""}`}
				>
					{title}
				</div>
			</div>
		</div>
	);
}

export default CloudStorageCard;
