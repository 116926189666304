import { Outlet, Route, Routes } from "react-router-dom";

export default function ContentAriea() {
  return (
    <div className="w-full   bg-white relative">
      <div className="w-full h-full p-5 ">
        <Outlet />
      </div>
    </div>
  );
}
