import React, { useState } from "react";
import Header from "./Header/Header";
import SignUpForm from "./SignUpForm/SignUpForm";
import Footer from "../Footer/Footer";
import Assessment from "./AssessmentContainer/Assessment";
import FeedBack from "./FeedBackContainer/FeedBack";
import ZigzagCard from "./ZigzagCardContainer/ZigzagCard";
import OurBlog from "./OurBlogContainer/OurBlog";
import Hero from "./HeroContainer/Hero";

const Home = () => {
  const [showOnBoardingFrom,setShowOnBoardingFrom] = useState(false)
  return (
    <div>
      <div className="flex justify-center  ">
        <div className="  ">
          <div className="">
            <Header
              className=""
              setShowOnBoardingFrom={setShowOnBoardingFrom}
              showOnBoardingFrom={showOnBoardingFrom}
            />
          </div>
          <div className="w-full">
            <Hero
              setShowOnBoardingFrom={setShowOnBoardingFrom}
              showOnBoardingFrom={showOnBoardingFrom}
            />
          </div>
          <div className="w-full">
            <Assessment />
          </div>
          <div className="w-full">
            <FeedBack />
          </div>
          <div className="w-full">
            <ZigzagCard />
          </div>
          <div className="w-full">
            <OurBlog />
          </div>
          <div className="">
            <Footer className="" />
          </div>
          {/* <div className="">
            <SignUpForm className="" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
