import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
function LineTextarea({
	onChange,
	className,
	value,
	name,
	inputLable,
	rows,
	id,
}) {
	return (
		<span className="p-float-label w-full px-0">
			<InputTextarea
				name={name}
				id={id}
				value={value}
				onChange={onChange}
				rows={rows || "2"}
				className={`w-full  px-2 py-2 ${className}`}
			/>
			<label htmlFor={inputLable} >{inputLable}</label>
		</span>
	);
}

export default LineTextarea;
