import React, { useContext, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { BiSupport } from "react-icons/bi";
import { TbAffiliateFilled } from "react-icons/tb";
// import images

import dashboard_image from "../../assets/ViewGrid.svg";
import batch_image from "../../assets/UsersOutline.svg";
import course_image from "../../assets/off.svg";
import notification_image from "../../assets/BellOutline.svg";

import { FaBook, FaMonero, FaRegBell, FaUsers } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { HiOutlineClipboardList } from "react-icons/hi";
import { MdDashboard, MdOutlineSdStorage } from "react-icons/md";

// import images

import { IoSettingsOutline } from "react-icons/io5";
import { RiAccountPinCircleLine } from "react-icons/ri";
import BrandLogo from "../../Pages/Shared/BrandLogo/BrandLogo";
import { AuthContext } from "../../Providers/AuthProvider";
import CustomButton from "../../Pages/Shared/CustomButton/CustomButton";

function AffilateMenuSidebar() {
  const { logout } = useContext(AuthContext);

  const location = useLocation();

  const dashboardMenu = [
    {
      title: "Dashboard",
      link: "/",
      img: dashboard_image,
      icon: <MdDashboard />,
    },

    // {
    // 	title: "Classes",
    // 	link: "/",
    // 	img: class_image,
    // },
    {
      title: "Courses",
      link: "/course",
      img: course_image,
      icon: <FaBook />,
    },

    {
      title: "Support",
      link: "/support",
      icon: <BiSupport />,
      img: batch_image,
    },
    {
      title: "Account",
      link: "/account",
      icon: <RiAccountPinCircleLine />,
      img: batch_image,
    },
    {
      title: "Payout",
      link: "/payout",
      icon: <FaMonero />,
      img: batch_image,
    },
    {
      title: "Affiliate",
      link: "#",
      icon: <TbAffiliateFilled />,
      img: batch_image,
    },
  ];

  return (
    <div className="w-[20rem] min-h-full tablet:w-[20%] bg-[#227c43] py-8 relative  overflow-y-auto no-scrollbar shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
      <div className="flex   items-center px-6 tablet:px-1">
        <BrandLogo className={`!w-[2rem]  tablet:-translate-y-0`} />
        <p className="text-white  text-xl tablet:pt-2">DexLabz</p>
      </div>

      <ul className="flex flex-col text-left text-white mt-10">
        {dashboardMenu.map((item, index) => (
          <li
            className={`flex w-full   mt-1  text-base ${
              location.pathname === item?.link
                ? "border-l-4 border-white"
                : " border-l-4 border-transparent"
            }`}
            key={index}
          >
            <Link
              to={item.link}
              className={`flex items-center  h-full w-full px-6 py-3 hover:cursor-pointer hover:bg-gradient-to-r hover:from-[#6bf06b] hover:to-[#1a8f0f] tablet:px-1 tablet:text-sm `}
            >
              {/* <img src={item.img} alt="" className="me-2" /> */}
              <span className="me-3 text-xl">{item?.icon}</span> {item.title}
            </Link>
          </li>
        ))}
      </ul>
      {/* <div className=" text-white mt-4">
        <p className="px-6 py-4 ">TEAMS</p>
        <ul className="">
          <li className={`flex w-full   mt-1  text-base bg-green-0 `}>
            <p
              className={`flex items-center  h-full w-full px-6 py-4 hover:cursor-pointer hover:bg-gradient-to-r hover:from-[#1C4185] hover:to-[#0f3c8f] tablet:px-1 tablet:text-sm`}
            >
              {" "}
              <img src={message_image} alt="" className="me-2" />
              Messages
            </p>
          </li>
          <li className={`flex w-full   mt-1  text-base bg-green-0 `}>
            <p
              className={`flex items-center  h-full w-full px-6 py-4 hover:cursor-pointer hover:bg-gradient-to-r hover:from-[#1C4185] hover:to-[#0f3c8f] tablet:px-1 tablet:text-sm`}
            >
              {" "}
              <img src={call_image} alt="" className="me-2" />
              Call Meetting
            </p>
          </li>
        </ul>
      </div> */}

      <div className="flex flex-col items-center bottom-10 w-full text-center absolute gap-10">
        <div className="flex justify-center mt-10 ">
          <CustomButton
            lable={`Logout`}
            className={`bg-white border-none !rounded-full py-3 !px-10 !font-normal !mx-0`}
            onClick={() => logout()}
          />
        </div>

        <Link
          to="https://www.phynlabz.com"
          className="text-white font-normal text-xs "
        >
          Powered by &copy;PhynLabz <br />
          <span className="text-gray-300 mt-2 text-xsm">
            Version 1.0.0 alpha
          </span>
        </Link>
      </div>
    </div>
  );
}

export default AffilateMenuSidebar;
