import { useState } from "react";
import { LuArrowRight } from "react-icons/lu";
import searchIcon from "../../../assets/searchIcon.svg";

function Assessment() {
  const [showBoxes, setShowBoxes] = useState("business");
  const [cardHoverStyle, setCardHoverStyle] = useState(-1);

  const business = [
    {
      icon: searchIcon,
      title: "Recruitment",
      description:
        "Identify real talents without the hassle of time-consuming CV screening",
    },
    {
      icon: searchIcon,
      title: "Employee Assessments",
      description:
        "Online workforce assessments encourage your employees to grow and cultivate a positive team spirit",
    },
    {
      icon: searchIcon,
      title: "Training",
      description:
        "Evaluate skills and knowledge in a professional and distraction-free environment",
    },
    {
      icon: searchIcon,
      title: "Sales Training",
      description:
        "Boost sales by giving your team the skills they need to interact with customers",
    },
    {
      icon: searchIcon,
      title: "Customer Service",
      description:
        "Use customized tests to ensure that employees are meeting customer service standards",
    },
    {
      icon: searchIcon,
      title: "Safety Procedures",
      description:
        "Build a better work environment while boosting morale and performance",
    },
    {
      icon: searchIcon,
      title: "Certification",
      description:
        "Streamline your certification processes with efficient and easy-to-use online assessment tools",
    },
  ];

  const education = [
    {
      icon: searchIcon,
      title: "Quizzes",
      description:
        "Engage your remote students or connected classrooms in a way that’s efficient and fun",
    },
    {
      icon: searchIcon,
      title: "Exam",
      description:
        "Test skills and knowledge in a professional and no-distraction environment",
    },
    {
      icon: searchIcon,
      title: "Homework",
      description: "Give friendly and interactive after school assignments",
    },
    {
      icon: searchIcon,
      title: "Competition",
      description:
        "Empower learners, challenge them and encourage discussions to experience amazing results",
    },
    {
      icon: searchIcon,
      title: "Formative Assessment",
      description:
        "Positive motivation, encouraging feedback, and equal opportunities with a new educational approach",
    },
  ];

  return (
    <div className="flex  justify-center bg-white  w-full lg:py-20 pt-10 ">
      <div className="flex flex-col  justify-center   items-center w-[95%] lg:w-[80%]  py-3 text-center ">
        <div className="flex flex-col items-center">
          <p className=" lg:text-lg mb-2 text-[#709197]">
            Skills and knowledge testing made easy
          </p>
          <p className="text-2xl lg:text-4xl text-green-950 ">
            One assessment platform for all your needs
          </p>
          <div className=" flex items-center justify-center my-10  p-1  rounded-md bg-[#F3F7FB] ">
            <p
              className={`px-10 py-2 cursor-pointer  ${
                showBoxes === "business"
                  ? "bg-white text-green-900"
                  : "text-[#709197]"
              }`}
              onClick={() => setShowBoxes("business")}
            >
              Business
            </p>
            <p
              className={`px-10 py-2 cursor-pointer ${
                showBoxes === "education"
                  ? "bg-white text-green-900"
                  : "text-[#709197]"
              }`}
              onClick={() => setShowBoxes("education")}
            >
              Education
            </p>
          </div>
        </div>
        <div className=" w-full flex flex-wrap  ">
          {showBoxes === "business"
            ? business.map((card, index) => (
                <div
                  className={`text-start border p-10 rounded-lg w-[25rem] tablet:w-[22rem] cursor-pointer me-auto mt-10 duration-700 ${
                    cardHoverStyle === index ? "shadow-2xl border-none " : ""
                  }`}
                  key={index}
                  onMouseEnter={() => setCardHoverStyle(index)}
                  onMouseLeave={() => setCardHoverStyle(-1)}
                >
                  <div className="">
                    <img src={card.icon} alt="" className="w-[20%] " />
                  </div>
                  <div className="">
                    <p className="text-xl font-medium   my-5">{card.title}</p>
                    <p className=" mb-10 text-gray-500 text-sm">{card.description}</p>
                    <p className="flex items-center  w-full">
                      Learn more
                      <LuArrowRight
                        className={`ms-3 text-2xl duration-300 ${
                          cardHoverStyle === index ? " ms-5" : ""
                        }`}
                      />
                    </p>
                  </div>
                </div>
              ))
            : education?.map((card, index) => (
                <div
                  className={`text-start border p-10 rounded-lg w-[25rem] tablet:w-[22rem]  cursor-pointer me-auto mt-10 duration-700 ${
                    cardHoverStyle === index ? "shadow-2xl border-none " : ""
                  }`}
                  key={index}
                  onMouseEnter={() => setCardHoverStyle(index)}
                  onMouseLeave={() => setCardHoverStyle(-1)}
                >
                  <div className="">
                    <img src={card?.icon} alt="" className="w-[20%] " />
                  </div>
                  <div className="">
                    <p className="text-xl font-medium  my-5">{card?.title}</p>
                    <p className=" mb-10 text-gray-500 text-sm">{card?.description}</p>
                    <p className="flex items-center ">
                      Learn more
                      <LuArrowRight
                        className={`ms-3 text-2xl duration-300 ${
                          cardHoverStyle === index ? " ms-5" : ""
                        }`}
                      />
                    </p>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default Assessment;
