import React from "react";
import aleart_svg from "../../assets/aleart_svg.svg";
import CustomButton from "../../Pages/Shared/CustomButton/CustomButton";
import Lottie from "lottie-react";
import deleteAnimation from "../../assets/item_delete_animation.json";

function DeletePopup({
  title,
  description,
  cancelOnClick,
  deleteOnClick,
  zoomInPopup,
}) {
  return (
    <div className="fixed top-0 left-0 bg-gray-800/50 flex justify-center items-center w-full h-screen z-50">
      <div
        className={`w-[30rem] min-h-[20rem] bg-white rounded-2xl p-5 flex flex-col items-center justify-between shadow-2xl ${zoomInPopup}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="">
          <div className="w-full  flex justify-center">
            <img src={aleart_svg} alt="" />
          </div>
          <div className="text-center text-gray-700 my-5 flex flex-col items-center gap-5">
            <p className="text-xl  font-semibold">{title} </p>
            <p className="px-5">{description} </p>{" "}
          </div>
        </div>
        {/* <div className="">
          <Lottie
            animationData={deleteAnimation}
            className=" h-[10rem] mb-10 -translate-y-5 "
          />
        </div> */}
        <div className="flex items-center w-full">
          <CustomButton
            lable={`Cancel`}
            className={`w-full hover:bg-red-500 hover:text-white hover:border-transparent border-red-500 text-red-500 bg-transparent !p-3 `}
            onClick={cancelOnClick}
          />
          <CustomButton
            lable={`Delete`}
            className={`w-full bg-red-500 text-white border-transparent hover:bg-red-600   !p-3 `}
            onClick={deleteOnClick}
          />
        </div>
      </div>
    </div>
  );
}

export default DeletePopup;
