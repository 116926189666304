import React, { useEffect, useState } from "react";
import CustomInput from "../../../Pages/Shared/CustomInput/CustomInput";
import CustomTextarea from "../../../Pages/Shared/CustomTextarea/CustomTextarea";
import CustomInputFile from "../../../Pages/Shared/CustomInputFile/CustomInputFile";
import CustomButton from "../../../Pages/Shared/CustomButton/CustomButton";
import { useCreateSectionMutation } from "../../../redux/ReduxToolkit";
import { enqueueSnackbar, useSnackbar } from "notistack";
import CustomLoader from "../../Loading/Loading";

function CreateSectionForm({
	setActiveStep,
	courseId,
	sectionRefetch,
	setShowCreateSectionForm,
	showCreateSectionForm,
	sectionAllData,
	showEditMode,
	sectionFormData,
	setShowEditMode,
}) {
	const [sectionTitle, setSectionTitle] = useState("");
	const [sectionDescription, setSectionDescription] = useState("");

	const [sectionContent, setSectionContent] = useState({ chapter: [] });

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [createSection, { isLoading }] = useCreateSectionMutation();

	console.log("create section form ke andar sectionAllData = ", sectionAllData);

	useEffect(() => {
		if (showEditMode) {
			setSectionTitle(sectionFormData?.sectionName);
			setSectionDescription(sectionFormData?.sectionDescription);
			// console.log("sectionContent", sectionFormData);
		}
	}, []);

	const handleFormSave = async () => {
		if (
			sectionTitle === null ||
			sectionTitle === undefined ||
			sectionTitle.length === 0
		) {
			enqueueSnackbar("Section Title is required !", {
				autoHideDuration: 3000,
				variant: "error",
				anchorOrigin: { horizontal: "center", vertical: "bottom" },
			});
			return;
		}
		if (
			sectionDescription === null ||
			sectionDescription === undefined ||
			sectionDescription.length === 0
		) {
			enqueueSnackbar("Section Description is required !", {
				autoHideDuration: 3000,
				variant: "error",
				anchorOrigin: { horizontal: "center", vertical: "bottom" },
			});
			return;
		}

		try {
			const response = await createSection({
				body: {
					sectionName: sectionTitle,
					sectionDescription: sectionDescription,
					courseId: courseId,
				},
			});

			// console.log("section created", loading, response);

			if (response) {
				sectionRefetch();
				setShowCreateSectionForm(false);
			} else {
				console.log("Oops! section is not created");
			}
		} catch (error) {
			console.error("Mutation failed:", error.message);
		}
	};

	return (
    <div className="w-full h-screen bg-[rgba(128,128,128,.8)] flex flex-col justify-center items-center fixed top-0 left-0 z-40">
      {isLoading && <CustomLoader />}
      <div className="bg-white  lg:w-[50rem] 6xl:w-[40%] tablet:w-[60%]  p-10 relative rounded-2xl shadow-lg">
        {/* <div className="flex flex-col w-[60%] items-start  rounded-md text-[#484949] h-[68vh] overflow-y-auto no-scrollbar"> */}
        <div className="text-3xl  flex justify-start font-medium  mb-12">
          <p className="text-end text-[#313639]">
            {sectionTitle === "" ? "Create Section " : "Update Section "}
          </p>
        </div>

        <div className="mt-4 w-full relative ">
          <CustomInput
            value={sectionTitle}
            inputLable={`Section Title `}
            onChange={(e) => setSectionTitle(e.target.value)}
          />
          <CustomTextarea
            value={sectionDescription}
            inputLable={`Section Description`}
            onChange={(e) => setSectionDescription(e.target.value)}
          />

          <div className="flex justify-end flex-col items-end">
            <CustomButton
              lable={`Save`}
              className={`mt-3 mx-0 duration-200 bg-orange-500 text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
              onClick={() => handleFormSave()}
            />
            {/* <CustomButton
            lable={`Next`}
            className={`mt-3 mx-0 duration-200 bg-gray-500 text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
            onClick={() => setActiveStep(2)}
          /> */}
            <CustomButton
              lable={`Cancel`}
              className={`mt-3 mx-0 duration-200 bg-red-500 text-white  hover:scale-105 border-none shadow-lg w-[10rem]`}
              onClick={() => {
                setShowCreateSectionForm(false);
                setShowEditMode(false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default CreateSectionForm;
