import { Route, Routes } from "react-router-dom";
import { AuthContext } from "../Providers/AuthProvider";
import { useContext } from "react";
import Home from "../Pages/Home/Home";
import Educator from "../Educator-app/Educator";
import Dashboard from "../Educator-app/Dashboard/Dashboard";
import Course from "../Educator-app/crm/AddCourse/Course";
import CloudStoreage from "../Educator-app/crm/CloudStorage/CloudStoreage";
import Login from "../Educator-app/crm/Login/Login";
import { LiveClass } from "../Educator-app/crm/LiveClasses/liveclass";
import { StudentView } from "../Educator-app/crm/LiveClasses/studentview";
import NodePad from "../Educator-app/crm/Notepad/nodepad";
import Enrollment from "../Educator-app/Enrollment/Enrollment";
import Demo from "../Pages/demo";
import TestSeries from "../Educator-app/TestSeries/TestSeries";
import Notificaton from "../Educator-app/Notificaton/Notificaton";
import AccountServices from "../Educator-app/crm/AccountServices/AccountServices";
import SupportServices from "../Educator-app/crm/SupportServices/SupportServices";
import PayoutServices from "../Educator-app/crm/PayoutServices/PayoutServices";
import TestSeriesServices from "../Educator-app/TestSeries/TestSeriesServices";

export const EducatorRouter = () => {
  const { accessToken } = useContext(AuthContext);

  return accessToken ? (
    <Routes>
      <Route path="/" Component={Educator}>
        <Route path="/" Component={Dashboard} />
        <Route path="/add-course" Component={Course} />
        <Route path="/live-class" Component={LiveClass} />
        <Route path="/live-student" Component={StudentView} />
        <Route path="/notepad" Component={NodePad} />
        <Route path="/cloud-storage" Component={CloudStoreage} />
        <Route path="/enrollment" Component={Enrollment} />
        <Route path="/test-series" Component={TestSeriesServices} />
        <Route path="/notification" Component={Notificaton} />
        <Route path="/account" Component={AccountServices} />
        <Route path="/support" Component={SupportServices} />
        <Route path="/payout" Component={PayoutServices} />
        {/* <Route path="/demo" Component={Demo} /> */}
      </Route>
    </Routes>
  ) : (
    <Routes>
      <Route path="/" Component={Home} />
      <Route path="/login" Component={Login} />
    </Routes>
  );
};

const Test = () => {
  return <div>sdjh</div>;
};
