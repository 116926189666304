import { LuArrowRight } from "react-icons/lu";
import img1 from "../../../assets/filters_quality(75)_format(webp) (1).webp";
import img2 from "../../../assets/filters_quality(75)_format(webp) (2).webp";
import img3 from "../../../assets/filters_quality(75)_format(webp) (3).webp";
import img4 from "../../../assets/filters_quality(75)_format(webp) (4).webp";

function OurBlog() {
  const cardData = [
    {
      image: img1,
      title: "How to achieve effectiveness in remote recruitment?",
      tag: "Customer Stories",
      date: "April 05,2022",
      time: "3min",
    },
    {
      image: img2,
      title: "upmyprep introduces AI-generated quizzes, tests and exams",
      tag: "Other",
      date: "April 05,2022",
      time: "3min",
    },
    {
      image: img3,
      title: "Consulting firm + online tests = productivity boost",
      tag: "Customer Stories",
      date: "April 05,2022",
      time: "3min",
    },
  ];
  return (
    <div className="flex  justify-center bg-white  w-full py-5 ">
      <div className="flex flex-col  justify-center  lg:justify-between items-start w-[95%] lg:w-[80%]  py-3 text-start">
        <p className="text-3xl lg:text-4xl font-semibold text-[#024751]">
          Our blog
        </p>
        <div className=" my-10 flex flex-col md:flex-row tablet:gap-3 justify-between">
          {cardData?.map((card, index) => (
            <div className=" lg:w-[32%] mb-10 lg:mb-0" key={index}>
              <div className="">
                <img src={card?.image} alt="w-full" />
              </div>
              <div className=" mt-5 flex flex-col items-start">
                <p className="border  p-1.5 rounded-lg px-3 border-[#64DA9B] text-xs w-auto">
                  {card?.tag}
                </p>
                <p className="my-5 text-xl text-[#024751] font-medium">
                  {card?.title}
                </p>
                <div className=" flex">
                  <p>{card?.date}</p>
                  <div className=" bg-gray-300 w-[2px] mx-3"></div>
                  <p>{card?.time}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col-reverse md:flex-row items-center border rounded-xl my-5">
          <div className=" mt-5 px-3 pb-5 lg:px-10">
            <span className="border  p-1.5 rounded-lg px-3 border-[#64DA9B] text-xs ">
              Customer Stories
            </span>
            <p className="my-5 text-2xl tablet:text-xl text-[#024751] font-medium">
              Better service quality with online tests at DHL
            </p>
            <p className="tablet:text-xs">
              The company employs over 400,000 people who deliver 1,614,000,000
              parcels annually in 220+ countries. The continuous, steady growth
              of DHL would be impossible without the competencies of its
              workforce.
            </p>
            <p className=" flex mt-5 mb-10">
              <span>October 13, 2021</span>
              <div className=" bg-gray-300 w-[2px] mx-3"></div>
              <span>3min read</span>
            </p>
            <p className="flex items-center">
              Read more
              <LuArrowRight className={`ms-3 text-3xl duration-300`} />
            </p>
          </div>
          <div className="">
            <img src={img4} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurBlog;
