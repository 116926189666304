import React from "react";
import AffilateDashboardCards from "./AffilateDashboardCards";
import AffilateDashboardTable from "./AffilateDashboardTable";
import ProgressBar from "./ProgressBar";
import AffilateGraphBox from "./AffilateGraphBox";
import { IoSearchOutline } from "react-icons/io5";
import TopCustomersTable from "./TopCustomersTable";

function AffilateDashboard() {
  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="   flex items-center justify-between">
        {/* <img
          src={require("../../assets/awtar.png")}
          alt=""
          className="p-1 w-[3rem] border rounded-full"
        /> */}
        <div className="flex flex-col  cursor-pointer">
          <p className="text-xs">Wellcome to UpMyPrep</p>
          <p className="font-medium">Jhon </p>
        </div>
        <div className="">
          <div className=" flex items-center w-full">
            <input
              type="text"
              placeholder="Search"
              className="p-2 w-[20rem] focus:outline-none text-xs border rounded-l-md "
            />
            <div className="bg-[#5e8e6b] hover:bg-[#5b9f6d]  p-2 px-3 rounded-r-md cursor-pointer text-white border border-[#5b9f6d]">
              <IoSearchOutline />
            </div>
          </div>
        </div>
      </div>
      <div className="flex  gap-5">
        <div className="w-full">
          <AffilateGraphBox />
        </div>
        <div className="">
          <ProgressBar />
        </div>
        <div className="">
          <AffilateDashboardCards />
        </div>
      </div>
      <div className="h-full  w-full flex items-start gap-10">
        <div className="w-[25%]">
          <TopCustomersTable />
        </div>
        <div className="w-[75%]">
          <AffilateDashboardTable />
        </div>
      </div>
    </div>
  );
}

export default AffilateDashboard;
