import React, { useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import CustomInput from "../../Shared/CustomInput/CustomInput";
import { useEducatorProfileInformationMutation } from "../../../redux/ReduxToolkit";
import { enqueueSnackbar } from "notistack";

function StepFirst({ setActiveStep, setEducatorId }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showNextBtn, setShowNextBtn] = useState(false);

  const [educatorProfileInformation] = useEducatorProfileInformationMutation();

  function ValidateEmail(mail) {
    if (
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  const handleSaveForm = async () => {
    if (
      firstName === null ||
      firstName === undefined ||
      firstName.length === 0
    ) {
      enqueueSnackbar("First Name is required !", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (lastName === null || lastName === undefined || lastName.length === 0) {
      enqueueSnackbar("Last Name is required !", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (email === null || email === undefined || email.length === 0) {
      enqueueSnackbar("email is required !", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (!ValidateEmail(email)) {
      enqueueSnackbar("Entered Email is incorrect", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });

      return;
    }
    if (mobile === null || mobile === undefined || mobile.length === 0) {
      enqueueSnackbar("Mobile Number is required !", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }
    if (password === null || password === undefined || password.length === 0) {
      enqueueSnackbar("Password is required !", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
      return;
    }

    try {
      const response = await educatorProfileInformation({
        body: {
          "profile.firstName": firstName,
          "profile.lastName": lastName,
          educatorEmail: email,
          educatorMobile: mobile,
          educatorPassword: password,
        },
      });

      if (response) {
        setShowNextBtn(true);
        setEducatorId(response?.data?.data?.educatorId);
        console.log("educator id = ", response);
        enqueueSnackbar(
          "Profile information has been added and click on Next to proceed further.",
          {
            autoHideDuration: 2000,
            variant: "success",
            anchorOrigin: { horizontal: "center", vertical: "top" },
          }
        );
      } else {
        console.log("Oops! user is not created");
      }
    } catch (error) {
      console.error("Mutation failed:", error.message);
    }
  };

  return (
    <div>
      <div className=" flex flex-col justify-center  p-5">
        <div className="self-center mb-10">
          <p className="text-2xl font-medium text-[#313639] ">
            Profile Information
          </p>
        </div>
        {/* ....................................... */}
        <div className="">
          {/* ....................................... */}
          <CustomInput
            value={firstName}
            inputLable={`First Name `}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <CustomInput
            value={lastName}
            inputLable={`Last Name `}
            onChange={(e) => setLastName(e.target.value)}
          />{" "}
          <CustomInput
            type={"email"}
            value={email}
            inputLable={`Email address `}
            onChange={(e) => setEmail(e.target.value)}
          />
          <CustomInput
            type={"number"}
            value={mobile}
            inputLable={`Mobile Number`}
            onChange={(e) => setMobile(e.target.value)}
          />
          <CustomInput
            type={"password"}
            value={password}
            inputLable={`Password`}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="mt-6 flex  w-full relative ">
            {!showNextBtn && (
              <CustomButton
                lable={`SAVE `}
                className={` bg-green-700 text-white border-none !mx-0 hover:bg-green-600 absolute left-0  `}
                onClick={() => handleSaveForm()}
              />
            )}
            {showNextBtn && (
              <CustomButton
                lable={` NEXT`}
                className={` bg-blue-700 text-white border-none !mx-0 hover:bg-blue-600 absolute right-0  `}
                onClick={() => setActiveStep(1)}
              />
            )}
          </div>
          {/* ....................................... */}
        </div>
        {/* ....................................... */}
      </div>
    </div>
  );
}

export default StepFirst;
