import React from 'react'

function BrandLogo({className}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            
            viewBox="0 0 1096 1153"
            fill="none"
            className={`-translate-y-2 w-[3rem] m-2 tablet:w-[4rem] 2xl:w-[5rem] ${className} `}
            
        >
            <rect width={1096} height={1153} fill="#00000000" />
            <circle cx={437} cy={468} r={84} fill="#81E3E4" />
            <circle cx={896} cy={473} r={84} fill="#81E3E4" />
            <circle cx={664} cy={700} r={84} fill="#81E3E4" />
            <circle cx={664} cy={934} r={84} fill="#81E3E4" />
            <circle cx={437} cy={934} r={84} fill="#81E3E4" />
            <circle cx={201} cy={700} r={84} fill="#81E3E4" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M286.578 453.827C277.996 417.251 245.463 389.744 206.186 389.093C159.801 388.324 121.575 425.303 120.806 471.689C120.286 503.03 136.999 530.646 162.203 545.5C177.338 554.572 194.533 557.619 212.712 560.841C240.887 565.834 271.428 571.247 300.339 600.158C330.809 630.628 337.629 663.443 343.78 693.045C344.241 695.265 344.699 697.466 345.162 699.647C346.618 744.114 382.75 780.072 427.724 780.818C474.11 781.587 512.336 744.607 513.105 698.222C513.667 664.312 494.056 634.763 465.344 621.02C448.093 610.294 431.806 606.97 415.283 603.598C390.811 598.604 365.82 593.503 336.407 564.09C305.909 533.592 299.471 505.769 293.409 479.571C291.384 470.822 289.401 462.253 286.578 453.827Z"
                fill="#EDBC5F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M748.578 453.827C739.996 417.251 707.463 389.744 668.186 389.093C621.801 388.324 583.575 425.303 582.806 471.689C582.286 503.03 598.999 530.646 624.203 545.5C639.338 554.572 656.533 557.619 674.712 560.841C702.887 565.834 733.428 571.247 762.339 600.158C792.809 630.628 799.629 663.443 805.78 693.045C806.241 695.265 806.699 697.466 807.162 699.647C808.618 744.114 844.75 780.072 889.724 780.818C936.11 781.587 974.336 744.607 975.105 698.222C975.667 664.312 956.056 634.763 927.344 621.02C910.093 610.294 893.806 606.97 877.283 603.598C852.811 598.604 827.82 593.503 798.407 564.09C767.909 533.592 761.471 505.769 755.409 479.571C753.384 470.822 751.401 462.253 748.578 453.827Z"
                fill="#EDBC5F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M681.136 556.354C718.392 549.388 747.91 517.954 750.825 478.531C754.267 431.971 719.297 391.898 672.717 389.026C641.194 387.082 612.433 402.673 596.065 427.387C586.134 442.153 582.092 459.244 577.819 477.311C571.179 505.387 563.981 535.821 533.258 563.553C500.877 592.782 467.501 598.146 437.393 602.985C435.135 603.348 432.896 603.707 430.677 604.074C385.898 603.524 347.676 638.191 344.338 683.334C340.896 729.894 375.866 769.967 422.446 772.84C456.498 774.94 487.327 756.578 502.793 728.358C514.567 711.515 518.845 695.306 523.185 678.861C529.614 654.504 536.178 629.631 567.435 601.417C599.845 572.162 628.182 566.954 654.863 562.051C663.775 560.413 672.503 558.809 681.136 556.354Z"
                fill="#EDBC5F"
            />
        </svg>
    )
}

export default BrandLogo