import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import awtar from "../../assets/awtar.png";
import { HiDotsHorizontal } from "react-icons/hi";
function TopCustomersTable() {
  const data = [
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
    {
      courseIamge: awtar,
      courseName: "IIT Jee Mains ",
      coursePrice: 2000,
      commissionPrice: 400,
      category: "JEE",
      date: "Nov 20, 2023",
    },
  ];
  return (
    <div className="flex flex-col justify-start   h-full w-full rounded-lg border shadow-no-border-shadow-2">
      <div className="flex items-center justify-between font-bold text-gray-700 p-3 border-b">
        <p className=" ">Top Coustomer</p>
        <HiDotsHorizontal className="text-3xl p-1.5 bg-gray-100 rounded-lg cursor-pointer rotate-90" />
      </div>
      {/* <div className="border w-full rounded-md flex items-center">
        <div className="flex items-center justify-between p-2 border-r w-[13rem] cursor-pointer">
          <p className=" text-xs">Filter payouts </p>
          <IoMdArrowDropdown />
        </div>
        <div className=" px-5 flex items-center w-full">
          <IoSearchOutline />
          <input
            type="text"
            placeholder="Search"
            className="p-2 w-full focus:outline-none text-xs"
          />
        </div>
      </div> */}
      <div className=" w-full h-[19rem] overflow-y-scroll  ">
        <table className="w-full h-auto text-xs bg-white rounded">
          <thead>
            <tr className="bg-gray-100 ">
              <th className=" p-3 text-start">Course</th>
              <th className=" p-3 text-end">Selling Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((items, index) => (
              <tr
                key={index}
                className={`shadow text-gray-500 cursor-pointer hover:bg-gray-50/80 ${
                  index === data.length - 1 ? "rounded-b-lg" : ""
                } `}
                style={{ fontFamily: "ArialHelvetica, sans-serif" }}
              >
                <td className=" p-3 text-start text-[#278346] font-medium">
                  <div className="flex items-start gap-4">
                    <img
                      src={items?.courseIamge}
                      alt=""
                      className="w-[2.5rem]"
                    />
                    <p className="line-clamp-2">{items?.courseName}</p>
                  </div>
                </td>
                <td
                  className={` p-3  text-end   ${
                    items?.refunds === 0 ? "text-red-500" : ""
                  }`}
                >
                  <span className="me-1">₹</span>
                  {Number(`${items?.commissionPrice}`).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TopCustomersTable;
