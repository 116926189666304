import React, { useContext, useEffect, useState } from "react";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useGetEnrolledStudentByDateMutation } from "../../redux/ReduxToolkit";
import CustomButton from "../../Pages/Shared/CustomButton/CustomButton";
import { AuthContext } from "../../Providers/AuthProvider";

import { Calendar as PrimeCalendar } from "primereact/calendar";
import { Utils } from "../../utils/utils";
import CustomLoader from "../Loading/Loading";
import { HiDotsHorizontal } from "react-icons/hi";
import { VscAccount } from "react-icons/vsc";

function TimeEnrollment() {
  const { userData } = useContext(AuthContext);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [showDetailsBox, setShowDetailsBox] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const [getEnrolledStudentByDate, { data: enrolledStudentData, isLoading }] =
    useGetEnrolledStudentByDateMutation();

  const handleSearch = async () => {
    try {
      getEnrolledStudentByDate({
        body: {
          startDate: fromDate,
          endDate: toDate,
          educatorId: userData?.userInfo?.educatorId,
        },
      });
    } catch (error) {
      console.log("mutation Error", error);
    }
  };

  useEffect(() => {
    if (enrolledStudentData?.data && enrolledStudentData?.data.length > 0) {
      setShowTable(true);
    }
  }, [enrolledStudentData]);

  // console.log("enrolledStudentData", enrolledStudentData);

    const handleThreeDot = (index, tableData) => {
      // console.log("index", index);
      // console.log("tableData", tableData);
      if (showDetailsBox === index) {
        setShowDetailsBox(-1);
        return;
      }
      setShowDetailsBox(index);
    };


  return (
    <div className="flex flex-col ">
      {isLoading && <CustomLoader />}

      <div className="flex flex-col justify-start  ">
        <p className="font-bold text-[#313639] text-xl mb-5">SELECT DATE</p>

        <div className="flex flex-row gap-8 items-end">
          <div className="me-8">
            <p className="mb-1">From</p>
            <PrimeCalendar
              inputClassName="border px-4 w-[12rem] h-[3rem]  rounded-lg"
              value={fromDate}
              onChange={(e) => setFromDate(e.value)}
              dateFormat="dd/mm/yy"
              className="w-[10rem] "
            />
          </div>
          <div className="ms-8">
            <p className="mb-1">To</p>
            <PrimeCalendar
              inputClassName="border px-4 w-[12rem] h-[3rem]  rounded-lg"
              value={toDate}
              onChange={(e) => setToDate(e.value)}
              dateFormat="dd/mm/yy"
              className="w-[10rem] "
            />
          </div>
          <CustomButton
            lable={`Search`}
            className={`mx-5 bg-green-600 text-white border-none w-[12rem] h-[3rem] `}
            onClick={() => handleSearch()}
          />
        </div>
      </div>

      {enrolledStudentData?.data.length === 0 && (
        <div className="flex flex-col justify-center items-center h-[50vh] w-full">
          <img
            src={require("../../assets/no_data_found.png")}
            alt=""
            className="w-[15rem]"
          />
          <p className="font-semibold text-xl">No Data Found</p>
        </div>
      )}

      {enrolledStudentData?.data &&
        enrolledStudentData?.data.length > 0 &&
        !isLoading &&
        showTable && (
          <div className="flex flex-col ">
            <div className="flex flex-col mb-0 mt-8">
              <div className="w-full flex justify-between items-center font-bold shadow-md mb-5 px-3 py-3">
                <div className="text-start w-[20%]  ">AVATAR</div>
                <div className="text-start w-[20%]  ">STUDENT NAME</div>
                <div className="text-start w-[20%]  ">COURSE</div>
                <div className="text-start w-[20%]  ">PRICE</div>
                <div className="text-start w-[20%]  ">DATE</div>
              </div>
            </div>

            {enrolledStudentData?.data?.map((tableData, index) => (
              <div className="flex flex-col relative" key={index}>
                <div className="w-full flex justify-between items-center my-2  shadow px-3 py-5 ">
                  <div className="text-start w-[20%]   text-sm text-gray-500">
                    {tableData?.studentInfo?.profile?.avatar?.url ? (
                      <img
                        src={tableData?.studentInfo?.profile?.avatar?.url}
                        alt=""
                        className="rounded-full w-[3rem] h-[3rem]"
                      />
                    ) : (
                      <VscAccount className=" w-[3rem] h-[3rem] text-gray-400" />
                    )}
                  </div>
                  <div className="text-start w-[20%]   text-sm text-gray-500">
                    {tableData?.studentInfo?.profile?.firstName}{" "}
                    {tableData?.studentInfo?.profile?.lastName}
                  </div>
                  <div className="text-start w-[20%]   text-sm text-gray-500">
                    {tableData?.paymentDetail?.courseName}
                  </div>
                  <div className="text-start w-[20%]   text-sm text-gray-500">
                    ₹{" "}
                    {Number(
                      tableData?.paymentDetail?.amount / 100
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                  <div className="text-start w-[20%]   text-sm text-gray-500">
                    {Utils.formatDate(tableData?.dateOfEnrollment)}
                  </div>
                  <HiDotsHorizontal
                    className="absolute right-3 cursor-pointer "
                    onClick={() => {
                      // setShowDetailsBox(!showDetailsBox);
                      handleThreeDot(index, tableData);
                    }}
                  />
                </div>
                {showDetailsBox === index && (
                  <div className="  z-10 flex self-end flex-col w-[25rem] bg-gray-50 shadow-lg  p-5  right-0 rounded-lg">
                    <div className="flex justify-between my-1">
                      <p className="font-semibold text-[#313639]">Email</p>
                      <p className="text-gray-500">
                        {tableData?.studentInfo?.email}
                      </p>
                    </div>
                    <div className="flex justify-between my-1">
                      <p className="font-semibold text-[#313639]">Mobile No </p>
                      <p className="text-gray-500">
                        +91 {tableData?.studentInfo?.profile?.mobile}
                      </p>
                    </div>
                    <div className="flex justify-between my-1">
                      <p className="font-semibold text-[#313639]">
                        Payment Method{" "}
                      </p>
                      <p className="text-gray-500 uppercase">
                        {tableData?.paymentDetail?.gatewayPayload?.method}
                      </p>
                    </div>
                    <div className="flex justify-between my-1">
                      <p className="font-semibold text-[#313639]">
                        Expiry Date{" "}
                      </p>
                      <p className="text-gray-500">
                        {Utils.formatDate(tableData?.expiryDate)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
    </div>
  );
}

export default TimeEnrollment;
