import React, { useEffect, useState } from "react";
import AffilateCourseCard from "./AffilateCourseCard";
import {
  useGetAllCourseQuery,
  useGetAllCoursesByGoalIdQuery,
  useGetAllGoalQuery,
} from "../../redux/ReduxToolkit";
import CustomLoader from "../../Educator-app/Loading/Loading";
import no_data_image from "../../assets/no_data_image.jpg";

function AffilateCourseServices() {
  const [courseArray, setCourseArray] = useState([]);
  const [currentGoalId, setCurrentGoalId] = useState("");

  const {
    data: getAllCourse,
    isLoading: getAllCourseIsLoading,
    refetch: getAllCourseRefetch,
  } = useGetAllCourseQuery();

  const { data: allGoal, isLoading: allGoalIsLoading } = useGetAllGoalQuery();

  const { data: allCourseByGoalId, isLoading: allCourseByGoalIdIsLoading } =
    useGetAllCoursesByGoalIdQuery(currentGoalId, {
      skip: !currentGoalId || currentGoalId === "",
    });

  useEffect(() => {
    if (
      getAllCourseIsLoading ||
      allCourseByGoalIdIsLoading ||
      allGoalIsLoading
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [getAllCourseIsLoading || allCourseByGoalIdIsLoading || allGoalIsLoading]);

  useEffect(() => {
    if (getAllCourse?.course) {
      setCourseArray(getAllCourse?.course);
    }
  }, [currentGoalId === "" && getAllCourse?.course]);

  useEffect(() => {
    if (allCourseByGoalId?.courses) {
      setCourseArray(allCourseByGoalId?.courses);
    }
  }, [allCourseByGoalId?.courses]);

  return (
    <div className="flex flex-col gap-5 h-full">
      {(getAllCourseIsLoading ||
        allCourseByGoalIdIsLoading ||
        allGoalIsLoading) && <CustomLoader />}
      <p className="font-semibold"> All Courses</p>
      <div className="border flex items-center rounded-lg">
        <p className="  font-medium p-2  px-5">Filter Course</p>
        <div className="border-x">
          <select
            className="min-w-[10rem] p-2   px-5 capitalize focus:outline-none text-gray-500"
            onChange={(e) => {
              setCurrentGoalId(e.target.value);
            }}
          >           
            <option value="">All Goals Selected</option>
            {allGoal?.msg?.map((goalData, index) => (
              <option key={index} value={goalData?._id}>
                {goalData?.goalTitle}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex flex-wrap  w-full h-full overflow-y-scroll ">
        {courseArray?.length > 0 &&
          courseArray?.map((courseData, index) => (
            <AffilateCourseCard 
              key={index}
              courseData={courseData}
              arrayIndex={index}
            />
          ))}
        {courseArray?.length === 0 && (
          <div className=" flex flex-col items-center justify-center absolute top-[35%] left-0 right-0 w-auto   ">
            <img src={no_data_image} alt="" className="w-[15rem]" />
            <p className="text-3xl text-gray-700  font-gill  ">
              There is no data here.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AffilateCourseServices;
